.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    z-index: $layer-overlay;
    transform: translateZ(0);
    background: rgba($color-white-two, 0.85);
    will-change: transform;
    transition: opacity $transition-default, visibility $transition-default;

    &.is-visible {
        opacity: 1;
        visibility: visible;
    }
}
