.account-events-accordion {
    border-radius: 20px;

    &__icon {
        top: 50%;
        right: 15px;
        transform: translateY(-50%);

        @include tablet {
            right: 30px;
        }
    }

    &__trigger {
        display: flex;
        flex-direction: column;
        padding: 15px $padding-side-mobile;

        @include tablet {
            padding: 30px $padding-side-tablet;
        }
    }

    &__panel {
        padding: 0;
    }

    &__title {
        color: $color-violet-blue;
        pointer-events: none;
    }

    &__date {
        margin-top: 5px;
        color: $color-greyish-brown;
        font-size: 12px;
        line-height: 1.57;
        pointer-events: none;

        @include tablet {
            font-size: 14px;
        }
    }

    &__order {
        padding: 0 $padding-side-mobile 15px;
        color: $color-greyish-brown;
        font-size: 16px;

        @include tablet {
            padding: 0 $padding-side-tablet 30px;
        }
    }

    &__details {
        padding: 15px $padding-side-mobile;
        background-color: $color-pale-grey;
        border-radius: 0 0 20px 20px;

        @include tablet {
            padding: 30px $padding-side-tablet;
        }
    }

    &__description {
        margin-top: 10px;
        color: $color-greyish-brown;

        @include tablet {
            margin-top: 15px;
        }
    }

    &__list {
        @include reset-list;

        margin-top: 10px;

        @include tablet {
            margin-top: 15px;
        }
    }

    &__item {
        padding: 15px 0;
        border-top: 1px solid $color-white-three;

        &:last-child {
            border-bottom: 1px solid $color-white-three;
        }
    }

    &__options {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        margin-bottom: 10px;
        font-size: 14px;

    }

    &__option-item {
        margin-right: 20px;
        margin-bottom: 5px;
    }

    &__option-title {
        font-weight: 500;
    }
}
