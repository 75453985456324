.rich-text-editor {
    ul {
        @include reset-list();
        list-style: none;
        display: block;
        color: $color-greyish-brown;
        padding: 10px 0 20px;

        @include tablet {
            padding: 15px 0 30px;
        }

        ul {
            padding: 10px 0 0;

            @include tablet {
                padding: 15px 0 0;
            }
        }

        li {
            margin-bottom: $desktop-xsmall-padding;
            padding-left: 20px;
            position: relative;
            font-size: 14px;
            line-height: 24px;

            @include tablet {
                font-size: 16px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 9px;
                width: 6px;
                height: 6px;
                background-color: $color-violet-blue;
                border-radius: 50%;
                -moz-border-radius: 50%;
                -webkit-border-radius: 50%;
            }
        }
    }

    h1, h2, h3 {
        margin-bottom: 15px;
        color: $color-violet-blue;
    }

    h1 {
        font-weight: bold;
        line-height: 1.17;
        letter-spacing: -0.5px;
        margin-bottom: 20px;
        font-size: 24px;

        @include tablet {
            margin-bottom: 30px;
            font-size: 46px;
        }
    }

    h2 {
        font-weight: 600;
        font-size: 22px;

        @include tablet {
            font-size: 32px;
        }
    }

    h3 {
        font-weight: 500;
        font-size: 20px;

        @include tablet {
            font-size: 26px;
        }
    }

    p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 15px;
        color: $color-greyish-brown;

        @include tablet {
            font-size: 16px;
        }
    }

    a {
        &:visited {
            color: #0000EE;
        }
    }

    hr {
        display: inline-block;
        width: 100%;
        margin-bottom:20px;
    }


    ol {
        @include reset-list();
        list-style: none;
        display: block;
        counter-reset: item;
        color: $color-greyish-brown;
        padding: 10px 0 20px;

        @include tablet {
            padding: 15px 0 30px;
        }

        ol {
            @include reset-list();
            padding: 10px 0 0;

            @include tablet {
                padding: 15px 0 0;
            }
        }

        li {
            margin-bottom: $desktop-xsmall-padding;
            position: relative;
            padding-left: 30px;
            font-size: 14px;

            @include tablet {
                font-size: 16px;
            }

            &:before {
                content: counter(item) ". ";
                counter-increment: item;
                color: $color-violet-blue;
                width: 1em;
                position: absolute;
                left: 0;
                @include quicksand-bold;
            }
        }
    }
    /* Table Styling */
    table {
        border: 1px solid $color-white-three;
        @include spectrum-radius();
        padding: 1.5em;
        border-spacing: 0;

        thead {
            tr {
                th {
                    border: none;
                    border-bottom: 1px solid $color-white-three;
                    text-align: left;
                    padding: 0 0 1em;
                    font-weight: 500;
                }
            }
        }

        tbody {
            tr {
                &:last-child {
                    td {
                        border-bottom: 0;
                        padding-bottom: 0;
                    }
                }

                th {
                }

                td {
                    border: none;
                    border-bottom: 1px solid $black-10;
                    padding: 1em 0;
                }
            }
        }
    }
}


.error-page {

    .rich-text-editor {
        margin: $mobile-large-padding 0 $mobile-small-padding;

        @include desktop {
            margin: $desktop-large-padding 0 $desktop-small-padding;
        }

        &.no-top-margin {
            margin-top: 0;
        }

        h1 {
            margin-bottom: $mobile-xsmall-padding;
            font-size: 24px;

            @include desktop {
                margin-bottom: $desktop-xsmall-padding;
                font-size: 46px;
            }

        }

        h2 {
            margin-bottom: $mobile-small-padding;
            font-size: 20px;

            @include desktop {
                margin-bottom: $desktop-xsmall-padding;
                font-size: 26px;
            }
        }
    }
}
