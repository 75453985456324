/*-----Donation Block------*/


.donation-block {
    background: $primary-color;
    @include spectrum-radius();
    padding: 1.5em;
    margin-top: $mobile-medium-padding;
    margin-bottom: $mobile-medium-padding;

    @include desktop {
        margin-top: 62px;
        padding: 2em;
        margin-bottom: 0;
    }

    &__title {
        color: #ffffff;
        font-size: 1.8em;

        @include desktop {
            font-size: 1.8em;
        }
    }

    &__list {
        list-style: none;
        position: relative;
        margin-bottom: 2em;

        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 3px;
            background: $tertiary-color;
            bottom: 0;
            left: 0;
        }
    }

    &__list-item {
        display: inline-block;
        color: #ffffff;
        width: 32.33333%;
        text-align: center;

        &--is-active {
            .donation-block__list-item-link {
                color: #ffffff;

                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 3px;
                    background: #ffffff;
                    bottom: 0;
                    z-index: 999;
                    left: 0;
                }
            }
        }
    }

    &__list-item-link {
        color: $tertiary-color;
        padding: 0.8em 0;
        display: block;
        font-weight: 500;
        font-size: 0.9em;
        position: relative;
        text-decoration: none;

        @include desktop {
            padding: 0.8em 1em;
            font-size: 1.2em;
        }

        &:hover {
            text-decoration: none;
            color: #ffffff;

            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 3px;
                background: #fff;
                bottom: 0;
                z-index: 999;
                left: 0;
            }
        }
    }

    div {
        display: inline-block;
    }

    &__form-label {
        color: #ffffff;
        font-weight: 700;
        margin: -1px 20px 0 0;
        font-size: 1.1em;
        display: block;
        margin-bottom: $mobile-small-padding;

        @include desktop {
            margin-bottom: 0;
            display: initial;
        }
    }

    &__input-text {
        height: 48px;
        @include border-radius(42px);
        background: #ffffff;
        outline: 0;
        font-weight: 700;
        font-size: 1.2em;
        border: 0;
        padding: 1em;
        width: 100%;

        @include desktop {
            width: 218px;
            margin: -1px 20px 0 0;
        }
    }

    &__form-bottom {
        margin-top: 1.5em;

        @include desktop {
            margin-bottom: 2em;
        }

        div {
            width: 100%;
            margin-bottom: $mobile-small-padding;

            &:last-of-type {
                margin-bottom: 0;
            }

            @include desktop {
                margin-bottom: 0;
                width: auto;
            }

            .button {
                width: 100%;
                margin-bottom: 0;

                @include desktop {
                    width: auto;
                }
            }
        }
    }

    input[type="radio"] {
        display: none;

        + label {
            color: #333;
            font-size: 14px;

            span {
                display: inline-block;
                width: 90px;
                vertical-align: middle;
                cursor: pointer;
                @include border-radius(42px);
                background-repeat: no-repeat;
                background-position: center;
                text-align: center;
                color: #ffffff;
                font-weight: 700;
                font-size: 1.4em;
                background-color: $tertiary-color;
                height: 38px;
                margin: -1px 10px 0 0;
                line-height: 38px;

                @include desktop {
                    height: 48px;
                    margin: -1px 20px 0 0;
                    line-height: 48px;
                }
            }
        }


        &:checked + label span {
            background-color: lighten($tertiary-color, 10%);
        }
    }
}



/*-----Web Space------*/

.web-space {

    &__title {
        text-align: center;
        font-size: 2em;
        color: #ffffff;

        @include desktop {
            font-size: 3em;
        }
    }

    &__text {
        font-size: 1.1em;
        text-align: center;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 2em;
        color: #cacaca;

        @include desktop {
            width: 70%;
        }
    }

    &__list {
        text-align: center;
    }

    &__item {
        display: inline-block;
        margin-bottom: 1em;
        padding-right: 1rem;
        padding-left: 1rem;
        background: $primary-color;
        @include spectrum-radius();
        @include transition(all,0.2s,ease);
        margin-right: 10px;

        @include desktop {
            margin-right: 20px;
        }

        &:nth-child(2) {
            background: $secondary-color;

            &:hover {
                background: darken($secondary-color,10%);
            }
        }

        &:nth-child(3) {
            background: $color-barney;

            &:hover {
                background: darken($color-barney,10%);
            }
        }

        &:nth-child(4) {
            background: $green;

            &:hover {
                background: darken($green,10%);
            }
        }

        &:nth-child(5) {
            background: $orange;

            &:hover {
                background: darken($orange,10%);
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        font-weight: 700;
        color: #ffffff;
        text-decoration: none;
        padding: 0.5em;
        @include border-radius(150px 150px 0 150px);

        @include desktop {
            padding: 1em;
        }

        &:hover {
            text-decoration: none;
        }
    }

    &__link-svg {
        fill: #ffffff;
        display: block;
        height: 20px;
        margin-left: 20px;
    }
}



/*-----Benefits List------*/


.benefits {
    padding-bottom: $mobile-large-padding;

    @include tablet {
        padding-bottom: $tablet-large-padding;
    }

    @include desktop {
        padding-bottom: $desktop-large-padding;
    }

    &__container {
        padding: 2em 3.5em;
        @include grey-border();
        @include spectrum-radius();
    }

    &__title {
        padding-bottom: $mobile-small-padding;

        @include desktop {
            padding-bottom: $desktop-small-padding;
        }
    }

    &__list {
        @include tablet {
            column-count: 2;
            column-gap: 65px;
        }
    }


    &__item {
        margin-bottom: 15px;

        &:before {
            content: "\2022";
            color: $secondary-color;
            font-weight: 700;
            display: inline-block;
            width: 1em;
            font-size: 1.5em;
            margin-left: -1em;
            position: relative;
            top: 2px;
            text-align: center;
            right: 6px;
        }

        @include tablet {
            font-size: 1.1em;
        }
    }
}
