.account-orders-accordion {
    border-radius: 20px;

    &__trigger {
        padding: 30px 65px 30px 30px;

        @include tablet {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding-right: 75px;
        }

        &[aria-expanded="true"] {
            padding: 30px 65px 30px 30px;

            @include tablet {
                padding-right: 75px;
            }
        }
    }

    &__head-main {
        display: flex;
        flex-direction: column;
        margin-right: 15px;

        @include tablet {
            margin-right: 15px;
        }
    }

    &__status {
        display: inline-block;
        margin-top: 15px;
        padding: 2px 10px;
        background: $grey;
        border-radius: 3px;
        color: $primary-color;
        font-size: 12px;
        font-weight: 500;

        @include tablet {
            margin-top: 0;
            margin-right: 20px;
        }
    }

    &__panel {
        padding: 0 20px 20px;

        @include tablet {
            padding: 0 $padding-side-tablet 30px;
        }
    }

    &__title {
        color: $color-violet-blue;
        pointer-events: none;
    }

    &__date {
        margin-top: 5px;
        color: $color-greyish-brown;
        font-size: 12px;
        line-height: 1.57;
        pointer-events: none;

        @include tablet {
            font-size: 14px;
        }
    }

    &__address-wrapper {
        margin-top: 0;

        @include tablet {
            margin-top: 5px;
        }
    }

    &__col {
        margin-top: 15px;

        @include tablet {
            margin-top: 0;
        }
    }

    &__subtext {
        color: $color-warm-grey-two;
    }

    &__address {
        margin-top: 10px;
        font-size: 14px;
        line-height: 1.5;

        @include tablet {
            font-size: 16px;
        }
    }

    &__details {
        @include spectrum-radius();

        margin-top: 30px;
        border: 1px solid $color-white-three;
    }

    &__summary {
        padding: 20px;
        border-top: 1px solid $color-white-three;

        @include tablet {
            padding: 30px;
        }
    }

    &__basket-card {
        padding: 20px;

        @include tablet {
            padding: 30px;
        }

        & + & {
            border-top: 1px solid $color-white-three;
        }
    }

    &__icon {
        top: 50%;
        right: 20px;
        transform: translateY(-50%);

        @include tablet {
            right: 30px;
        }
    }
}
