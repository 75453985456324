.step-process {
    &__item {
        margin-bottom: 15px;

        @include tablet {
            margin-bottom: 0;
        }

        &--simple {
            display: flex;
            border: 1px solid $color-white-three;
            padding: 15px;
            @include spectrum-radius();
            align-items: center;
            margin-bottom: 15px;
            background: $color-white;
        }

        &--detailed {
            display: flex;
            flex-direction: column;

            @include tablet {
                height: 100%;
                margin-bottom: 0;
            }

            .step-process__description {
                font-size: 14px;
            }

            .step-process__count {
                position: absolute;
                left: 10px;
                top: 10px;
            }

            .step-process__content {
                flex: 1 1;
                display: flex;
                flex-flow: column nowrap;
            }
        }

        &--grey {
            background: $color-pale-grey;
            border: 1px solid $color-pale-grey;
        }
    }

    &__title {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
        margin-bottom: 15px;
        color: $color-violet-blue;

        @include tablet {
            font-size: 18px;
        }
    }

    &__curve {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        fill: $color-white;
    }

    &__description {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $color-greyish-brown;

        @include tablet {
            font-size: 16px;
        }
    }


    &__count {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: -0.5px;
        text-align: center;
        line-height: 54px;
        margin-right: 30px;
        color: $color-white;
        height: 50px;
        width: 50px;
        background: $color-purple-pink;
        @include border-radius(25px 25px 0 25px);
        flex-shrink: 0;

        @include tablet {
            font-size: 28px;
        }
    }

    &__image {
        position: relative;
        height: 194px;

        @include tablet {
            height: auto;
        }

        img {
            max-width: 100%;
            height: auto;
            vertical-align: top;
            @include border-radius(15px 15px 0 0);
            width: 100%;

            @include tablet {
                width: auto;
            }

            @include mobile-only {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &--grey {
            .step-process__curve {
                fill: $color-pale-grey;
            }
        }
    }

    &__content {
        padding: 20px;
        @include border-radius(0 0 0 20px);
        background: $color-white;

        @include tablet {
            padding: 30px;
        }

        &--grey {
            background: $color-pale-grey;
        }
    }


    &__3 {

        + .step-process__4 {
            @include tablet {
                margin-top: 30px;
            }
        }
    }
}
