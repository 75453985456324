$fallback-font: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-quicksand: Quicksand, $fallback-font;
$font-hero-new: hero-new, $fallback-font;

@mixin quicksand($weight) {
    font-family: $font-quicksand;
    font-weight: $weight;
}

@mixin quicksand-light {
    @include quicksand(300);
}

@mixin quicksand-regular {
    @include quicksand(400);
}

@mixin quicksand-bold {
    @include quicksand(700);
}


//webpack build error 06/03/2020
@mixin hero-new($weight: 400) {
    font-family: $font-hero-new;
    font-weight: $weight;
}

//webpack build error 06/03/2020
//@mixin hero-new {
//    @include hero-new(400);
//}

@mixin hero-new-italic {
    @include hero-new(400);
    font-style: italic;
}


@mixin hero-new-semibold {
    @include hero-new(600);
}


@mixin hero-new-bold {
    @include hero-new(700);
}

@mixin hero-new-bold-italic {
    @include hero-new(700);
    font-style: italic;
}
