.participant-card {
    $this: &;

    @include spectrum-radius;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px $padding-side-mobile;
    background-color: white;

    @include tablet {
        padding: 30px $padding-side-tablet;
    }

    &__title {
        color: $color-violet-blue;
    }

    &__subtitle {
        display: inline-block;
        margin-top: 5px;
    }

    &__link,
    &__copy,
    &__mark {
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }

    &__mark {
        display: none;
        background-color: $color-fun-green;
    }

    &__copy {
        @include reset-button;

        vertical-align: middle;
        background-color: inherit;
        border: 1px solid $color-lavender;

        & + #{$this}__link {
            margin-left: 10px;
        }
    }

    &__links {
        display: flex;
    }

    &__link {
        display: inline-block;
        vertical-align: middle;
        background-color: $color-violet-blue;
    }

    &__copy-icon,
    &__mark-icon,
    &__link-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__mark-icon {
        color: $color-white;
    }

    &__link-icon {
        color: white;
    }

    &__copy-icon {
        color: $color-lavender;
    }

    &__col {
        & + & {
            padding-left: 15px;
        }
    }

    &--simple {
        padding: 0;
        background-color: inherit;
        border-radius: 0;
    }

    &--done {
        #{$this}__title {
            color: $color-fun-green;
        }

        #{$this}__mark {
            display: inline-block;
        }

        #{$this}__links {
            display: none;
        }
    }
}
