.modal-content {
    display: none;
    width: 100%;
    min-height: 200px;
    padding: 20px;

    .modal & {
        display: block;
    }
}
