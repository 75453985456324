.cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    opacity: 0;
    z-index: $layer-selector;
    transform: translateY(100%);
    width: 100%;
    padding: 30px $padding-side-mobile 30px;
    background-color: white;
    border-top: 1px solid $color-white-three;
    transition: transform 1000ms ease, opacity 700ms linear;

    @include tablet {
        padding: 30px $padding-side-tablet;
    }

    &__title {
        color: $color-violet-blue;
    }

    &__content {
        margin-top: 15px;
    }

    &__row {
        @include tablet {
            justify-content: space-between;
        }
    }

    &__col {
        & + & {
            margin-top: 15px;

            @include tablet {
                margin-top: 0;
            }
        }
    }

    &__link {
        color: $color-violet-blue;
    }

    &__controls {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include tablet {
            flex-direction: row;
            justify-content: flex-end;
            align-items: stretch;
        }
    }

    &__btn {
        width: 100%;
        min-height: 40px;

        @include tablet {
            width: auto;
        }

        & + & {
            margin-top: 15px;

            @include tablet {
                margin-top: 0;
                margin-left: 15px;
            }
        }
    }

    &__close {
        @include reset-button;

        position: absolute;
        top: 28px;
        right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        background-color: inherit;
        border: 1px solid $color-white-three;
        border-radius: 50%;

        @include tablet {
            top: 15px;
        }
    }

    &__close-icon {
        width: 12px;
        height: 12px;
        color: $color-violet-blue;
    }

    &.is-visible {
        opacity: 1;
        transform: translateY(0);
    }
}
