.promotional-panel {
    background: $color-white;
    text-align: center;
    border-top: 1px solid $color-white-three;
    border-bottom: 1px solid $color-white-three;

    @include tablet {
        padding-left: 30px;
        padding-right: 30px;
        text-align: left;
    }

    &__container {
        max-width: 964px;
        margin: 0 auto;
    }

    &--blue {
        background: #5E28C8;
        border: none;

        h2, p {
            color: $color-white;
        }

        .promotional-panel__description {
            opacity: 0.8;
        }

        .promotional-panel__actions {
            background: $color-violet-blue;

            &:after {
                background: $color-violet-blue;
            }
        }

        .promotional-panel__curve-left {
            fill: #5E28C8;
        }
    }

    &--purple {
        background: #9A36BB;
        border: none;

        h2, p {
            color: $color-white;
        }

        .promotional-panel__description {
            opacity: 0.8;
        }

        .promotional-panel__actions {
            background: $color-barney;

            &:after {
                background: $color-barney;
            }
        }

        .promotional-panel__curve-left {
            fill: #9A36BB;
        }
    }

    &__content {
        padding: 30px;
        width: 100%;

        @include tablet {
            padding-top: 60px;
            padding-bottom: 60px;
            width: 60%;
        }
    }

    &__title {
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: -0.5px;
        color: $color-violet-blue;

        @include tablet {
            font-size: 32px;
        }
    }

    &__description {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        margin-top: 15px;
        padding: 0 15px;

        @include tablet {
            padding: 0;
            font-size: 16px;
        }
    }

    &__actions-holder {
        width: 100%;

        @include tablet {
            width: 40%;
            margin-left: auto;
        }
    }

    &__actions {
        align-items: center;
        position: relative;
        justify-content: center;
        background: $color-pale-grey;
        padding-bottom: $mobile-medium-padding;
        padding-top: $mobile-medium-padding;

        @include tablet {
            height: 100%;
            padding-top: 87px;
            padding-bottom: 87px;
            padding-left: 70px;
            display: flex;
        }

        @include desktop {
            padding-left: 117px;
        }

        &:after {
            @include tablet {
                height: 100%;
                background: $color-pale-grey;
                content: "";
                width: 200%;
                position: absolute;
                left: 100%;
                top: 0;
            }
        }

        .button {
            @include tablet {
                padding: 12px 0;
                width: 100%;
            }

            @include desktop {
                padding: 12px 30px;
                width: auto;
            }
        }
    }

    &__curve-left {
        display: none;

        @include tablet {
            display: block;
            position: absolute;
            height: 100%;
            fill: $color-white;
            left: 0;
            top: 0;
        }
    }
}
