.event-option {
    $this: &;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px;
    border: 1px solid $color-white-three;
    border-radius: 10px;
    color: $color-greyish-brown;

    @include tablet {
        flex-wrap: nowrap;
    }

    &__main {
        flex: 1 0 100%;
        margin-bottom: 15px;

        @include tablet {
            flex: 1 1 auto;
            margin-right: 30px;
            margin-bottom: 0;

        }
    }

    &__price {
        @include hero-new(500);

        margin-right: 30px;
        font-size: 14px;

        @include tablet {
            font-size: 16px;

        }
    }

    &__title {
        margin-bottom: 10px;
    }

    &__caption {
        @include hero-new(500);

        margin-top: 10px;
        color: $color-warm-grey-two;
        font-size: 12px;
    }

    &__summary {
        margin-top: 13px;
    }

    &__radio {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        align-items: center;

        @include tablet {
            flex-grow: 0;
            width: 135px;
        }

    }

    &--disabled {
        pointer-events: none;

        #{$this}__main,
        #{$this}__price,
        #{$this}__quantity {
            opacity: 0.7
        }
    }
}
