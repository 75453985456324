.membership-form {
    position: relative;
    padding: 65px 30px 30px;

    @include tablet {
        padding: 65px 60px 60px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 5px;
        background-color: $color-violet-blue;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        color: $color-warm-grey-two;

        & > :first-child {
            margin-right: 10px;
        }
    }

    &__title {
        margin-top: 15px;
        color: $color-violet-blue;
    }

    &__text {
        margin-top: 15px;
    }

    &__types {
        margin-top: 30px;
    }

    &__box {
        margin-top: 15px;
    }

    &__controls {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;

        @include tablet {
            flex-direction: row;
        }
    }

    &__control {
        width: 100%;
        min-width: 200px;
        margin-top: 15px;

        @include tablet {
            width: auto;
            margin-top: 30px;
        }
    }

    &__footer-text {
        margin-top: 15px;

        @include tablet {
            margin-top: 30px;
        }
    }
}
