.file-page {
    overflow: hidden;

    &__file-wrap {
        padding: 40px $padding-side-mobile;

        @include tablet {
            padding: 80px $padding-side-tablet;
        }
    }
}

.file {
    $this: &;

    &__carousel {
        width: 100%;

        @include tablet {
            width: 45%;
        }

        @include desktop {
            padding-right: 40px;
        }
    }

    &__carousel-wrap {
        position: relative;
        overflow: hidden;
        border-radius: 20px 20px 0 20px;
        height:293px;
        @include tablet {
            height:400px;
        }
        .image-gallery {
            &__caption {
                display: none;
            }

            &__image {
                height: 293px;

                @include tablet {
                    height: 435px
                }

                img {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    &__carousel-control {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }

    &__carousel-button {
        position: relative;
        width: 30px;
        height: 30px;
        background-color: white;
        border: 1px solid $color-white-three;
        border-radius: 50%;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            display: block;
            width: 10px;
            height: 10px;
            border: 2px solid $color-lavender;
            border-top: none;
            border-right: none;
        }

        &--prev {
            &::before {
                left: 55%;
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }

        &--next {
            &::before {
                left: 45%;
                transform: translate(-50%, -50%) rotate(-135deg);
            }
        }
    }

    &__download-link {
        text-decoration: none;
        color: $color-violet-blue;
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-weight:500;

        &:hover {
            color:$secondary-color;
            .file__download {
                fill: $secondary-color;
            }
        }
    }

    &__download {
        max-height: 25px;
        max-width: 25px;
        margin-left: 15px;
        fill: $primary-color;
        @include transition(all,0.5s,ease);
    }

    &__info {
        margin-top: 20px;

        @include tablet {
            margin-top: 0;
        }
    }

    &__title {
        color: $color-violet-blue;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.5px;
        line-height: 1.2;

        @include tablet {
            font-size: 32px;
            font-weight: 600;
            line-height: 1.3;
        }
    }

    &__price {
        margin-top: 15px;
        color: $color-greyish-brown;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.4;

        @include tablet {
            font-size: 18px;
        }
    }

    &__note {
        display: flex;
        margin-top: 15px;
        color: $color-warm-grey-two;
        font-size: 12px;
        line-height: 1.5;

        @include tablet {
            font-size: 14px;
        }
    }

    &__code {
        @include tablet {
            display: flex;
        }

        & + #{$this}__availability {
            margin-left: 13px;

            @include tablet {
                margin-left: 30px;
            }
        }
    }

    &__availability {
        @include tablet {
            display: flex;
        }
    }

    &__code-value,
    &__availability-value {
        @include tablet {
            margin-left: 5px;
            font-weight: 500;
        }
    }

    &__short {
        margin-top: 20px;

        a {
            &:visited {
                color: #0000EE;
            }
        }
    }

    &__description {
        margin-top: 20px;
        color: $color-greyish-brown;
        font-size: 14px;
        line-height: 1.5;

        @include tablet {
            font-size: 16px;
        }

        &.is-short {
            position: relative;
            overflow: hidden;
            max-height: 60px;

            @include tablet {
                max-height: 95px;
            }
        }
    }

    &__btn-read-more {
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 15px;
        padding: 5px 15px 5px 4px;
        background-color: $color-white-two;
        border: none;
        border-radius: 20px;
        color: $color-greyish-brown;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.8;
        cursor: pointer;

        &::before {
            content: '';
            display: block;
            width: 22px;
            height: 22px;
            margin-right: 10px;
            background-color: white;
            border-radius: 20px;
        }

        span {
            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 11px;
                display: block;
                transform: translateY(-50%);
                background-color: $color-pinkish-grey;
            }

            &::after {
                left: 14px;
                width: 2px;
                height: 8px;
            }

            &::before {
                width: 8px;
                height: 2px;
            }
        }

        &.less {
            span::after {
                content: none;
            }
        }

        &.is-hidden {
            display: none;
        }
    }

    &__choices {
        margin-top: 20px;

        @include tablet {
            margin-top: 20px;
        }

        &.is-hidden {
            display: none;
        }
    }

    &__choice-wrap {
        display: flex;
        align-self: center;
    }

    &__choice {
        margin-bottom: 15px;
    }

    &__quantity {
        & + #{$this}__button-basket {
            margin-left: 15px;

            @include tablet {
                margin-left: 30px;
            }
        }
    }

    &__input-wrap {
        display: flex;
    }

    &__button-basket {
        align-self: flex-end;
        width: 50%;
        max-width: 200px;
        min-height: 46px;
        padding: 14px 14px;

        @include tablet {
            font-size: 16px;
            padding: 14px 36px;
        }
    }

    &__button-out {
        display: none;
        min-width: 200px;
        margin-top: 30px;
    }

    &--out-of-stock {
        #{$this}__choices {
            display: none;
        }

        #{$this}__button-out {
            display: block;
        }
    }
}
