/*  reset default styles */
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}


.heading-1 {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.5px;
    line-height: 1.25;

    @include tablet {
        font-size: 46px;
        line-height: 1.17;
    }
}

.heading-2 {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: -0.5px;
    line-height: 1.27;

    @include tablet {
        font-size: 32px;
        font-weight: 600;
        line-height: 1.31;
    }
}

.heading-3 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.5px;
    line-height: 1.2;

    @include tablet {
        font-size: 26px;
        font-weight: 500;
        line-height: 1.38;
    }
}

.heading-4 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.5px;
    line-height: 1.22;

    @include tablet {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.27;
    }
}

.heading-5 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;

    @include tablet {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.33;
    }
}

.heading-6 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;

    @include tablet {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.38;
    }
}

.paragraph-1 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;

    @include tablet {
        font-size: 22px;
        line-height: 1.45;
    }
}

.paragraph-2 {
    font-size: 18px;
    line-height: 1.33;

    @include tablet {
        font-size: 20px;
        line-height: 1.4;
    }
}

.paragraph-3 {
    font-size: 16px;
    line-height: 1.38;

    @include tablet {
        font-size: 18px;
        line-height: 1.44;
    }
}

.paragraph-4 {
    font-size: 14px;
    line-height: 1.43;

    @include tablet {
        font-size: 16px;
        line-height: 1.5;
    }
}

.paragraph-5 {
    font-size: 12px;
    line-height: 1.5;

    @include tablet {
        font-size: 14px;
        line-height: 1.57;
    }
}

.paragraph-6 {
    font-size: 10px;
    line-height: 1.6;

    @include tablet {
        font-size: 12px;
        line-height: 1.5;
    }
}

.subtext {
    font-size: 12px;
    letter-spacing: 1.5px;
    line-height: 1.67;
    text-transform: uppercase;

    @include tablet {
        font-size: 14px;
        letter-spacing: 2px;
    }
}

.subtext-2 {
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1.5px;
    line-height: 1.8;
    text-transform: uppercase;

    @include tablet {
        font-size: 12px;
        letter-spacing: 1.5px;
        line-height: 1.67;
    }
}

.subtext-3 {
    font-size: 8px;
    font-weight: 500;
    letter-spacing: 1.5px;
    line-height: 2;
    text-transform: uppercase;

    @include tablet {
        font-size: 10px;
        letter-spacing: 1.5px;
        line-height: 1.8;
    }
}


/* TODO: move to richtext by NAS */
/*h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.1;
    margin-bottom:15px;
}

p {
    color:$body-font-color;
    line-height:1.4;
    @include hero-new-regular;

}

img {
    vertical-align: top;
    height:auto;
    max-width:100%;
}*/
