.filter-bar {
    background: $color-white;
    width: 100%;
    padding: 15px;
    border-bottom: solid 1px $color-white-three;

    @include tablet {
        padding: 15px 0;
        display: flex;
        overflow: visible;
    }




    &--top {
        align-items: center;

        .dropdown {
            &:last-of-type:not(:first-of-type) {
                padding: 0;

                .dropdown-list {
                    @include tablet {
                        left: initial;
                        right: 0;
                    }
                }
            }
        }

        .dropdown-icon--main {
            display: none;
        }

        &.filter-bar--has-clear {
            .filter-bar__search-box {
                @include tablet {
                    width: 40%;
                }
            }
        }

        .filter-bar__clear-all {
            @include tablet {
                padding-left: 15px;
            }
        }

        .filter-bar__update-button {
            @include tablet {
                display: none;
            }
        }
    }

    &--left {
        flex-basis: 100%;
        max-width: 100%;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        flex: 0 0 auto;
        width: 100%;
        display: initial;
        padding-top: 0;
        border-bottom: 0;
        padding: 0 1rem 30px;

        @include tablet {
            padding: 0 1rem;
            flex-basis: 30%;
            max-width: 30%;
        }

        .filter-bar__search-box {
            width: 100%;
            padding: 0;
        }

        .filter-bar__search {
            min-width: unset;
            background-color: $color-pale-grey;

            @include tablet {
                margin-bottom: 30px;
            }
        }

        .filter-bar__filter-list {
            display: initial;

            @include mobile-only {
                border: 1px solid $color-white-three;
                padding: 20px;
                display: block;
                @include border-radius(10px 10px 0 10px);
                max-height: 50px;
                @include transition(max-height,0.25s,ease);

                .dropdown {
                    display: none;
                }
            }

            &.active {
                max-height: 1000px;

                .dropdown {
                    display: block;
                }

                .filter-bar__toggle {
                    margin-bottom: 15px;
                }

                .dropdown-icon {
                    &--first {
                        span {
                            transform: rotate(90deg);

                            &:last-of-type {
                                left: 50%;
                                right: 50%;
                            }
                        }
                    }
                }
            }
        }

        .filter-bar__title {
            margin-bottom: 15px;
        }

        .dropdown {
            padding: 0;
            border: 1px solid $color-white-three;
            padding: 20px;
            @include border-radius(15px 15px 0 15px);
            margin-bottom: 15px;
            position: relative;

            &.on {
                .dropdown-icon {
                    &--main {
                        span {
                            transform: rotate(90deg);

                            &:last-of-type {
                                left: 50%;
                                right: 50%;
                            }
                        }
                    }
                }
            }
        }

        .filter-bar__clear-all {
            margin-top: 15px;

            @include tablet {
                margin-top: 0;
            }
        }

        .filter-bar__clear-button {
            width: 100%;
        }

        .dropdown .dropdown-label {
            width: 100%;
            padding: 0;
            background: 0;
            border: 0;
            height: auto;
            line-height: normal;
            margin: 0;
        }

        .dropdown:last-child {
            padding: 20px;
            margin-bottom: 30px;
        }

        .filter-bar__arrow {
            right: 15px;
        }

        .filter-bar__postcode {
            width: 100%;
            @include border-radius(20px 20px 0 0);
            background: $color-pale-grey;
            border: 1px solid $color-white-three;
            outline: none;
            font-size: 12px;
            height: 50px;
            padding-left: 15px;
            color: $color-greyish-brown;
            font-weight: 500;

            @include tablet {
                font-size: 14px;
            }
        }

        .filter-bar__title--left {
            display: block;
        }

        .filter-bar__postcode-select {
            width: 100%;
            @include border-radius(0 0 0 0);
            font-size: 12px;
            margin-bottom: 30px;
            height: 50px;
            padding-left: 15px;
            background: $color-pale-grey;
            border: 1px solid $color-white-three;
            border-top: 0;
            color: $color-greyish-brown;
            font-weight: 500;
            margin: 0;

            @include tablet {
                font-size: 14px;
            }
        }

        .filter-bar__arrow--small {
            display: none;
        }

        .dropdown .dropdown-label:before {
            right: 0;
            display: none;
        }

        .dropdown .dropdown-list {
            background: none;
            padding: 0;
            border: 0;
            width: auto;
            left: auto;
            top: auto;
            overflow-y: hidden;
            position: relative;
            max-height: 0;
            @include transition(max-height,0.25s,ease);
        }

        .dropdown.on .dropdown-list {
            border-top: 1px solid $color-white-three;
            padding-top: 15px;
            margin-top: 15px;
            max-height: 100vh;
        }

        .dropdown .dropdown-icon {
            right: 0;
        }

        .dropdown .dropdown-label.active {
            background: none;
            border-color: none;
            color: none;
        }

        .dropdown .dropdown-number.active {
            top: 4px;
        }

        .filter-bar__toggle {
            display: block;
            line-height: 0.8;

            @include tablet {
                line-height: 1.8;
                display: none;
            }
        }

        .dropdown-option {
            color: $color-greyish-brown;
        }

        .filter-bar__postcode-box {
            padding: 0;
        }

        .content-block {
            display: none;

            @include tablet {
                display: block;
            }
        }
    }

    &__update-button {
        margin-bottom: 30px;
        width: 100%;
        @include border-radius(0 0 20px 20px);
        color: $color-white;
        background: $primary-color;
        padding: 10px 40px;
        text-align: center;
        font-weight: 700;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        line-height: 1.2;
        cursor: pointer;
        text-decoration: none;
        outline: none;
        border: 2px solid transparent;
        font-size: 14px;
        @include transition(all,0.2s,ease);
    }

    &__toggle {
        display: none;
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 1.5px;
        color: $color-warm-grey-two;
        text-transform: uppercase;
        position: relative;

        &:hover {
            cursor: pointer;
        }
    }

    &__arrow-submit {
        position: absolute;
        right: 1em;
        height: 15px;
        width: 15px;
        z-index: 99;
        top: 5px;
        right: 15px;
        display: none;

        @include tablet {
            display: block;
            opacity: 0;
            z-index: 9999;
            right: 25px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .dropdown-icon {
        position: absolute;
        right: -11px;
        top: -11px;
        width: 30px;
        height: 30px;

        @include tablet {
            top: -5px;
            right: 2em;
        }

        span {
            position: absolute;
            transition: .3s;
            background: $color-pinkish-grey;
            border-radius: 2px;

            &:first-of-type {
                top: 25%;
                bottom: 25%;
                width: 2px;
                left: 46%;
            }

            &:last-of-type {
                left: 25%;
                right: 25%;
                height: 2px;
                top: 45%;
            }
        }

        &.active {
            span {
                transform: rotate(90deg);

                &:last-of-type {
                    left: 50%;
                    right: 50%;
                }
            }
        }
    }

    &--transparent {
        background: none;
    }

    &--black {
        background: $color-black;
    }

    &--75 {
        background: $color-greyish-brown;
    }

    &--50 {
        background: $color-warm-grey;
    }

    &--25 {
        background: $color-pinkish-grey;
    }

    &--10 {
        background: $color-white-three;
    }

    &--5 {
        background: $color-white-two
    }

    &--white {
        background: $color-white;
    }

    &--washed-chalk {
        background: $color-pale-grey;
    }

    &--blue-violet {
        background: $color-violet-blue;
    }

    &--violet-purple {
        background: $color-purple-pink-two;
    }


    &__search-box {
        align-self: flex-end;
        align-items: center;
        display: flex;
        /*float: left;*/
        width: 100%;
        margin-bottom: 15px;
        position: relative;

        @include tablet {
            padding: 0 1rem;
            width: 42%;
            margin-bottom: 0;
        }

        @include desktop {
            width: 46%;
        }
    }

    &__arrow {
        position: absolute;
        right: 1em;
        height: 30px;
        width: 30px;
        fill: $color-lavender;
        z-index: 99;
        top: 10px;
        right: 15px;

        @include tablet {
            right: 32px;
        }

        &--small {
            height: 15px;
            width: 15px;
            top: 5px;
            display: none;
            z-index: 222;

            @include tablet {
                display: block;
                right: 25px;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__search {
        color: #bbbbbb;
        position: relative;
        z-index: 5;
        transition: z-index 0.8s, width 0.5s, background 0.3s ease, border 0.3s;
        height: 50px;
        margin: 0;
        padding: 5px 0 5px 40px;
        font-size: 1rem;
        cursor: pointer;
        right: 0;
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDUxMiA1MTIiIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiB3aWR0aD0iNTEycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik01MDMuODY2LDQ3Ny45NzRMMzYwLjk1OCwzMzUuMDUyYzI4LjcyNS0zNC41NDQsNDYuMDE3LTc4LjkxMiw0Ni4wMTctMTI3LjMzNiAgYzAtMTEwLjA4NC04OS4yMjctMTk5LjMxMi0xOTkuMzEyLTE5OS4zMTJDOTcuNTk5LDguNDAzLDguMzUxLDk3LjYzMSw4LjM1MSwyMDcuNzE1YzAsMTEwLjA2NCw4OS4yNDgsMTk5LjMxMiwxOTkuMzEyLDE5OS4zMTIgIGM0OC40MzUsMCw5Mi43OTItMTcuMjkyLDEyNy4zMzYtNDYuMDE3bDE0Mi45MDgsMTQyLjkyMkw1MDMuODY2LDQ3Ny45NzR6IE0yOS4zMzEsMjA3LjcxNWMwLTk4LjMzNCw3OS45ODctMTc4LjMzMiwxNzguMzMyLTE3OC4zMzIgIGM5OC4zMjUsMCwxNzguMzMyLDc5Ljk5OCwxNzguMzMyLDE3OC4zMzJzLTgwLjAwNywxNzguMzMyLTE3OC4zMzIsMTc4LjMzMkMxMDkuMzE4LDM4Ni4wNDcsMjkuMzMxLDMwNi4wNSwyOS4zMzEsMjA3LjcxNXoiIGZpbGw9IiMzNzQwNEQiLz48L3N2Zz4=) no-repeat left 9px center transparent;
        @include border-radius(10px);
        border: none;
        background-size: 19px;
        width: 100%;
        border: 1px solid $color-white-three;
        background-color: white;

        &:focus {
            z-index: 3;

            @include desktop {
                border: 1px solid #bbbbbb;
                background-color: white;
                outline: none;
                cursor: auto;
                padding-right: 10px;
            }

            &:after {
                content: "";
                height: 20px;
                width: 20px;
                line-height: 20px;
                position: absolute;
                right: 10px;
                top: 50%;
                border: 1px solid #D6C9F1;
            }
        }
    }

    &__submit {
        position: absolute;
        right: 1em;
        height: 30px;
        width: 30px;
        fill: #D0C0EF;
        z-index: 993;
        top: 10px;
        opacity: 0;
    }

    &__filter-list {
        position: relative;
        width: 100%;

        @include tablet {
            display: flex;
            width: auto;
            align-items: center;
        }
    }

    .content-block {
        display: none;
    }

    &__title {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.5px;
        color: $color-warm-grey-two;
        text-transform: uppercase;
        padding-right: 15px;
        display: none;

        @include tablet {
            display: block;
        }

        &--left {
            display: none;
        }
    }

    &__postcode-box {
        position: relative;

        @include tablet {
            padding-right: 1rem;
        }
    }

    &__postcode {
        position: relative;
        display: block;
        height: 50px;
        background: $color-white;
        border: 1px solid $color-white-three;
        padding: 6px 12px;
        line-height: 36px;
        cursor: pointer;
        font-weight: 600;
        font-size: 12px;
        color: $color-greyish-brown;
        width: 100%;
        @include border-radius(10px 10px 0 0);
        font-size: 12px;

        @include tablet {
            height: 25px;
            margin-bottom: 0;
        }
    }

    &__postcode-select {
        position: relative;
        display: block;
        height: 50px;
        background: $color-white;
        border: 1px solid $color-white-three;
        padding: 1px 12px;
        line-height: 36px;
        cursor: pointer;
        font-weight: 600;
        font-size: 12px;
        color: $color-greyish-brown;
        width: 100%;
        border-top: 0;
        @include border-radius(0 0 0 0);
        margin-bottom: 0;

        @include tablet {
            @include border-radius(0 0 10px 10px);
            margin-bottom: 0;
            height: 25px;
        }
    }

    &__clear-button {
        padding: 9px 15px;
        width: 100%;

        @include tablet {
            width: auto;
        }
    }
}



.dropdown {
    font-size: 14px;
    color: $color-greyish-brown;
    /*float: left;*/
    width: 100%;
    position: relative;

    @include tablet {
        position: relative;
        padding: 0 15px 0 0;
        width: auto;
    }

    .dropdown-list {
        padding: 30px;
        background: #fff;
        position: absolute;
        top: 50px;
        left: 10px;
        right: 2px;
        width: 95%;
        transform-origin: 50% 0;
        transform: scale(1, 0);
        transition: transform .15s ease-in-out .15s;
        overflow-y: scroll;
        z-index: 44;
        border: 1px solid $color-white-three;
        border-top: 0;
        max-height: 100vh;


        @include tablet {
            top: 66px;
            left: 0;
            width: 100%;
            width: 455px;
        }

        @include desktop {
            width: 388px;
        }
    }

    .dropdown-item {
        position: relative;
    }

    .dropdown-option {
        display: block;
        padding: 2px 12px 15px 35px;
        opacity: 0;
        transition: opacity .15s ease-in-out;
        position: relative;
    }

    .dropdown-icon-holder {
        display: block;
        width: 100%;

        &:hover {
            cursor: pointer;
        }
    }

    .dropdown-button {
        &:hover {
            cursor: pointer;
        }
    }

    .dropdown-number {
        height: 15px;
        width: 16px;
        text-align: center;
        font-size: 10px;
        color: $color-white;
        line-height: 16px;
        @include border-radius(150px);
        display: none;
        background: $color-violet-blue;
        right: 39px;
        top: 17px;
        position: absolute;

        @include tablet {
            right: 31px;
        }

        @include desktop {
            right: 39px;
        }

        &.active {
            display: block;
        }
    }

    .dropdown-icon {
        position: absolute;
        right: 2em;
        top: -5px;
        width: 30px;
        height: 30px;

        span {
            position: absolute;
            transition: .3s;
            background: $color-pinkish-grey;
            border-radius: 2px;

            &:first-of-type {
                top: 25%;
                bottom: 25%;
                width: 2px;
                left: 46%;
            }

            &:last-of-type {
                left: 25%;
                right: 25%;
                height: 2px;
                top: 45%;
            }
        }

        &.active {
            span {
                transform: rotate(90deg);

                &:last-of-type {
                    left: 50%;
                    right: 50%;
                }
            }
        }
    }

    .dropdown-label {
        position: relative;
        display: block;
        height: 50px;
        background: #fff;
        border: 1px solid $color-white-three;
        padding: 6px 12px;
        line-height: 36px;
        cursor: pointer;
        @include border-radius(10px);
        font-weight: 600;
        font-size: 12px;
        width: 100%;
        margin-bottom: 15px;

        @include tablet {
            margin-bottom: 0;
            width: 180px;
        }

        @include desktop {
            width: 203px;
            font-size: 13px;
        }

        &:before {
            float: right;
            content: '';
            position: absolute;
            top: 50%;
            width: 9px;
            height: 9px;
            color: #C4C4C4;
            border: 1px solid currentcolor;
            transform: translateY(-90%) rotateZ(-45deg);
            transition: transform 0.3s;
            transform-origin: 100%;
            right: 17px;
            border-color: transparent transparent currentcolor currentcolor;
        }

        &.active {
            background: $color-light-blue-grey;
            border-color: $color-light-blue-grey;
            color: $color-violet-blue;

            &:before {
                float: right;
                content: '';
                position: absolute;
                top: 50%;
                width: 9px;
                height: 9px;
                color: #C4C4C4;
                border: 1px solid currentcolor;
                transform: translateY(-90%) rotateZ(-45deg);
                transition: transform 0.3s;
                transform-origin: 100%;
                right: 17px;
                border-color: transparent transparent $color-violet-blue $color-violet-blue;
            }
        }
    }

    &__submenu {
        padding-left: 10px;
        overflow: hidden;
        max-height: 0;
        @include transition(max-height,0.25s,ease);
        opacity: 0;
        visibility: hidden;

        @include desktop {
            padding-left: 20px;
        }

        &.active {
            opacity: 1;
            visibility: visible;
            max-height: 100vh;
        }
    }

    &__submenu-item {
        position: relative;

        .dropdown-option {
            padding: 2px 31px 15px 35px;
        }
    }

    &__thirdlevel {
        padding-left: 10px;
        overflow: hidden;
        max-height: 0;
        @include transition(max-height,0.25s,ease);
        opacity: 0;

        @include desktop {
            padding-left: 20px;
        }

        &.active {
            opacity: 1;
            max-height: 100vh;
        }
    }

    &.on {
        .dropdown-list {
            transform: scale(1, 1);
            transition-delay: 0s;

            .dropdown-option {
                opacity: 1;
                transition-delay: .2s;
            }
        }

        .dropdown-label:before {
            content: '';
            position: absolute;
            top: 57%;
            right: 17px;
            width: 9px;
            height: 9px;
            color: #C4C4C4;
            border: 1px solid currentcolor;
            transform: translateY(-90%) rotateZ(-45deg);
            transition: transform 0.3s;
            transform-origin: 100%;
            border-color: currentcolor currentcolor transparent transparent;
        }

        .dropdown-label.active:before {
            border-color: $color-violet-blue $color-violet-blue transparent transparent;
        }
    }

    [type="checkbox"] {
        position: relative;
        top: -1px;
        margin-right: 4px;
        float: left;
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    &__apply {
        @include border-radius(150px);
        color: #ffffff;
        background: $primary-color;
        padding: 10px 40px;
        text-align: center;
        font-weight: 700;
        display: inline-flex;
        margin-top: 15px;
        float: right;
        justify-content: center;
        align-items: center;
        line-height: 1.2;
        cursor: pointer;
        text-decoration: none;
        outline: none;
        border: 2px solid transparent;
        font-size: 14px;
        @include transition(all,0.2s,ease);
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: $color-white;
        @include border-radius(3px);
        border: 1px solid $color-white-three;

        &:after {
            left: 7px;
            top: 0;
            width: 6px;
            height: 13px;
            border: solid $color-violet-blue;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            content: "";
            position: absolute;
            display: none;
        }
    }
}

.dropdown-option input:checked ~ .checkmark:after {
    display: block;
}
