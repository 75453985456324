.image {

    &--border {
        img {
            border: 1px solid $color-white-three;
            border-bottom: 0;
        }
    }

    a {
        text-decoration:none
    }

    img {
        @include border-radius(20px 20px 0 0);
        max-width: 100%;
        height: auto;
        vertical-align: top;
    }

    &__caption {
        text-align: center;
        border: 1px solid $color-white-three;
        padding: 15px;
        color: $color-warm-grey;
        width: 100%;
        font-size: 12px;
        @include border-radius(0 0 0 20px);

        @include tablet {
            padding: 20px;
            font-size: 14px;
        }
    }
}
