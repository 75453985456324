.dimmer-switch {
    $this: &;
    $switch-width: 90px;
    $toggle-width: 26px;
    $edge-toggle-right: 4px;
    $edge-toggle-left: $switch-width - $toggle-width - $edge-toggle-right;
    $duration-animate: 0.2s;

    display: flex;
    align-items: center;

    &__label {
        display: none;

        @media screen and (min-width:1125px) {
            display: block;
            margin-right: 15px;
            color: $black-75;
            font-size: 10px;
            font-weight: 500;
            letter-spacing: 1.5px;
            line-height: 1.5;
            text-transform: uppercase;
        }
    }

    &__btn {
        @include reset-button();
        @include border-radius(20px);
        position: relative;
        width: 40px;
        height: 40px;
        background-color: $color-white-three;

        @media screen and (min-width:1125px) {
            overflow: hidden;
            z-index: 0;
            display: flex;
            flex-shrink: 0;
            justify-content: space-between;
            align-items: center;
            width: $switch-width;
            height: 34px;
            padding: 5px 10px;
            background-color: $color-violet-blue;
            color: white;
            font-size: 11px;
            font-weight: 500;
            letter-spacing: 0.3px;
            line-height: 1.82;
        }

        &[aria-checked="false"] {

            #{$this}__mode--vivid {
                left: 0;
            }

            #{$this}__toggle {
                right: $edge-toggle-right;
            }

            #{$this}__mode--calm {
                right: -100%;
            }
        }

        &[aria-checked="true"] {
            background-color: $color-slate-blue;

            #{$this}__mode--vivid {
                left: -100%;
            }

            #{$this}__toggle {
                right: $edge-toggle-left;
                background-color: rgba($color-white, 0.35);
                border: 0;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='12' viewBox='0 0 14 12'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' d='M7 1V0m0 12v-1m6-5h-1M2 6H1m1.146-4.354l.708.708m-.708 8l.708-.708m8.292-7.292l.708-.708m-.708 8l.708.708M7 9c1.657 0 3-1.343 3-3S8.657 3 7 3 4 4.343 4 6s1.343 3 3 3z'/%3E%3C/svg%3E");
            }

            #{$this}__mode--calm {
                right: 0;
            }
        }
    }

    &__mode {

        @media screen and (max-width:1125px) {
            @include visually-hidden;
        }

        position: relative;
        color: white;

        &--vivid {
            transition: left $duration-animate ease;
        }

        &--calm {
            transition: right $duration-animate ease;
        }
    }

    &__toggle {
        @include border-radius(20px);
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: white;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 16px 16px;
        border: 1px solid $color-white-three;
        pointer-events: none;
        transition: right $duration-animate ease;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3ClinearGradient id='prefix__a' x1='100%25' x2='0%25' y1='50%25' y2='50%25'%3E%3Cstop offset='0%25' stop-color='%23FBBC12'/%3E%3Cstop offset='100%25' stop-color='%23E72D7F'/%3E%3C/linearGradient%3E%3Cpath fill='none' fill-rule='evenodd' stroke='url(%23prefix__a)' stroke-linecap='round' stroke-linejoin='round' d='M13 8V6m0 14v-2m7-5h-2M8 13H6m1.354-5.646l1.414 1.414m-1.414 10l1.414-1.414m8.525-8.647l1.414-1.414m-1.414 10l1.414 1.414M13 16c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z' transform='translate(-5 -5)'/%3E%3C/svg%3E%0A");

        @media screen and (min-width:1125px) {
            top: 50%;
            left: auto;
            transform: translateY(-50%);
            width: $toggle-width;
            height: 26px;
        }
    }

    &__modal {
        padding: 55px 30px 30px;

        @include tablet {
            padding: 65px 45px 60px;
        }
    }

    &__modal-message {
        color: $color-violet-blue;
    }
}
