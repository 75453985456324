.explainers {
    &__header {
        width: 100%;
        align-items: center;
        margin-bottom: 15px;

        @include tablet {
            display: flex;
            margin-bottom: 30px;
            justify-content: space-between;
        }
    }

    &__title {
        line-height: 1.31;
        letter-spacing: -0.5px;
        color: $color-violet-blue;

        @include tablet {
            font-size: 32px;
        }
    }

    &__content {
        @include tablet {
            width: 70%;
            flex-basis: 70%;
        }
    }

    &__description {
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: normal;
        color: $color-greyish-brown;
        margin-top: 15px;

        @include tablet {
            margin-bottom: 0;
            font-size: 16px;
        }
    }

    &__item {
        padding: 15px;
        border: 1px solid $color-white-three;
        @include spectrum-radius();
        position: relative;
        margin-bottom: 15px;

        @include tablet {
            height: 100%;
            margin-bottom: 0;
            padding: 30px;
        }

        &--purple {
            background: $color-violet-blue;
            border-color: $color-violet-blue;

            .explainers__item-title, .explainers__item-description {
                color: $color-white;
            }

            .explainers__arrow {
                fill: $color-white;
            }
        }

        &--pink {
            background: $color-purple-pink;
            border-color: $color-purple-pink;

            .explainers__item-title, .explainers__item-description {
                color: $color-white;
            }

            .explainers__arrow {
                fill: $color-white;
            }
        }

        &--white {
            background: $color-white;
        }
    }

    &__item-title {
        width: 70%;
        font-weight: 500;
        line-height: 1.27;
        letter-spacing: -0.5px;
        color: $color-violet-blue;
        margin-bottom: 15px;

        @include tablet {
            font-size: 22px;
            width: 80%;
        }
    }

    &__link {
        text-decoration:none;
        display:block;
        height:100%;
        width:100%;
    }

    &__item-description {
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: normal;
        color: $color-greyish-brown;

        @include tablet {
            font-size: 16px;
        }
    }

    &__button {
        position: absolute;
        right: 20px;
        top: 20px;

        @include tablet {
            right: 30px;
            top: 30px;
        }
    }

    &__cta {
        @include tablet {
            position: absolute;
            right: 0;
            top: 0;
        }

        .button {
            width: 100%;
            margin-top: 15px;
            font-size: 14px;

            @include tablet {
                padding: 10px 51px;
                margin-top: 0;
                width: auto;
            }
        }
    }

    &__arrow {
        height: 30px;
        width: 30px;
        fill: $color-lavender;
    }
}
