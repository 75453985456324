.sign-up {
    $this: &;

    position: relative;
    padding: 30px $padding-side-mobile;

    @include tablet {
        padding: 60px $padding-side-tablet;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 5px;
        background-color: $color-lavender;
    }

    &__progress {
        @include transition(width, 0.5ms, ease);

        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 5px;
        background-color: $color-violet-blue;
    }

    &__container {
        max-width: 800px;
    }

    &__heading {
        display: flex;
        justify-content: space-between;
    }

    &__header,
    &__steps {
        color: $color-warm-grey-two;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;

        @include tablet {
            font-size: 14px;
        }
    }

    &__header {
        text-align: left;
    }

    &__steps {
        text-align: right;
    }

    &__form {
        margin-top: 15px;
    }

    &__tab {
        display: none;

        &.is-current {
            display: block;
        }
    }

    &__tab-title {
        color: $color-violet-blue;
    }

    &__fields {
        margin-top: 15px;

        @include tablet {
            margin-top: 30px;
        }

        &--preferences {
            margin-top: 0;

            @include tablet {

                #{$this}__checkbox + #{$this}__checkbox {
                    margin-top: 15px;
                }
            }
        }
    }

    &__fieldset {
        @include reset-fieldset;
    }

    &__legend {
        margin-bottom: 15px;
        color: $color-warm-grey-two;

        @include tablet {
            margin-bottom: 30px;
        }

        &--preferences {
            color: $color-greyish-brown;
        }
    }

    &__field {
        & + & {
            margin-top: 15px;

            @include tablet {
                margin-top: 30px;
            }
        }
    }

    &__dropdown {
        display: block;
        max-width: 140px;

        @include tablet {
            max-width: 190px;
        }
    }

    &__col {
        & + & {
            @include tablet {
                margin-top: 0;
            }
        }
    }

    &__checkboxes-label {
        margin-bottom: 15px;
        color: $color-warm-grey-two;
        font-size: 14px;
        line-height: 1.5;

        @include tablet {
            margin-bottom: 30px;
        }
    }

    &__checkbox {
        & + & {
            margin-top: 15px;
        }
    }

    &__about-text {
        margin-top: 15px;
    }

    &__terms-text {
        margin-top: 15px;

        @include tablet {
            margin-top: 30px;
        }
    }

    &__link {
        color: $color-violet-blue;
    }

    &__controls {
        display: flex;
        flex-direction: column;
        margin-top: 15px;

        @include tablet {
            flex-direction: row;
            margin-top: 30px;
        }
    }

    &__btn {
        min-width: 200px;

        &:not(.is-hidden) + & {
            margin-top: 15px;

            @include tablet {
                margin-top: 0;
            }
        }

        &--prev {
            border-color: $color-lavender;

            @include tablet {
                margin-right: 10px;
            }

            &:hover,
            &:focus {
                background-color: $color-lavender;
                color: $color-violet-blue;
            }
        }

        &--next,
        &--submit {
            @include tablet {
                margin-left: auto;
            }
        }

        &--submit {
            margin-top: 15px;

            @include tablet {
                margin-top: 0;
            }
        }

        &.is-hidden {
            display: none;
        }
    }
}
