.added-to-basket-modal {
    padding: 55px 30px 30px;

    @include tablet {
        padding: 65px 60px 60px;
    }

    &__title {
        margin-top: 15px;
        color: $color-violet-blue;
    }

    &__copy {
        margin-top: 12px;
    }

    &__footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 30px;

        @include tablet {
            flex-direction: row;
            margin-top: 80px;
        }
    }

    &__button {
        & + & {
            margin-top: 15px;

            @include tablet {
                margin-top: 0;
            }
        }
    }
}
