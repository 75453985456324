.utility-microsite {
    display: none;

    @media screen and (min-width:1125px) {
        display: block;
        padding-bottom: 20px;
    }

    &__row {
        @media screen and (min-width:1125px) {
            border-bottom: 1px solid $color-white-three;
        }
    }

    .page-header__inner {
        padding-bottom: 0;
        min-height: auto;
    }

    .utility-block {
        margin-bottom: 0;

        @media screen and (min-width:1125px) {
            flex-direction: row;
            justify-content: flex-end;
        }

        .utility-nav {
            @media screen and (min-width:1125px) {
                align-items: center;
                align-self: center;
                margin-right: 15px;
                margin-top: 0;
            }

            &__link {
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: 1.38px;
                font-size: 11px;
            }
        }

        &__button {
            @media screen and (min-width:1125px) {
                align-items: center;
                align-self: center;
                width: auto;
                font-size: 14px;
                display: inline-flex;
                height: 30px;
                margin: 0;
                margin-right: 30px;
                padding: 5px 24px;
            }
        }

        .dimmer-switch__label {
            @include tablet {
                display: none;
            }
        }
    }
}



.utility-navigation {
    $this: &;
    display: table-footer-group;

    @media screen and (min-width:1125px) {
        display: initial;
    }

    &__content {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap-reverse;
        justify-content: flex-end;
        align-items: center;

        @media screen and (min-width:1125px) {
            flex-direction: row;
            flex-wrap: nowrap;
            min-height: 50px;
            background-color: $color-pale-grey;
            border-radius: 0 0 0 20px;
        }

        @media screen and (min-width:1273px) {
            border-radius: 0 0 20px 20px;
        }
    }

    &__item {
        margin-top: 10px;

        @media screen and (min-width:1125px) {
            margin-top: 0;
        }
    }

    &__dimmer-switch {
        @media screen and (max-width:346px) {
            margin-top: 10px;
        }

        margin-top: 0;
        margin-right: 15px;

        @media screen and (min-width:1125px) {
            position: relative;
            padding-right: 15px;
            padding-left: 20px;
            margin-right: 0;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                display: block;
                transform: translateY(-50%);
                width: 1px;
                height: 14px;
                background-color: $color-pinkish-grey;
            }

            & + #{$this}__profile {
                padding-left: 15px;
            }
        }
    }

    &__bell {
        margin-right: 15px;

        @media screen and (min-width:1125px) {
            position: relative;
            margin-right: 15px;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                display: block;
                transform: translateY(-50%);
                width: 1px;
                height: 14px;
                background-color: $color-pinkish-grey;
            }
        }
    }

    &__profile {
        display: flex;
        align-items: center;
    }

    &__mini-basket {
        order: -1;
        margin-left: 15px;

        @media screen and (min-width:1125px) {
            order: 0;
            margin-left: 0;
        }
    }
}


.utility-block {
    margin-bottom: 15px;
    @include clearfix;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 2rem 0 1rem;
    display: table;
    transition: transform .3s;

    @media screen and (min-width:1125px) {
        display: flex;
        flex-direction: row-reverse;
        position: relative;
        float: right;
        padding: 0;
    }

    .utility-navigation__dimmer-switch {
        margin-top: 10px;
        margin-right: 0;

        @media screen and (min-width:1125px) {
            position: relative;
            padding-right: 15px;
            margin-top: 0;
            padding-left: 20px;
            margin-right: 0;
        }
    }

    &__back {
        font-size: 11px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.82;
        letter-spacing: 1.38px;
        color: $color-warm-grey;
        text-transform: uppercase;
        float: left;
        text-decoration: none;
        display: none;

        @include desktop-wide {
            display: flex;
            align-self: center;
            margin-right: auto;
            padding-left: 30px;
        }

        span {
            font-size: 0;
            margin-right: 15px;
            position: relative;
        }
    }

    &__arrow {
        fill: $color-white-three;
        height: 20px;
        width: 13px;
        transform: rotate(90deg) scaleX(-1);
    }


    .utility-nav {
        display: table-header-group;

        @media screen and (min-width:1125px) {
            display: inherit;
            align-self: flex-start;
            float: right;
            align-items: center;
            margin-right: 25px;
            margin-top: 8px;
        }

        &__list {
            margin: 0 0 1em 0;
            position: relative;
            padding: 0;

            @include mobile-only {
                @media screen and (max-height:680px) {
                    margin: 0 0 0.5em 0;
                }
            }

            @media screen and (min-width:1125px) {
                margin: 0;
            }
        }

        &__item {
            position: relative;
            display: block;

            @media screen and (min-width:1125px) {
                display: inline-block;
            }
        }

        &__link {
            display: block;
            color: $color-warm-grey;
            text-decoration: none;
            padding: 10px 10px 10px 0;
            font-size: 12px;
            @include transition(all,0.2s,ease);
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 1.5px;

            @media only screen and (max-height: 680px) {
                font-size: 10.5px;
            }

            @media screen and (min-width:1125px) {
                text-transform: unset;
                letter-spacing: normal;
                padding: 10px;
            }

            &:hover {
                text-decoration: none;
                color: darken($color-warm-grey,10%);
                cursor:pointer;
            }

            &--button {
                color: #ffffff;
                background: $secondary-color;
                display: none;
                @include border-radius(150px);

                @include desktop {
                    display: block;
                    margin-left: 1em;
                    padding: 8px 20px;
                }

                &:hover {
                    text-decoration: none;
                    color: #ffffff;
                    background: darken($secondary-color, 10%);
                }
            }
        }
    }

    &__button {
        width: 100%;
        margin-bottom: 13px;
        padding: 11px;

        @include mobile-only {
            @media screen and (max-height:680px) {
                margin-bottom: 5px;
                padding: 10px;
            }
        }

        @media screen and (min-width:1125px) {
            display: none;
        }
    }


    &__account-svg {
        width: 20px;
        margin-right: 0.5em;
    }

    &__sign-in {
        display: flex;
        align-items: center;
        font-size: 0.9em;

        @media screen and (min-width:1125px) {
            vertical-align: central;
        }
    }

    &__sign-in-text {
        color: #3f3f3f;
    }

    &__switch {
        display: inline-block;
        margin-right: 25px;

        input[type=checkbox] {
            height: 0;
            width: 0;
            visibility: hidden;
        }

        label {
            cursor: pointer;
            text-indent: -9999px;
            width: 50px;
            display: inline-block;
            height: 25px;
            background: grey;
            border-radius: 100px;
            position: relative;
        }

        label:after {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            width: 20px;
            height: 20px;
            background: #fff;
            border-radius: 90px;
            transition: 0.3s;
        }

        input:checked + label {
            background: #bada55;
        }

        input:checked + label:after {
            left: calc(100% - 5px);
            transform: translateX(-100%);
        }

        label:active:after {
            width: 20px;
        }
    }
}
