.accordion {
    margin: 0;
    padding: 0;
    width: 100%;
    @include grey-border();
    @include spectrum-radius();

    &--black {
        background: $color-black;

        .accordion__title, p, ul li, ol li {
            color: $color-white;
        }

        .accordion__icon span {
            background: $color-white;
        }

        .accordion__panel {
            a {
                color: $color-white;
                text-decoration: underline;
            }

            h1, h2, h3 {
                color: $color-white;
            }
        }
    }

    &--black-75 {
        background: $black-75;

        .accordion__title, p, ul li, ol li {
            color: $color-white;
        }

        .accordion__icon span {
            background: $color-white;
        }

        .accordion__panel {
            h1, h2, h3 {
                color: $color-white;
            }
        }
    }

    &--black-50 {
        background: $black-50;

        .accordion__title, p, ul li, ol li {
            color: $color-white;
        }

        .accordion__icon span {
            background: $color-white;
        }

        .accordion__panel {
            h1, h2, h3 {
                color: $color-white;
            }
        }
    }

    &--black-25 {
        background: $black-25;
    }

    &--black-10 {
        background: $black-10;
    }

    &--black-5 {
        background: $black-5;
    }

    &--white {
        background: $color-white;

        p, ul li, ol li {
            color: $color-greyish-brown;
        }

        .accordion__panel {
            h1, h2, h3 {
                color: $color-greyish-brown;
            }
        }
    }

    &--washed-chalk {
        background: $washed-chalk;

        p {
            color: $color-greyish-brown;
        }

        .accordion__panel {
            h1, h2, h3 {
                color: $color-greyish-brown;
            }
        }
    }

    &--blue-violet {
        background: $color-violet-blue;
        border-color: $color-violet-blue;

        .accordion__title, p, ul li, ol li {
            color: $color-white;
        }

        .accordion__icon span {
            background: $color-white;
        }

        .accordion__panel {
            h1, h2, h3 {
                color: $color-white;
            }
        }
    }

    &--violet-purple {
        background: $color-darkish-purple;
        border-color: $color-darkish-purple;

        .accordion__title, p, ul li, ol li {
            color: $color-white;
        }

        .accordion__icon span {
            background: $color-white;
        }

        .accordion__panel {
            h1, h2, h3 {
                color: $color-white;
            }
        }
    }

    &--yellow {
        background: $deep-yellow;
        border-color: $deep-yellow;

        .accordion__title, p, ul li, ol li {
            color: $color-black;
        }

        .accordion__icon span {
            background: $color-black;
        }

        .accordion__panel {
            h1, h2, h3 {
                color: $color-black;
            }
        }
    }

    &--invalid {
        border: 1px solid #A22602
    }

    &__title {
        padding: 0;
        display: inline-block;
        margin: 0;
        color: $color-violet-blue;
        font-weight: 500;
        font-size: 18px;
        letter-spacing: -0.5px;
        pointer-events: none;
        width: 85%;

        @include tablet {
            width: 90%;
        }

        @include desktop {
            font-size: 22px;
        }
    }

    &__description {
        margin: 1em 0 0;
        width: 70%;
        pointer-events: none;
        font-size: 14px;
    }

    &__icon {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 50px;
        height: 50px;
        pointer-events: none;

        @include desktop {
            top: 22px;
            right: 30px;
        }

        span {
            position: absolute;
            transition: .3s;
            background: $primary-color;
            @include border-radius(20px);

            &:first-of-type {
                top: 26%;
                bottom: 34%;
                width: 3px;
                left: 45%;
            }

            &:last-of-type {
                left: 27%;
                right: 32%;
                height: 3px;
                top: 42%;
            }
        }
    }

    &__source {
        margin-bottom: 20px;
    }

    &__panel {
        margin: 0;
        padding: 0 20px 10px;

        @include desktop {
            padding: 0 30px 30px 30px;
        }

        a {
            font-style: italic;
            color: #000000;
            font-size: 0.9em;
            text-decoration: none;
        }

        p {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 15px;
        }

        h1, h2, h3 {
            margin-bottom: 15px;
            font-weight: 500;
        }

        ul, ol {
            padding-left: 20px;

            li {
                font-size: 14px;
                line-height: 24px;
            }
        }
    }

    &__trigger {
        background: none;
        color: $color-greyish-brown;
        display: block;
        font-size: 1rem;
        font-weight: normal;
        margin: 0;
        padding: 20px;
        position: relative;
        text-align: left;
        width: 100%;
        outline: none;
        border: none;
        cursor: pointer;

        @include desktop {
            padding: 30px;
        }

        &:hover {
            background: none;
        }

        &[aria-expanded="true"] {
            padding: 20px 20px 5px;

            @include desktop {
                padding: 30px 30px 5px;
            }

            .accordion__description {
                margin-bottom: 15px;
            }

            .accordion__icon {
                span {
                    transform: rotate(90deg);

                    &:last-of-type {
                        left: 50%;
                        right: 50%;
                    }
                }
            }
        }
    }
}
