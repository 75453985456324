/*-----------------Child Page Widget-----------------*/
.child-categories {
    display: block;
    margin-top: 2em;

    .block-3 {
        width: 100%;
        flex-basis: 100%;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        min-height: 1px;
        padding-right: 20px;
        padding-left: 20px;

        @include desktop {
            max-width: 33.33333%;
            flex-basis: 33.33333%;
        }
    }

    &__block {
        @include transition(background,1.5s,ease);


        @include border-radius(10px);
        padding: 2em;
        margin-bottom: 1em;

        h4 {
            color: white;

            @include desktop {
                color: #000000;
            }
        }

        &--Purple {
            background: purple;

            @include desktop {
                border: 5px solid #ffffff;
            }

            h4 {
                @include desktop {
                    color: #ffffff;
                }
            }
        }

        &--Orange {
            background: orange;

            h4 {
                @include desktop {
                    color: #000000;
                }
            }
        }
    }
}
