.sidebar-filter {
    @include mobile-only {
        max-height: 58px;
        overflow: hidden;
        @include transition(all,0.25s,ease);
        border: 1px solid $color-white-three;
        @include border-radius(15px 15px 0 15px);
        margin-bottom: 10px;
        padding: 15px !important;

        .sidebar-filter__item {
            opacity: 0;
        }

        .sidebar-filter__option {
            opacity: 0;
        }
    }

    &__inner {
    }

    &.active {
        max-height: 800px;

        .dropdown {
            display: block;
        }

        .sidebar-filter__item,
        .sidebar-filter__option {
            opacity: 1;
        }

        .sidebar-filter__title {
            margin-bottom: 10px;
        }

        .sidebar-filter__button-clear {
            @include mobile-only {
                display:inline-flex;
            }
        }
    }

    &__title {
        font-size: 10px;
        font-weight: 500;
        line-height: 1.8;
        letter-spacing: 1.5px;
        color: $color-warm-grey-two;
        text-transform: uppercase;
        margin-top: 4px;

        @include tablet {
            margin-top: 0;
            margin-bottom: 15px;
        }
    }

    &__plus {
        position: absolute;
        right: 2em;
        top: -5px;
        width: 30px;
        height: 30px;

        @include tablet {
            display: none;
        }

        span {
            position: absolute;
            transition: .3s;
            background: $color-pinkish-grey;
            border-radius: 2px;

            &:first-of-type {
                top: 25%;
                bottom: 25%;
                width: 2px;
                left: 46%;
            }

            &:last-of-type {
                left: 25%;
                right: 25%;
                height: 2px;
                top: 45%;
            }
        }

        &.active {
            span {
                transform: rotate(90deg);

                &:last-of-type {
                    left: 50%;
                    right: 50%;
                }
            }
        }
    }

    &__toggle {
        display: block;
        width: 100%;
        position: relative;

        &:hover {
            @include desktop {
                pointer-events: none;
                cursor: default;
            }

            cursor: pointer;
        }
    }

    &__button-clear {
        display: none;

        @include tablet {
            display: inline-flex;
        }
    }

    &__item {
        border: 1px solid $color-white-three;
        padding: 20px;
        @include spectrum-radius();
        margin-bottom: 15px;
    }

    &__option {
        font-size: 14px;
        font-weight: 500;
        color: $color-greyish-brown;
        display: block;
        width: 100%;
        position: relative;

        a {
            display: block;
            width: 100%;
            position: relative;

            &:hover {
                cursor: pointer;
            }
        }

        &.active {
            span {
                font-weight: 600;
                color: $color-violet-blue;
            }
        }
    }

    &__select {
        &:not(:last-child) {
            border-bottom: 1px solid $color-white-three;
            padding-bottom: 15px;
            margin-bottom: 15px;
        }
    }

    .side-filter-icon {
        position: absolute;
        right: 0;
        top: -2px;
        width: 25px;
        height: 25px;

        span {
            position: absolute;
            transition: .3s;
            background: $color-pinkish-grey;
            border-radius: 2px;

            &:first-of-type {
                top: 25%;
                bottom: 25%;
                width: 10%;
                left: 45%;
            }

            &:last-of-type {
                left: 25%;
                right: 25%;
                height: 10%;
                top: 45%;
            }
        }

        &.active {
            span {
                transform: rotate(90deg);

                &:last-of-type {
                    left: 50%;
                    right: 50%;
                }
            }
        }
    }

    @include tablet {
        position: initial;
        padding: 0 15px 0 0;
        width: auto;
    }

    &:last-child {
        padding: 0;
    }

    .side-filter-list {
        padding: 30px;
        background: #fff;
        position: absolute;
        top: 50px;
        left: 10px;
        right: 2px;
        width: 95%;
        transform-origin: 50% 0;
        transform: scale(1, 0);
        transition: transform .15s ease-in-out .15s;
        overflow-y: scroll;
        z-index: 44;
        border: 1px solid $color-white-three;
        border-top: 0;
        max-height: 66vh;


        @include tablet {
            top: 66px;
            left: 66px;
            width: 100%;
            width: 455px;
        }
    }

    .side-filter-option {
        display: block;
        padding: 2px 12px 15px 35px;
        transition: opacity .15s ease-in-out;
        position: relative;
        font-size: 14px;
    }

    .side-filter-option-single {
        display: block;
        padding: 2px 0 0 0;
        transition: opacity .15s ease-in-out;
        position: relative;
        font-size: 13px;
    }

    .side-filter-button {
        &:hover {
            cursor: pointer;
        }
    }

    .sidebar-filter-number {
        height: 15px;
        width: 16px;
        text-align: center;
        font-size: 10px;
        color: $color-white;
        line-height: 16px;
        @include border-radius(150px);
        display: none;
        background: $color-violet-blue;
        right: 50px;
        top: 1px;
        position: absolute;

        &.active {
            display: block;
        }
    }

    .side-filter-label {
        position: relative;
        display: block;
        height: 50px;
        background: #fff;
        border: 1px solid $color-white-three;
        padding: 6px 12px;
        line-height: 36px;
        cursor: pointer;
        @include border-radius(10px);
        font-weight: 600;
        font-size: 12px;
        width: 100%;
        margin-bottom: 15px;

        @include tablet {
            margin-bottom: 0;
            width: 145px;
        }

        @include desktop {
            width: 184px;
            font-size: 14px;
        }

        &:before {
            float: right;
            content: '';
            position: absolute;
            top: 50%;
            width: 9px;
            height: 9px;
            color: #C4C4C4;
            border: 1px solid currentcolor;
            transform: translateY(-90%) rotateZ(-45deg);
            transition: transform 0.3s;
            transform-origin: 100%;
            right: 17px;
            border-color: transparent transparent currentcolor currentcolor;
        }

        &.active {
            background: $color-light-blue-grey;
            border-color: $color-light-blue-grey;
            color: $color-violet-blue;

            &:before {
                float: right;
                content: '';
                position: absolute;
                top: 50%;
                width: 9px;
                height: 9px;
                color: #C4C4C4;
                border: 1px solid currentcolor;
                transform: translateY(-90%) rotateZ(-45deg);
                transition: transform 0.3s;
                transform-origin: 100%;
                right: 17px;
                border-color: transparent transparent $color-violet-blue $color-violet-blue;
            }
        }
    }

    &__sub-menu {
        overflow: hidden;
        max-height: 0;
        @include transition(all,0.25s,ease);
        opacity: 0;

        &.active {
            opacity: 1;
            border-top: solid 1px $color-white-three;
            padding-top: 20px;
            margin-top: 20px;
            max-height: 400px;
        }
    }


    [type="checkbox"] {
        position: relative;
        top: -1px;
        margin-right: 4px;
        float: left;
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    &__apply {
        @include border-radius(150px);
        color: #ffffff;
        background: $primary-color;
        padding: 10px 40px;
        text-align: center;
        font-weight: 700;
        display: inline-flex;
        float: right;
        justify-content: center;
        align-items: center;
        line-height: 1.2;
        cursor: pointer;
        text-decoration: none;
        outline: none;
        border: 2px solid transparent;
        @include transition(all,0.2s,ease);
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: $color-white;
        @include border-radius(3px);
        border: 1px solid $color-white-three;

        &:after {
            left: 7px;
            top: 0;
            width: 6px;
            height: 13px;
            border: solid $color-violet-blue;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            content: "";
            position: absolute;
            display: none;
        }
    }


    .side-filter-option input:checked ~ .checkmark:after {
        display: block;
    }

    .side-filter-option input:checked + .side-filter-option-single span {
        color: $color-violet-blue;
    }

    .dropdown {
        padding: 0;
        border: 1px solid $color-white-three;
        padding: 15px;
        @include border-radius(15px 15px 0 15px);
        margin-bottom: 15px;
        position: relative;
        display: none;

        @include tablet {
            display: block;
            padding: 20px;
        }

        &.on {
            .dropdown-icon {
                &--main {
                    span {
                        transform: rotate(90deg);

                        &:last-of-type {
                            left: 50%;
                            right: 50%;
                        }
                    }
                }
            }
        }
    }

    .dropdown .dropdown-label {
        width: 100%;
        padding: 0;
        background: 0;
        border: 0;
        height: auto;
        line-height: normal;
        margin: 0;

        &:focus {
            outline: 2px solid rgba(59, 153, 252, 0.7);
        }
    }

    .dropdown:last-child {
        padding: 20px;
        margin-bottom: 30px;
    }

    .filter-bar__arrow {
        right: 15px;
    }

    .filter-bar__postcode {
        width: 100%;
        @include border-radius(20px 20px 0 0);
        background: $color-pale-grey;
        border: 1px solid $color-white-three;
        outline: none;
        font-size: 12px;
        height: 50px;
        padding-left: 15px;
        color: $color-greyish-brown;
        font-weight: 500;

        @include tablet {
            font-size: 14px;
        }
    }

    .filter-bar__title--left {
        display: block;
    }

    .filter-bar__postcode-select {
        width: 100%;
        @include border-radius(0 0 20px 20px);
        font-size: 12px;
        margin-bottom: 30px;
        height: 50px;
        padding-left: 15px;
        background: $color-pale-grey;
        border: 1px solid $color-white-three;
        border-top: 0;
        color: $color-greyish-brown;
        font-weight: 500;

        @include tablet {
            font-size: 14px;
        }
    }

    .dropdown .dropdown-label:before {
        right: 0;
        display: none;
    }

    .dropdown .dropdown-list {
        background: none;
        padding: 0;
        border: 0;
        width: auto;
        left: auto;
        top: auto;
        overflow-y: hidden;
        position: relative;
        max-height: 0;
        @include transition(max-height,0.25s,ease);
        overflow: hidden;
    }

    .dropdown.on .dropdown-list {
        border-top: 1px solid $color-white-three;
        padding-top: 15px;
        margin-top: 15px;
        max-height: 100vh;
    }

    .dropdown .dropdown-icon {
        right: -2px;
    }

    .dropdown .dropdown-label.active {
        background: none;
        border-color: none;
        color: none;
    }

    .dropdown .dropdown-label-span {
        display: block;
        width: 75%;
    }

    .dropdown .dropdown-number.active {
        top: 2px;

        @include tablet {
            right: 30px;
        }

        @include desktop {
            top: 4px;
            right: 36px;
        }
    }

    .filter-bar__toggle {
        display: block;

        @include tablet {
            display: none;
        }
    }

    .dropdown-option {
        color: $color-greyish-brown;

        @include tablet {
            font-size: 13px;
        }

        @include desktop {
            font-size: 14px;
        }
    }
}
