.advice-section {
    @include clearfix();
    position: relative;

    &__toolbar {
        margin-bottom: 30px;

        @include tablet {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
        }
    }

    &__row {
        box-sizing: border-box;
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex: 0 1 auto;
        -webkit-box-flex: 0;
        flex: 0 1 auto;
        -ms-flex-direction: row;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-left: -1rem;
        margin-right: -1rem;
    }

    &__block {
        background: #ffffff;
        @include spectrum-radius();
        margin: $mobile-medium-padding 2px;
        @include grey-border;
        padding: 20px;
        width: 100%;
        position: relative;
        height: 80%;

        &:after {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 5px;
            @include border-radius(0 0 0 25px);
            background: $color-barney;
            content: "";
            width: 99%;

            @include desktop {
                width: 98%;
            }
        }
    }

    &__content {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__header {
        font-size: 24px;
        text-align: center;
        color: $color-violet-blue;
        margin-bottom: $mobile-xsmall-padding;

        @include tablet {
            text-align: left;
            font-size: 28px;
        }


        @include desktop {
            text-align: left;
            font-size: 32px;
        }
    }

    &__description {
        text-align: center;
        font-size: 14px;
        margin-bottom:20px;
        @include tablet {
            text-align: left;
            font-size: 16px;
            margin-bottom:0;
        }
    }

    &__text {
        text-align: center;

        @include desktop {
            text-align: left;
        }
    }

    &__title {
        width: 93%;
        margin-bottom: 3em;
        color: $color-barney;
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
    }

    &__button {
        display: flex;
        justify-content: flex-end;
    }

    &__arrow {
        width: 93%;
        float: right;
        text-align: right;
        padding: 0 1em 1em;
    }

    .arrow-svg {
        height: 30px;
        width: 30px;
        fill: $color-barney;
        opacity: 0.25;
    }

    &--static {
        .advice-section__header {
            font-weight: 500;
            text-align: left;
            font-size: 20px;

            @include tablet {
                font-size: 22px;
            }

            @include desktop {
                font-size: 26px;
            }
        }

        .advice-section__item {
            box-sizing: border-box;
            flex: 0 0 auto;
            padding-right: 1rem;
            padding-left: 1rem;
            flex-basis: 100%;
            max-width: 100%;

            @include tablet {
                flex-basis: 50%;
                max-width: 50%;
            }

            @include desktop {
                flex-basis: 25%;
                max-width: 25%;
            }
        }

        .advice-section__block {
            margin: $mobile-medium-padding 0;
        }
    }

    &--single-column {

    }

    .owl-buttons__button {
        &:hover {
            .owl-buttons__owl-svg {
                fill: $color-white;
                background: $color-violet-blue;
                z-index: 222;
            }
        }
    }

    .owl-buttons__owl-svg {
        width: 100%;
        @include transition(all,0.2s,ease);
        height: auto;
        fill: $color-lavender;
        width: 50px;
        @include border-radius(150px);
        height: 50px;
        padding: 1em;
        background: $color-white;
        @include transition(all,0.2s,ease);
        position: relative;
        border: 1px solid $color-white-three;
    }

    &__buttons {

        @include tablet {
            margin-top: auto;
        }

        .button {
            font-size: 14px;
            width: 100%;

            @include tablet {
                margin-bottom: 0;
                width: auto;
            }

            &:not(:last-child) {
                margin-right: 15px;
            }
        }
    }
}

.advice-carousel {
    .owl-stage-outer {
        padding-top: 10px;
        margin-top: -10px;
    }
}

/*Related Content Block*/

.related-advice {
    background: #ffffff;
    @include spectrum-radius();
    @include grey-border();
    padding: 20px;
    width: 100%;
    position: relative;
    margin-bottom: 15px;
    overflow: hidden;
    height: 218px;

    @include tablet {
        margin-bottom: 30px;
    }

    a {
        display:block;
        width:100%;
        text-decoration:none;
        height:100%;
    }
    &:after {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 5px;
        @include border-radius(0 0 0 25px);
        background: $color-barney;
        content: "";
        width: 100%;
    }

    &__content {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__header {
        font-size: 22px;
        text-align: center;
        color: $color-violet-blue;
        margin-bottom: $mobile-xsmall-padding;

        @include tablet {
            text-align: left;
            font-size: 28px;
        }


        @include desktop {
            text-align: left;
            font-size: 32px;
        }
    }

    &__title {
        width: 93%;
        margin-bottom: 3em;
        color: $color-barney;
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;

        @include tablet {
            min-height: 60px;
        }
    }

    &__button {
        display: flex;
        justify-content: flex-end;
    }

    &__arrow {
        width: 93%;
        float: right;
        text-align: right;
        padding: 0 1em 1em;
    }

    &__text {
        text-align: center;

        @include desktop {
            text-align: left;
        }
    }

    .arrow-svg {
        height: 30px;
        width: 30px;
        fill: $color-barney;
        opacity: 0.25;
    }
}
