.event-page {
    padding: 40px 15px;

    @include tablet {
        padding: 80px 30px 60px;
    }

    &__container {
        max-width: 964px;
        margin: 0 auto;
    }

    &__header {
        font-weight: 600;
        color: $color-violet-blue;
        letter-spacing: -0.5px;
        margin-bottom: 15px;
        font-size: 22px;

        @include tablet {
            margin-bottom: 30px;
            font-size: 32px;
        }
    }

    &__about {
        ul {
            @include reset-list();
            list-style: none;
            display: block;
            color: $color-greyish-brown;
            padding: 10px 0 20px;

            @include tablet {
                padding: 15px 0;
            }

            ul {
                padding: 10px 0 0;

                @include tablet {
                    padding: 15px 0 0;
                }
            }

            li {
                margin-bottom: $desktop-xsmall-padding;
                padding-left: 20px;
                position: relative;
                font-size: 14px;
                line-height: 24px;

                @include tablet {
                    font-size: 16px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 9px;
                    width: 6px;
                    height: 6px;
                    background-color: $color-violet-blue;
                    border-radius: 50%;
                    -moz-border-radius: 50%;
                    -webkit-border-radius: 50%;
                }
            }
        }

        p {
            margin-bottom: 15px;
        }

        h1, h2, h3 {
            margin-bottom: 15px;
            color: $color-violet-blue;
        }

        h1 {
            font-weight: bold;
            line-height: 1.17;
            letter-spacing: -0.5px;
            margin-bottom: 20px;
            font-size: 24px;

            @include tablet {
                margin-bottom: 30px;
                font-size: 46px;
            }
        }

        h2 {
            font-weight: 600;
            font-size: 22px;

            @include tablet {
                font-size: 32px;
            }
        }

        h3 {
            font-weight: 500;
            font-size: 20px;

            @include tablet {
                font-size: 26px;
            }
        }
    }

    p {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $color-greyish-brown;

        @include tablet {
            font-size: 16px;
        }
    }

    .event-information {
        @include spectrum-radius();
        border: 1px solid $color-white-three;
        padding: 20px;
        margin: 20px 0 0;

        @include tablet {
            margin: 0;
            padding: 30px;
        }

        &__block {
            display: flex;
            padding: 15px 0;
            border-bottom: 1px solid $color-white-three;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
            }
        }

        &__icon {
            padding-right: 30px;
            display: none;

            @include tablet {
                display: block;
            }

            img {
                max-width: 40px;
            }
        }

        &__header {
            font-weight: 500;
            font-size: 14px;
            color: $color-violet-blue;
            margin-bottom: 5px;

            @include tablet {
                font-size: 16px;
            }
        }

        &__event {
            margin-bottom: 10px;
            border-bottom: 1px solid $color-white-three;
            padding-bottom: 15px;

            @include tablet {
                display: flex;
                margin-bottom: 15px;
                flex-flow: wrap;
                align-items: center;
            }

            label {
                line-height: 1.5;
                font-size: 14px;

                @include tablet {
                    font-size: 16px;
                }

            }

            .button {
                width: 100%;
                margin-top: 10px;

                @include desktop {
                    width: auto;
                    margin-top: 0;
                    margin-left: auto;
                }
            }

            &:last-child:not(:first-child) {
                margin-bottom: 0;
                border-bottom: 0;
                padding-bottom: 0;
            }

            &--signup {
                form {
                    width:100%;
                    @include desktop{
                        width:auto;
                        margin-left:auto;
                    }
                }
                label {
                    line-height: 1.5;
                    font-size: 14px;

                    @include tablet {
                        font-size: 16px;
                    }
                    @include desktop {
                        flex:1;
                        padding-right:2px;
                    }
                }
            }
        }
    }
}
