.pagination {
    display: inline-flex;
    align-items: center;

    &__list {
        @include reset-list();

        display: flex;
        margin: 0 15px;
    }

    &__li {
        display: flex;
        align-items: center;
    }

    &__prev,
    &__next {
        @include reset-button();

        height: 10px;
        padding: 0 15px;
        color: $color-violet-blue;
        cursor: pointer;

        &[disabled] {
            color: $color-pinkish-grey;
            cursor: default;
        }
    }

    &__page,
    &__dots {
        width: 40px;
        height: 40px;
        margin: 0 5px;
        color: $color-warm-grey;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        text-align: center;
        cursor: pointer;
    }

    &__page {
        background-color: white;
        border: 1px solid $color-white-three;
        border-radius: 50%;

        &[disabled] {
            color: $color-violet-blue;
            cursor: default;
        }

        &.is-current {
            color: $color-violet-blue;
            pointer-events: none;
        }
    }

    &__dots {
        background-color: transparent;
        border: none;
        cursor: default;
    }
}
