.footer {
    &__subscription-block {
        background: $color-pale-grey;
        text-align: center;
        padding: $mobile-large-padding 15px;

        @include desktop {
            padding: $desktop-medium-padding 30px;
        }

        .button {
            width: 100%;

            @include tablet {
                width: auto;
            }
        }

        &--light {
            background: $color-white;
            border-bottom: 1px solid $color-white-three;
            border-top: 1px solid $color-white-three;

            .footer__subscription-title {
                color: $color-violet-blue;
            }
        }

        &--hide {
            display: none;

            + .footer__sub-footer {
                border-top: 1px solid $color-white-three;
            }
        }
    }

    &__subscription-title {
        font-size: 18px;
        margin-bottom: 15px;
        line-height: 28px;
        font-weight: 500;

        @include desktop {
            font-size: 22px;
            margin-bottom: 30px;
        }
    }

    .container {
        max-width: 1260px;
        margin: 0 auto;
        width: auto;

        @media screen and (min-width:1370px) {
            max-width: none;
        }
    }

    &__button {
        background: none;
        width: 100%;
        padding: $mobile-small-padding;
        outline: none;
        border: none;

        @include desktop {
            display: none;
        }

        &:hover {
            background: none;
        }
    }

    &__button-title {
        font-size: 16px;
        color: $color-violet-blue;
        width: 100%;
        margin: 0;
        text-align: left;
    }

    &__icon {
        position: absolute;
        right: 10px;
        top: 18px;
        width: 20px;
        height: 20px;

        span {
            position: absolute;
            transition: .3s;
            background: $color-pinkish-grey;
            border-radius: 2px;

            &:first-of-type {
                top: 25%;
                bottom: 25%;
                width: 10%;
                left: 45%;
            }

            &:last-of-type {
                left: 25%;
                right: 25%;
                height: 10%;
                top: 45%;
            }
        }
    }

    &__sub-footer {
        padding: $desktop-small-padding 15px;

        @include desktop {
            padding: $desktop-small-padding;
        }

        @media screen and (min-width:1366px) {
            padding: 30px 60px;
        }
    }

    &__sub-nav {
        border: 1px solid $color-white-three;
        @include border-radius(20px 20px 0);
        margin-bottom: $mobile-small-padding;

        @include desktop {
            border: 0;
            @include border-radius(0);
            margin-bottom: 0;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 100px;
        }
    }

    &__top {
        width: 100%;
        padding: 0 1rem;

        @include desktop {
            display: flex;
        }
    }

    &__list {
        padding: 0;
        margin: 0;
        height: 0;
        @include transition(height,0.5s,ease);
        overflow: hidden;

        @include desktop {
            height: auto;
            margin: 0 0 1em;
        }

        &.active {
            height: auto;
        }

        &:focus {
            outline: none;
        }
    }

    &__item {
        display: block;
        text-align: center;

        @include desktop {
            display: inline-block;
        }

        &:first-child {
            .footer__link {
                @include desktop {
                    padding-left: 0;
                }
            }
        }

        &:last-child {
            .footer__link {
                @include desktop {
                    padding-right: 0;
                }
            }
        }
    }

    &__link {
        display: block;
        text-decoration: none;
        padding-top: 1em;
        padding-bottom: 1em;
        padding-right: 2em;
        font-weight: 700;
        color: $body-font-color;

        @include desktop {
            font-size: 0.9em;
        }
    }

    &__sub-text {
        font-size: 10px;
        color: $color-warm-grey-two;
        margin-bottom: 15px;
        text-align: center;

        @include desktop {
            margin-bottom: 0;
            text-align: left;
        }
    }


    .social {
        margin-bottom: 20px;

        @include desktop {
            margin-bottom: 0;
            margin-left: auto;
        }

        &__list {
            margin: 0;
            padding: 0;

            @include desktop {
                text-align: right;
            }
        }

        &__item {
            text-align: center;
            display: inline-block;

            &:not(:last-child) {
                margin-right: 5px;
            }
        }

        &__link {
            text-decoration: none;
            color: $primary-color;
            @include grey-border();
            height: 30px;
            width: 30px;
            line-height: 30px;
            @include border-radius(150px);
            display: block;
            align-items: center;
            display: flex;
            justify-content: center;

            @include tablet {
                height: 40px;
                width: 40px;
                line-height: 40px;
            }

            img {
                max-width: 17px;
            }
        }
    }

    &__fundrasier {
        text-align: center;

        @include desktop {
            text-align: right;
        }

        img {
            max-height: 50px;

            @include tablet {
                max-height: 48px;
            }
        }
    }
}
