.notification-item {
    p {
        color: #363636;
    }
}

.service-directory {

    &__hero-content {
        margin-left: 15px;
        padding-top:  40px;

        @include desktop {
            margin-left: 60px;
            padding-top:  40px;
        }

        .title-button {
            position: relative;
            z-index: 99;

            @include desktop {
                display: flex;
                justify-content: space-between;
            }

            a {
                position: relative;
                display: flex;
                border-radius: 20px;
                background: $color-purple-pink;
                border: none;
                padding-right: 70px;
                font-weight: 500;
                height: 60px;
                color: $color-white;
                margin-bottom: 15px;
                text-decoration: none;

                @include desktop {
                    height: 80px;
                    margin-right: 60px;
                    margin-bottom: 0;
                }

                .service-directory__arrow {
                    top: 18px;

                    @include desktop {
                        top: 27px;
                    }
                }

                span {
                    @include desktop {
                        width: 60%;
                        display: block;
                        margin: auto;
                    }
                }
            }

            h2 {
                color: white;
                padding-bottom: 20px;
            }
        }
    }

    .hero-banner {
        flex-direction: column;
    }

    form {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top:  40px;

        @include desktop {
            padding-top:  40px;
        }

        .service-directory__search-bar-new {
            margin: 0 15px;
            flex-direction: column;
            flex: 1;
            gap: 20px;
            display: grid;



            @include desktop {
                margin: 0 75px;
                gap: 40px;
                justify-content: space-between;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(1, 1fr);
            }

        }

        .input-sd {
            height: 80px;
            display: flex;
            position: relative;
            align-items: center;
            background: white;
            border-bottom-left-radius: 20px;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;

            input[type=text] {
                border-bottom-left-radius: 20px;
                border-top-left-radius: 20px;
                height: 80px;
                padding-left: 20px;
                flex: 1;
                border: none;

                &.prefix {
                    padding-left: 70px;
                }

                &::placeholder {
                    font-weight: 600;
                    color: $primary-color;
                }
            }

            svg.icon-prefix {
                position: absolute;
                height: 55px;
                left: 10px;
                width: 60px;
            }

        }

        .search-button {
            position: relative;
            border-radius: 20px;
            background: $color-purple-pink;
            border: none;
            flex: 1;
            font-weight: 500;
            width: 100%;
            height: 80px;
            color: $color-white;
        }
    }

    .service-grid-container {
        margin: 0 15px;
        display: flex;

        @include desktop {
            width: calc(100% - 30px);
        }

        .service-grid {
            gap: 20px;
            display: grid;


            @include desktop {
                width: 100%;
                margin: 0 60px;
                gap: 40px;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(3, 1fr);
            }
        }
    }

    .category-title {
        padding: 20px 0;
        color: white;
        text-align: left;
        align-self: baseline;
        margin: 0 15px;

        @include desktop {
            margin: 0 60px;
        }
    }

    .service-tile {
        position: relative;
        background: $color-white;
        height: 80px;
        padding: 20px;
        display: flex;
        align-items: center;
        border-radius: 20px;

        span {
            color: $primary-color;
            font-weight: bold;
            padding-left: 10px;
        }

        svg {
            height: 60px;
            width: 60px;
        }
    }

    &__checkbox {
        margin: 20px 15px 0;
        display: flex;
        flex-direction: column;

        @include desktop {
            margin: 20px 60px 0;
            align-self: baseline;
        }
    }

    input[type="checkbox"]:checked ~ .service-tile {
        opacity: 0.8;
    }

    #resultsMap {
        p {
            i {
                display: none;
            }
        }
    }

    button.search {
        background: $color-purple-pink;
        border-radius: 20px;
        padding: 10px 100px;
        color: $color-white;
        margin: 20px 0;
        font-weight: bold;
        border: none;
    }

    .nas-services {
        position: relative;
        color: $color-white;
        padding: 0 15px 0 35px;

        input {
            display: none;

            &:checked ~ .checkmark:after {
                display: block;
            }
        }



        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #FFFFFF;
            border-radius: 3px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border: 1px solid #E0E0E0;

            &::after {
                left: 7px;
                top: 0;
                width: 6px;
                height: 13px;
                border: solid #4203BF;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
                content: "";
                position: absolute;
                display: none;
            }
        }
    }

    .hero-banner {
        padding: 20px 0;
        position: relative;

        .hero-svg-container {
            position: absolute;
            right: 0;
            height: 100vh;
            top: 0;
            width: 35vw;
            overflow: hidden;

            &__svg {
                width: 60vw;
                height: 100vw;
                position: absolute;
                top: -65%;
                right: -50%;
            }
        }

        &__title {
            margin-bottom: 15px;

            @include tablet {
                font-size: 46px;
            }
        }

        &__text-block {
            &--left {
                @include tablet {
                    padding: 85px 60px 160px;
                }
            }
        }

        p {
            font-size: 14px;
            color: $color-white;
            opacity: 0.8;
            line-height: 24px;
            width: 80%;

            @include tablet {
                width: 70%;
                font-size: 16px;
            }

            @include desktop {
                width: 46%;
            }
        }

        &__curve {
            fill: $color-pale-grey;
        }
    }

    &__search {
        position: relative;

        @include tablet {
            width: 100%;
            display: flex;
            align-items: flex-end;
        }

        @include desktop {
            width: 80%;
        }

        @include desktop-wide {
            width: 70%;
        }

        label {
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 500;
            line-height: 1.8;
            letter-spacing: 1.5px;
            color: $color-white;
            margin-bottom: 10px;
            display: flex;

            @include tablet {
                margin-bottom: 15px;
                font-size: 12px;
            }
        }

        input,
        select {
            border: 0;
        }

        input[type="submit"]{
            &:hover{
                cursor:pointer;
            }
        }
    }

    &__search-bar {
        margin-bottom: 15px;
        position: relative;

        @include tablet {
            margin-bottom: 0;
            width: 40%;
        }

        input {
            @include border-radius(20px);
            width: 100%;
            padding-left: 49px;
            font-size: 12px;
            color: $color-warm-grey;
            height: 50px;

            @include tablet {
                height: 80px;
                font-size: 14px;
                padding-left: 55px;
                @include border-radius(20px 0 0 20px);
            }
        }
    }

    &__icon {
        position: absolute;
        left: 25px;
        top: 45px;
        width: 16px;
        height: 16px;
        fill: $color-pinkish-grey;

        @include tablet {
            top: 66px;
            width: 20px;
            height: 20px;
        }
    }

    &__postcode {
        position: relative;

        @include tablet {
            width: 40%;
        }

        input {
            width: 100%;
            padding-left: 49px;
            font-size: 12px;
            @include border-radius(20px 20px 0 0);
            border-bottom: 1px solid $color-white-three;
            color: $color-warm-grey;
            height: 50px;

            @include tablet {
                @include border-radius(0);
                border-bottom: 0;
                border-left: 1px solid $color-white-three;
                border-right: 1px solid $color-white-three;
                font-size: 14px;
                height: 80px;
                padding-left: 55px;
            }
        }
    }

    &__select {
        margin-bottom: 15px;
        position: relative;

        @include tablet {
            margin-bottom: 0;
            width: 17%;
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 5px;
            width: 9px;
            height: 9px;
            color: #C4C4C4;
            transform: translateY(-90%) rotateZ(-45deg);
            transition: transform 0.3s;
            transform-origin: 100%;
            border-color: transparent transparent currentcolor currentcolor;
        }

        select {
            width: 100%;
            font-size: 12px;
            @include border-radius(0 0 20px 20px);
            padding-left: 16px;
            height: 50px;

            @include tablet {
                font-size: 14px;
                height: 80px;
                @include border-radius(0);
            }
        }
    }

    &__submit {
        background: $color-purple-pink;
        border: 0;
        outline: 0;
        height: 80px;
        color: $color-white;
        @include border-radius(0 20px 0 0);
        width: 75px;
        font-size: 0px;

        @include tablet {
            font-size: 0;
            @include border-radius(0 20px 0 0);
            width: 75px;
            height: 80px;
        }
    }

    &__arrow {
        fill: $color-white;
        width: 50px;
        height: 25px;
        position: absolute;
        top: 27px;
        right: 15px;
        pointer-events: none;

        &:hover{
            cursor:pointer;
        }
    }

    .generic-section.footer-curved {
        .generic-section__footer {
            bottom: -62px;
            background:$color-white;
        }
    }
}

.featured-services {
    position: relative;

    &__title {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.27;
        letter-spacing: -0.5px;
        color: $color-violet-blue;
        margin-bottom: 30px;

        @include tablet {
            font-size: 32px;
        }
    }

    &__row {
        box-sizing: border-box;
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex: 0 1 auto;
        -webkit-box-flex: 0;
        flex: 0 1 auto;
        -ms-flex-direction: row;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }


    &__carousel {
        .service-card__item {
            margin-bottom: 0;

            @include tablet {
                min-height: 287px;
            }
        }
    }

    .owl-buttons__button {
        &:hover {
            .owl-buttons__owl-svg {
                fill: $color-white;
                background: $color-violet-blue;
                z-index: 222;
            }
        }
    }

    .owl-buttons__owl-svg {
        width: 100%;
        @include transition(all,0.2s,ease);
        height: auto;
        fill: $color-lavender;
        width: 50px;
        @include border-radius(150px);
        height: 50px;
        padding: 1em;
        background: $color-white;
        @include transition(all,0.2s,ease);
        position: relative;
    }
}


.service-card {
    &__item {
        @include spectrum-radius();
        border: 1px solid $color-white-three;
        padding: 20px;
        position: relative;
        background: $color-white;
        margin-bottom:10px;
        @include tablet {
            margin-bottom:15px;
            padding: 30px;
        }
    }

    &__title {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.22;
        letter-spacing: -0.5px;
        color: $color-barney;
        margin-bottom: 10px;
        width: 80%;
        line-height:22px;

        @include tablet {
            width: 80%;
            margin-bottom: 15px;
            font-size: 20px;
        }
    }

    &__location {
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 500;
        line-height: 1.8;
        letter-spacing: 1.5px;
        margin-bottom: 10px;
        color: $color-warm-grey-two;

        @include tablet {
            margin-bottom: 15px;
            font-size: 12px;
        }
    }

    &__description {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $color-greyish-brown;

        @include tablet {
            font-size: 14px;
        }
    }

    &__link {
        text-decoration:none;
    }

    &__button {
        position: absolute;
        right: 20px;
        top: 20px;

        @include tablet {
            right: 30px;
            top: 30px;
        }
    }

    &__arrow {
        height: 30px;
        width: 30px;
        fill: $color-lavender;
    }
}

.service-tabs {
    &__title {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.27;
        letter-spacing: -0.5px;
        color: $color-violet-blue;
        margin-bottom: 30px;

        @include tablet {
            font-size: 32px;
        }
    }

    &__list {
        background: $color-pale-grey;
        padding: 14px 14px 0 14px;
        overflow-y: scroll;
        height: 100%;
        overflow-x: hidden;
    }

    &__nav-list {
        list-style: none;
        margin: 0 0 15px;
        padding: 0;
        display: flex;
        align-items: flex-end;
        height: 13%;
        border-bottom: 2px solid $color-white-three;


        @include mobile-only {
            flex-wrap: nowrap;
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        @include tablet {
            list-style: none;
            height: 10%;
        }
    }

    &__nav-li {
        text-align: left;
    }

    &__nav-link {
        color: $color-warm-grey;
        text-align: left;
        font-size: 14px;
        text-decoration: none;
        display: block;
        font-size: 14px;
        padding: 10px;
        position: relative;

        @include tablet {
            padding: 16px 40px;
            font-size: 16px;
        }

        &:after {
            height: 2px;
            width: 100%;
            bottom: -2px;
            content: '';
            position: absolute;
            left: 0;
            @include transition(all,0.5s,ease);
        }

        &:hover {
            color: $color-violet-blue;
            font-weight: 500;

            &:after {
                background: $color-violet-blue;
            }
        }

        &.is-active {
            color: $color-violet-blue;
            font-weight: 500;

            &:after {
                background: $color-violet-blue;
            }
        }
    }

    &__section {
        width: 100%;
        display: none;

        &.is-active {
            display: block;
        }
    }

    &__service-list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
    }

    &__service-item {
        flex: 1 100%;
        position: relative;

        @include tablet {
            flex-basis: 50%;
            width: 50%;
            flex: 0 0 auto;
        }

        @include desktop {
            flex-basis: 33.3333%;
            width: 33.3333%;
            flex: 0 0 auto;
        }
    }

    &__service-link {
        text-decoration: none;
        color: $color-violet-blue;
        padding: 10px;
        font-weight: 500;
        display: block;
        font-size: 12px;

        @include tablet {
            font-size: 16px;
        }

        &:hover {
            .service-tabs__arrow {
                opacity: 1;
            }
        }
    }

    &__arrow {
        position: absolute;

        @include tablet {
            right: 30px;
        }

        max-height: 15px;
        max-width: 20px;
        fill: $color-lavender;
        opacity: 0;
        @include transition(all,0.25s,ease);
    }
}


.recently-added {

    @include tablet {
        position: relative;
    }

    &__header {
        margin-bottom: 15px;

        @include tablet {
            margin-bottom: 30px;
            display: flex;
            align-content: center;
            justify-content: space-between;
        }
    }

    .button {
        width: 100%;
        margin-top:5px;
        @include tablet {
            width: auto;
            position: absolute;
            right: 0;
            margin-top:0;
            top: 0;
        }
    }

    &__title {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.27;
        letter-spacing: -0.5px;
        color: $color-violet-blue;

        @include tablet {
            font-size: 32px;
        }
    }
}
