.notification-bell {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: $color-pale-grey;
    border-radius: 50%;
    color: $color-greyish-brown;

    @include desktop {
        width: 50px;
        height: 50px;
        background-color: transparent;
    }

    &.is-active {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 10px;
            right: 10px;
            display: block;
            width: 7px;
            height: 7px;
            background-color: $color-rosy-pink;
            border-radius: 50%;

            @include desktop {
                top: 15px;
                right: 15px;
            }
        }
    }
}
