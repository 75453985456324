.form-steps {
    position: relative;
    height: 5px;
    background-color: $color-lavender;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 100%;
        background-color: $color-violet-blue;
    }

    &--step-1 {
        &::before {
            width: 33%;
        }
    }

    &--step-2 {
        &::before {
            width: 66%;
        }
    }

    &--complete {
        background-color: $color-violet-blue;

        &::before {
            content: normal;
        }
    }

}
