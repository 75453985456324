

.service-editor-toolbar {
    margin-top: -34px;
    float: right;
}


.modal .box p {
    margin-bottom: 16px;
}


/* modifications for quill editor */
.ql-container {
    font-family: unset!important;
    font-size: unset!important;
}

.ql-editor p, .ql-editor li {
    margin-bottom: 10px !important;
}

.is-invalid .ql-editor,
.is-invalid .ktc-radio label::before {
    border: 1px solid #A22602!important;
}
.form-field.is-invalid .ql-editor,
.form-field.is-invalid .multi-select-zone {
    /* border: 1px solid #A22602;*/
}

.ql-container.ql-snow, .ql-editor {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    -moz-border-radius-bottomleft: 10px;
    -moz-border-radius-bottomright: 10px;
}

.ql-toolbar.ql-snow {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
}
