.generic-section {
    $this: &;

    position: relative;
    z-index: 0;
    padding-right: $padding-side-mobile;
    padding-left: $padding-side-mobile;
    background-color: white;

    @include tablet {
        padding-right: $padding-side-tablet;
        padding-left: $padding-side-tablet;
    }

    &__container {
        max-width: $narrow-container-max-width;
        margin: 0 auto;
    }

    &__content {
        position: relative;
    }

    &__col {
        &.split {
            width: 100%;

            @include tablet {
                width: 50%;
            }
        }
    }

    &__header,
    &__footer {
        position: absolute;
        left: 0;
        z-index: -10;
        display: none;
        width: 100%;
    }

    &--one-column,
    &--two-column {
        #{$this}__content {
            justify-content: center;
        }
    }

    &--h-center {
        #{$this}__col {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &--h-right {
        #{$this}__col {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }

    &.header-curved {
        #{$this}__header {
            top: 0;
            display: block;
        }
    }

    &.footer-curved {
        #{$this}__footer {
            bottom: -4px;
            display: block;
            height: 120px;
        }

        #{$this}__footer-curve {
            position: absolute;
            top: 1px;
            transform: translateY(-100%);
            width: 100%;
        }
    }

    &.full {
        #{$this}__container {
            max-width: none;
        }
    }

    &.no-side-padding {
        padding-right: 0;
        padding-left: 0;
    }
}
