.region-selector {
    background: $color-barney;
    padding: 20px;
    @include spectrum-radius();
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;

    @include tablet {
        margin-bottom: 0;
        width: 290px;
        position: fixed;
        top: 30%;
        right: 30px;
        z-index: 222;
    }

    &__description {
        font-size: 12px;
        line-height: 1.5;
        color: $color-white;
        margin-bottom: 10px;
        width: 70%;
        flex-basis: 70%;
    }

    &__close {
        background: $color-white;
        display: flex;
        width: 20px;
        height: 20px;
        margin-left: auto;
        @include border-radius(150px);
        align-items: center;
        justify-content: center;

        @include tablet {
            width: 30px;
            height: 30px;
        }
    }

    &__svg {
        height: 7px;
        width: 7px;
        fill: $color-barney;

        @include tablet {
            height: 10px;
            width: 10px;
        }
    }

    &__form {
        flex-basis: 100%;
        width: 100%;
    }

    &__select {
        background: $color-white;
        border: 0;
        font-size: 12px;
        font-weight: 500;
        color: $color-greyish-brown;
        height: 40px;
        border: 1px solid $color-white-three;
        width: 100%;
        @include border-radius(10px);
        padding-left: 10px;
    }

    &.close {
        display: none;
        visibility: hidden;
    }
}
