.contact-card {
    padding: 30px;
    background: $color-white;
    @include grey-border();
    @include border-radius(20px 20px 0 0);
    position: relative;

    @include tablet {
        @include border-radius(20px 0 0 20px);
    }

    &__title {
        font-weight: 600;
        line-height: 1.31;
        letter-spacing: -0.5px;
        color: $color-violet-blue;
        font-size: 22px;
        margin-bottom: 15px;

        @include tablet {
            font-size: 32px;
            margin-bottom: 30px;
        }
    }

    &__description {
        font-size: 14px;
        margin-bottom: 15px;
        line-height: 1.5;
        letter-spacing: normal;
        color: $color-greyish-brown;
        font-weight: 500;

        @include tablet {
            font-size: 16px;
        }
    }

    &__item {
        padding-bottom: 15px;
        display: flex;
    }

    &__icon {
        height: auto;
        vertical-align: top;
        max-height: 40px;
        position: absolute;
        right: 32px;
        top: 25px;

        @include tablet {
            top: 30px;
            max-height: 50px;
        }
    }

    &__subtitle {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        line-height: 1.38;
        color: $color-greyish-brown;

        @include tablet {
            font-size: 16px;
        }

        span {
            color: lighten($color-greyish-brown,20%);
        }
    }

    &__text {
        color: $color-greyish-brown;
        font-size: 14px;
        line-height: 24px;

        @include tablet {
            font-size: 16px;
        }
    }

    .button {
        padding: 10px 62px;
        font-size: 14px;
        font-weight: 700;
        margin: 15px 0 30px 0;
    }

    &__legal {
        font-size: 10px;
        line-height: 1.6;
        color: $color-warm-grey;
    }
}

.map-section {
    width:100%;
    height:500px;
}


.map-box {
    padding: 15px;
    font-family: hero-new, Century Gothic, Helvetica, Arial, sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

    &__title {
        font-weight: 600;
        line-height: 1.31;
        letter-spacing: -0.5px;
        color: $color-violet-blue;
        font-size: 18px;
        margin-bottom: 5px;

        @include tablet {
            margin-bottom: 10px;
            font-size: 22px;
        }
    }

    &__description {
        font-size: 14px;
        margin-bottom: 15px;
        line-height: 1.5;
        letter-spacing: normal;
        color: $color-greyish-brown;
        font-weight: 500;
    }

    &__phone {
        font-size: 14px;
        margin-bottom: 15px;
        line-height: 1.5;
        letter-spacing: normal;
        color: $color-greyish-brown;
        font-weight: 500;
    }

    .button {
        padding: 10px 62px;
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 15px 0;
    }

    &__legal {
        font-size: 10px;
        line-height: 1.6;
        color: $color-warm-grey;
    }
}
