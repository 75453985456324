.copy-to-clipboard {
    position: relative;

    &::before {
        content: 'Copied!';
        position: absolute;
        top: -105%;
        left: 50%;
        opacity: 0;
        visibility: hidden;
        z-index: 1;
        display: inline-block;
        transform: translateX(-50%);
        padding: 3px 10px;
        background-color: $color-greyish-brown;
        border-radius: 10px;
        color: white;
        font-size: 12px;
        line-height: 1.5;
        transition: opacity 1s, visibility 1s;
    }

    &.is-visible {
        &::before {
            opacity: 1;
            visibility: visible;
        }
    }
}
