.account-events-list {
    @include reset-list();

    &__item {
        & + & {
            margin-top: 20px;

            @include tablet {
                margin-top: 30px;
            }
        }
    }
}
