.fundraiser-totaliser {
    &__intro {
        color: $color-violet-blue;
        text-align: center;
        margin-bottom: 30px;
        font-weight: 700;

        @include tablet {
            font-size: 31px;
        }

        @include desktop {
        }

        .button {
            width: auto;
            margin-bottom: 10px;

            @include tablet {
                margin-bottom: 20px;

            }
        }
    }

    &__block {
        background: $color-dark-slate-blue;
        @include spectrum-radius();
        position: relative;
        padding: 25px;

        @include mobile-only {
            display: flex;
            justify-content: center;
        }


        @include tablet {
            padding: 30px;
        }

        @include desktop {
            padding: 40px;
        }
    }

    &__title {
        color: $color-violet-blue;
        margin-bottom: 10px;

        @include tablet {
            font-size: 31px;
            margin-bottom: 20px;
        }

        @include desktop {
            margin-bottom: 10px;
        }
    }

    &__animated-section {
        overflow: hidden;
        position: relative;
    }

    &__animated-block {
        background: $color-white;
        display: flex;
    }

    &__image {
        width: 100%;
        position: relative;
        z-index: 2;
    }

    &__animated-bg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0%;
        background: $color-turquoise;
    }

    &__sub-title {
        background: $color-turquoise;
        padding: 10px 20px;
        color: $color-white;
        position: absolute;
        @include border-radius(10px 10px 0 10px);
        font-weight: 500;
        line-height: 1;
        font-size: 18px;
        top: -25px;

        @include tablet {
        }

        @include desktop {
            font-size: 25px;
        }
    }

    &__total {
        color: $color-white;
        margin-bottom: 15px;
        text-align: center;
        font-weight: 700;
        margin-top: 10px;
        font-size: 40px;

        @include tablet {
            margin-top: 5px;
        }

        @include desktop {
            margin-bottom: 20px;
            margin-top: 15px;
            font-size: 60px;
        }
    }

    &__current-total {
        background: $color-white;
        color: $color-dark-slate-blue;
        @include border-radius(15px 15px 0 15px);
        padding: 10px 20px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 20px;
        line-height: 1;
        font-size: 35px;
        margin-top: 25px;

        @include tablet {
            margin-bottom: 15px;
            margin-top: 0;
        }

        @include desktop {
            padding: 20px 30px;
            margin-bottom: 30px;
            font-size: 48px;
        }

        &--hidden {
            display: none;
        }
    }

    &__current-total-block {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        justify-content: center;

        @include tablet {
            margin-bottom: 15px;
        }

        @include desktop {
            margin-bottom: 20px;
        }
    }

    &__total-percent {
        color: $color-turquoise;
        font-weight: bold;
        text-align: center;
        box-sizing: border-box;
        font-size: 30px;
        flex: 0 0 auto;
        padding-right: 1rem;
        padding-left: 1rem;
        flex-basis: 50%;
        max-width: 50%;

        @include tablet {
            font-size: 32px;
            flex-basis: 30%;
            max-width: 30%;
        }

        @include desktop {
            font-size: 42px;
        }
    }


    &__total-text {
        color: $color-white;
        font-weight: 500;
        box-sizing: border-box;
        flex: 0 0 auto;
        padding-right: 1rem;
        padding-left: 1rem;
        flex-basis: 50%;
        max-width: 50%;
        font-size: 14px;

        @include tablet {
            flex-basis: 50%;
            max-width: 50%;
        }

        @include desktop {
            flex-basis: 55%;
            max-width: 55%;
            font-size: 19px;
        }
    }

    &__share-block {
        border: 1px solid $color-turquoise;
        @include border-radius(15px 15px 0 15px);
        padding: 20px;
        text-align: center;
        margin-bottom: 20px;

        @include tablet {
            margin-bottom: 20px;
            padding: 15px;
        }

        @include desktop {
            margin-bottom: 20px;
            padding: 30px;
        }

        .button {
            width: 100%;
            margin-bottom: 20px;
            font-size: 14px;

            @include tablet {
                margin-bottom: 15px;
            }

            @include desktop {
                margin-bottom: 20px;
                font-size: 18px;
            }
        }
    }

    &__cta-text {
        color: $color-white;
        font-weight: 500;
        margin-bottom: 20px;
        font-size: 14px;

        @include tablet {
            margin-bottom: 15px;
        }

        @include desktop {
            margin-bottom: 20px;
            font-size: 19px;
        }
    }

    &__share-text {
        color: $color-white;
        text-align: center;
        font-size: 14px;
        position: relative;

        @include desktop {
            font-size: 16px;
        }

        a {
            text-decoration: underline;
            color: $color-white;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__copied-text {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        text-align: right;
        opacity: 0;
        transform: translateY(-1em);
        color: $color-white;
        transition: all .500s;
        font-size: 11px;

        @include tablet {
            font-size: 16px;
        }

        &--copied {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &__small-text {
        color: $color-white;
        font-size: 12px;
        line-height: 16px;
        text-align: center;

        @include tablet {
            font-size: 10px;
        }

        @include desktop {
            font-size: 12px;
        }
    }
}
