.related-content {
    &__title {
        font-size: 24px;
        text-align: center;
        color: $color-violet-blue;
        margin-bottom: $mobile-xsmall-padding;

        @include tablet {
            text-align: left;
            font-size: 28px;
        }


        @include desktop {
            text-align: left;
            font-size: 32px;
        }
    }

    &__subtitle {
        text-align: center;
        font-size: 14px;
        margin-bottom: 20px;

        @include tablet {
            text-align: left;
            font-size: 16px;
        }
    }
}
