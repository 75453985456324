.quantity {
    $this: &;

    display: flex;

    &__button {
        position: relative;
        width: 42px;
        height: 46px;
        padding: 0;
        background-color: white;
        border: 1px solid $color-silver;
        font-size: 14px;
        cursor: pointer;

        &--minus {
            border-right: none;
            border-radius: 12px 0 0 12px;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 12px;
                height: 2px;
                background-color: $color-pinkish-grey;
            }
        }

        &--plus {
            border-left: none;
            border-radius: 0 12px 12px 0;

            &::after,
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: $color-pinkish-grey;
            }

            &::before {
                width: 12px;
                height: 2px;
            }

            &::after {
                width: 2px;
                height: 12px;
            }
        }

        &.invalid {
            border-color: $color-rust-red;

            &::before,
            &::after {
                background-color: $color-rust-red;
            }
        }
    }

    &__input {
        width: 52px;
        height: 46px;
        margin: 0;
        background-clip: padding-box;
        border: 1px solid $color-silver;
        border-radius: 0;
        font-size: 14px;
        text-align: center;

        @include tablet {
            width: 50px;
        }

        /* Remove Arrows/Spinners */

        /* Firefox */
        &[type="number"] {
            appearance: textfield;
        }

        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none;
        }

        &.input-validation-error {
            border-color: $color-rust-red;
        }
    }

    &__text {
        display: none;
        align-self: center;
        padding: 0 6px;
        font-size: 14px;
    }

    &--basket {
        #{$this}__input {
            display: none;
        }

        #{$this}__text {
            display: block;
        }

        #{$this}__button {
            width: 20px;
            height: 20px;
            border: none;
        }
    }

    &.is-disabled {
        opacity: 0.5;
    }
}
