.account-selector {
    &__options {
        padding: 15px;
        color: $color-violet-blue;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.3;
    }

    &__option {
        vertical-align: middle;
        color: $color-greyish-brown;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
    }
}
