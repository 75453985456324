.modal {
    $this: &;
    $button-width: 30px;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    z-index: $layer-modal;
    display: none;
    align-items: center;
    padding: 15px $padding-side-mobile;

    @include tablet {
        padding: 30px $padding-side-tablet;
    }

    @include desktop {
        padding: 60px $padding-side-tablet;
    }

    &__wrapper {
        position: relative;
        width: 100%;
        max-width: $container-mid-max-width;
        margin: auto;
    }

    &__content {
        position: relative;
        overflow: hidden;
        width: 100%;
        background-color: white;
        border-radius: 20px 20px 0 20px;
    }

    &__close {
        @include reset-button();

        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 1;
        width: $button-width;
        height: $button-width;
        border: 1px solid $color-white-three;
        border-radius: 50%;
        color: $color-violet-blue;
        cursor: pointer;

        @include tablet {
            top: 20px;
            right: 20px;
        }

        &--white {
            color: $color-white-three;
        }
    }

    &__close-icon {
        @include tablet {
            width: 10px;
            height: 10px;
        }
    }

    &--light {
        #{$this}__close {
            color: white;
        }
    }

    &--tiny {
        #{$this}__wrapper {
            max-width: 450px;
        }
    }

    &.is-opened {
        display: flex;
    }
}
