.mini-basket {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    background-color: $color-violet-blue;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 22px;
    border-radius: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='20' viewBox='0 0 24 20'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3.538 7h18l-1.636 12H5.175L3.538 7zM1 7h22m-10.736 4v4m4.029-4l-.529 3.965M8.236 11l.528 3.965M8.946 1L7 6.838M15 1l1.946 5.838'/%3E%3C/svg%3E");

    @include desktop {
        width: 62px;
        height: 50px;
        border-radius: 0 0 0 20px;
    }

    &__link {
        display: block;
        width: 100%;
        height: 100%;
    }

    &::before {
        content: '';
        position: absolute;
        top: 12px;
        right: 7px;
        display: block;
        width: 7px;
        height: 7px;
        background-color: $color-butterscotch;
        border-radius: 50%;

        @include desktop {
            top: 16px;
            right: 16px;
            width: 9px;
            height: 9px;
        }
    }

    &.is-hidden {
        display: none;
    }
}
