
.option-picker-with-search{}

.option-picker-with-search .search-results {
    border: 1px solid #E0E0E0;
    border-radius: 20px 20px 0 20px;
    padding: 10px;
    padding-top: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.option-picker-with-search .selected-items {
    margin-top:10px;
}

.option-picker-with-search .button--secondary {
    padding-left: 7px !important;
    padding-right: 7px !important;
}

.option-picker-with-search .dropdown {
    margin-bottom: 10px;
}
