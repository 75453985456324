.form-field {
    $this: &;

    &__label {
        display: inline-block;
        margin-bottom: 10px;
        color: $color-warm-grey-two;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 1.5px;
        line-height: 1.8;
        text-transform: uppercase;

        @include tablet {
            font-size: 12px;
            line-height: 1.67;
        }

        &.required-field {
            &::after {
                content: "*";
                color: $color-rust-red;
            }
        }
    }

    &__input,
    &__email,
    &__date,
    &__number,
    &__dropdown,
    &__textarea {
        width: 100%;
        padding: 10px 15px;
        background-clip: padding-box;
        background-color: white;
        border: solid 1px $color-silver;
        border-radius: 10px;
        color: $color-greyish-brown;
        font-size: 14px;
        line-height: 1.5;
        -webkit-appearance: none;

        @include tablet {
            padding: 12px 20px;
            font-size: 16px;
        }

        &:focus {
            border-color: $color-violet-blue;
        }

        &::placeholder {
            color: $color-pinkish-grey;
        }

        &.is-invalid,
        &.input-validation-error {
            border: 1px solid $color-rust-red;
        }
    }

    &__date {
        height: 43px;
        appearance: inherit;

        @include tablet {
            height: 50px;
        }
    }

    &__dropdown {
        background-origin: content-box;
        background-position: right, center;
        background-repeat: no-repeat;
        background-size: 12px, 12px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' fill='%23bbbbbb'%3E%3Cpath d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'/%3E%3C/svg%3E");
        appearance: none;

        &:focus {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' fill='%234203BF'%3E%3Cpath d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'/%3E%3C/svg%3E");
        }

        &.input-validation-error {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' fill='%23A22602'%3E%3Cpath d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'/%3E%3C/svg%3E");
        }
    }

    &__textarea {
        height: 128px;
        resize: none;
    }

    &__checkbox-item {
        & + & {
            margin-top: 15px;
        }
    }

    .field-validation-error {
        margin-top: 15px;
        color: $color-rust-red;
        font-size: 12px;
        line-height: 1.5;
    }

    .explanation-text {
        margin-top: 8px;
        font-size: 10px;
        line-height: 1.6;

        @include tablet {
            font-size: 12px;
            line-height: 1.5;
        }
    }

    input[disabled] {
        color: $color-pinkish-grey;
    }

    & + & {
        margin-top: 20px;

        @include tablet {
            margin-top: 30px;
        }
    }

    &--checkbox, .ktc-checkbox-list {
        #{$this}__checkbox,
        .ktc-checkbox #{$this}__checkbox, .form-field__input {
            @include visually-hidden();

            &:checked ~ #{$this}__label,
            &:checked ~ label {
                &::after {
                    opacity: 1;
                }
            }

            &:focus ~ #{$this}__label,
            &:focus ~ label {
                &::before {
                    border: 2px solid #99CCFF;
                }
            }
        }

        #{$this}__label,
        .ktc-checkbox label {
            position: relative;
            margin-bottom: 0;
            padding-left: 35px;
            color: $color-greyish-brown;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: normal;
            text-transform: none;

            @include tablet {
                font-size: 14px;
            }

            &::before {
                content: '';
                position: absolute;
                top: 2px;
                left: 0;
                display: block;
                width: 20px;
                height: 20px;
                border: 1px solid $color-silver;
                border-radius: 5px;
            }

            &::after {
                content: '';
                position: absolute;
                top: 4px;
                left: 6px;
                opacity: 0;
                display: block;
                transform: rotate(45deg);
                width: 8px;
                height: 12px;
                border: 2px solid $color-violet-blue;
                border-top: none;
                border-left: none;
                transition: opacity $transition-default;
            }

            p {
                margin-bottom: 15px;
            }

            a {
                color: $color-violet-blue;
            }
        }
    }

    .ktc-checkbox-list {
        display: block;

        .ktc-checkbox {
            &:not(:first-child) {
                margin-left: 15px;
            }
        }

        label {
            display: block;
            line-height: 1.67;

        }
    }

    &--checkbox-group {
        #{$this}__checkbox-item.form-field + #{$this}__checkbox-item.form-field {
            margin-top: 16px;

            @include tablet {
                margin-top: 16px;
            }
        }
    }

    &--radio {
        .ktc-radio-list {
            display: flex;
            flex-direction: column;

            .ktc-radio + .ktc-radio {
                margin-top: 15px;
            }
        }

        .ktc-radio-list--bordered {
            border: 1px solid $color-white-three;
            border-radius: 10px;
        }

        #{$this}__radio {
            @include visually-hidden();

            &:checked ~ label {
                &::after {
                    opacity: 1;
                }
            }

            &:focus ~ label {
                &::before {
                    border: 2px solid #99CCFF;
                }
            }
        }

        #{$this}__radio ~ label {
            position: relative;
            display: inline-block;
            margin-bottom: 0;
            padding-left: 35px;
            color: $color-greyish-brown;
            font-size: 14px;
            line-height: 1.6;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                display: block;
                transform: translateY(-50%);
                width: 20px;
                height: 20px;
                border: 1px solid $color-silver;
                border-radius: 50%;
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 4px;
                opacity: 0;
                display: block;
                transform: translateY(-50%);
                width: 12px;
                height: 12px;
                background-color: $color-violet-blue;
                border-radius: 50%;
                transition: opacity $transition-default;
            }
        }

        #{$this}__radio[disabled] ~ label {
            opacity: 0.7
        }

        #{$this}__radio.input-validation-error ~ label {
            &::before {
                border-color: $color-rust-red;
            }
        }
    }

    &.is-hidden {
        display: none;
    }

    &.is-invalid {
        #{$this}__label::before,
        input {
            border: 1px solid $color-rust-red;
        }
    }
}
