.hero-banner-container {
    @include desktop {
        max-width: 1260px;
        margin: 0 auto;
    }
}

.hero-banner-holder {
    &--promo {
        padding-bottom: 40px;

        @include tablet {
            background: $color-pale-grey;
            padding-bottom: 94px;
        }

        @include desktop {
            padding-bottom: 43px;
        }
    }
}

.hero-banner {
    @include clearfix();
    position: relative;

    @include desktop {
        display: flex;
        align-items: center;
    }

    &--homepage {
        background: $color-white;

        @include tablet {
            height: 368px;
        }

        @include desktop {
            display: flex;
            align-items: initial;
            height: 468px;
        }

        .hero-banner__text-block {
            padding: 40px 15px 5px;

            @include tablet {
                padding: 30px 70px 30px 30px;
            }

            @include desktop {
                padding: $desktop-medium-padding;
            }

            @include desktop-wide {
                padding: $desktop-medium-padding 100px $desktop-medium-padding $desktop-medium-padding;
            }
        }

        .hero-banner__title {
            color: $color-violet-blue;
            font-size: 24px;

            @include tablet {
                font-size: 28px;
            }

            @include desktop {
                font-size: 44px;
            }

            @include desktop-wide {
                font-size: 46px;
            }
        }

        .hero-banner__image-block--mask {
            height: 250px;

            @include tablet {
                height: 100%;
            }

            img {
                @include tablet {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    @include border-radius(100px 0 0 100px);
                }

                @include desktop {
                    @include border-radius(150px 0 0 100px);
                }
            }
        }

        .hero-banner__curve {
            fill: $color-white;
            width: 101%;

            @include tablet {
                bottom: -1px;
                fill: $color-pale-grey;
            }

            &--white {
                fill: $color-white;
            }
        }
    }

    &--search-results {
        padding: 30px 15px;

        @include desktop {
            padding: 0;
        }

        .hero-banner__text-block--left {
            padding: 0 0 15px;

            @include desktop {
                padding: 30px 2em;
            }
        }

        .button {
            width: 100%;

            @include desktop {
                width: auto;
            }
        }
    }


    &--event {
        display: block;

        @include tablet {
            height: 400px;
        }

        @include desktop {
            height: 538px;
        }

        .hero-banner__curve {
            display: none;

            @include tablet {
                display: block;
            }
        }

        .hero-banner__image-block--mask {
            height: 250px;

            @include tablet {
                height: 100%;
            }

            img {
                @include tablet {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    @include border-radius(150px 0 0 200px);
                }
            }
        }

        .hero-banner__curve--mobile {
            display: block;
            fill: $color-barney;

            @include tablet {
                display: none;
            }
        }

        .button {
            display: inline-block;
            padding: 14px 72px;
        }
    }

    &--story {
        background: $color-pale-grey;
        @include grey-border();

        @include tablet {
            height: 370px;
            @include border-radius(20px 20px 0 20px);
        }

        @include desktop {
            height: 460px;
        }

        .hero-banner__subtitle {
            font-size: 14px;
            color: $color-greyish-brown;
            margin-bottom: 10px;
            line-height: 23px;
            font-weight: normal;

            @include tablet {
                font-size: 16px;
            }

            strong {
                font-weight: bold;
            }
        }

        .hero-banner__image-block--mask {
            height: 250px;

            @include tablet {
                height: 100%;
            }


            img {

                @include tablet {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    @include border-radius(100px 20px 0 100px);
                }

                @include desktop {
                    @include border-radius(150px 20px 0 100px);
                }
            }
        }

        .hero-banner__curve {
            fill: $color-pale-grey;
        }
    }

    &--audience {
        background: $color-dark-slate-blue;

        @include tablet {
            margin-top: $desktop-small-padding;
            @include border-radius(40px 40px 0 40px);
        }

        .hero-banner__title {
            font-weight: bold;
            letter-spacing: -0.5px;
            color: $color-white;
            font-size: 21px;

            @include tablet {
                margin-bottom: 15px;
                font-size: 38px;
            }

            @include desktop-wide {
                font-size: 46px;
            }
        }

        .hero-banner__subtitle {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: normal;
            color: $color-white;
            margin-bottom: 5px;

            @include tablet {
                font-size: 17px;
            }

            @include desktop-wide {
                font-size: 20px;
            }
        }

        p {
            color: $color-white;
            opacity: 0.8;
            line-height: 1.5;
        }
    }

    &--campaign {
        display: block;

        @include tablet {
            height: 470px;
        }

        @include desktop {
            height: 540px;
        }

        .hero-banner__image-block--mask {
            height: 250px;

            @include tablet {
                height: 100%;
            }

            img {

                @include tablet {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    @include border-radius(100px 0 0 150px);
                }

                @include desktop {
                    @include border-radius(170px 0 0 200px);
                }
            }
        }
    }

    &--vibrant-purple {
        background: $color-violet-blue;


        .hero-banner__title, p {
            color: $color-white;
        }

        a {
            color: $color-white;
            font-weight: 700;
        }
    }

    &--generic {
        .hero-banner__curve {
            @include tablet {
                fill: $color-white;
            }
        }

        .hero-banner__text-block {
            p {
                margin: 0 auto;

                @include desktop {
                    width: 70%;
                }
            }
        }
    }

    &--campaign {
        .hero-banner__curve {
            fill: $color-violet-blue;

            @include tablet {
                fill: $color-pale-grey;
            }
        }
    }

    &--rich-purple {
        background: $color-barney;

        .hero-banner__title, p {
            color: $color-white;
        }

        a {
            color: $color-white;
            font-weight: 700;
        }

        .hero-banner__curve--mobile {
            fill: $color-barney;
        }
    }

    &--detailed {
        @include tablet {
            display: flex;
            align-items: initial;
        }

        .hero-banner__curve {
            width: 101%;

            @include tablet {
                fill: $color-pale-grey;
            }

            &--white {
                fill: $color-white;
            }
        }
    }

    &--simple {
        &.hero-banner--washed-chalk {
            border-bottom: 1px solid $color-white-three;
            border-top: 1px solid $color-white-three;
        }

        .hero-banner__title {
            font-size: 24px;

            @include tablet {
                font-size: 46px;
                line-height: 1.17;
                letter-spacing: -0.5px;
            }
        }

        .hero-banner__text-block--left {
            padding: 15px;

            @include tablet {
                padding: 30px 60px;
            }
        }
    }

    &--simple-text {
        padding: 30px 15px;

        @include tablet {
            padding: 30px 20px;
        }

        @include desktop {
            padding: 30px 60px;
        }

        .hero-banner__text-block--left {
            padding: 0;
        }

        .hero-banner__description {
            font-size: 14px;
            line-height: 24px;
            float: left;
            margin-top: 15px;

            @include tablet {
                font-size: 16px;
            }

            @include desktop {
                margin-top: 0;
            }
        }
    }

    &__text-block {
        width: 100%;
        float: left;

        @include tablet {
            width: 50%;
        }

        &--right {
            float: right;
        }

        &--detailed {
            padding: $mobile-small-padding $mobile-small-padding 40px;

            @include tablet {
                padding: 30px 50px 60px 30px;
            }

            @include desktop {
                padding: 60px 80px 80px 60px;
            }

            @include desktop-wide {
                padding: 60px 120px 120px 60px;
            }

            .hero-banner__title {
                margin-bottom: 15px;

                @include tablet {
                    font-size: 36px;
                }

                @include desktop {
                    font-size: 46px;
                }
            }
        }

        &--event {
            padding: $mobile-small-padding $mobile-small-padding 40px;

            @include mobile-only {
                margin-top: -71px;
                position: relative;
                z-index: 2;
            }


            @include tablet {
                padding: $tablet-small-padding;
            }

            @include desktop {
                padding: $desktop-medium-padding;
            }

            .hero-banner__title {
                margin-bottom: 15px;

                @include desktop {
                    font-size: 46px;
                }
            }
        }

        &--no-date-event {
            @include mobile-only {
                margin-top: 0;
                padding-top: 25px;
            }
        }

        &--campaign {
            padding: $mobile-small-padding $mobile-small-padding 40px;

            @include tablet {
                padding: $tablet-small-padding;
            }

            @include desktop {
                padding: $desktop-medium-padding;
            }

            .hero-banner__title {
                margin-bottom: 15px;

                @include tablet {
                    font-size: 32px;
                }

                @include desktop {
                    font-size: 36px;
                }

                @include desktop-wide {
                    font-size: 46px;
                }
            }

            p {
                @include tablet {
                    width: 80%;
                }
            }

            .hero-banner__subtitle {
                color: $color-butterscotch;
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
                line-height: 1.4;
                letter-spacing: normal;
                text-transform: none;

                @include tablet {
                    margin-bottom: 20px;
                    font-size: 17px;
                }

                @include desktop-wide {
                    margin-bottom: 30px;
                    font-size: 20px;
                }
            }
        }

        p {
            margin-bottom: 1.5em;
            font-size: 14px;
            line-height: 24px;

            @include desktop {
                font-size: 16px;
            }
        }

        .button + .button {
            margin-left: 1em;
        }

        &--full {
            width: 80%;
            float: none;
            text-align: center;
            padding: 2em 1em;
            margin: 0 auto;

            @include tablet {
                padding: 4em 1em;
                width: 50%;
            }
        }

        &--left {
            width: 100%;
            padding: $desktop-small-padding 1em;

            @include tablet {
                padding: $desktop-small-padding 2em;
            }

            .hero-banner__title {
                margin: 0;
            }
        }

        &--audience {
            padding: $mobile-small-padding $mobile-small-padding 40px;

            @include tablet {
                padding: $mobile-large-padding $mobile-large-padding;
            }


            @include desktop-wide {
                padding: 88px 60px;
            }

            p {
                margin-bottom: 0;
            }
        }

        &--story {
            padding: $mobile-large-padding $mobile-small-padding 40px;

            @include tablet {
                padding: $mobile-large-padding $mobile-medium-padding;
            }


            @include desktop-wide {
                padding: $desktop-large-padding $desktop-medium-padding;
            }

            .hero-banner__title {
                @include hero-new-bold;
                margin-bottom: 12px;

                @include tablet {
                    font-size: 22px;
                    margin-bottom: 20px;
                }

                @include desktop {
                    font-size: 32px;
                }
            }

            p {
                margin-bottom: 0;
                color: $color-greyish-brown;
                line-height: 1.44;
                font-size: 16px;

                @include tablet {
                    font-size: 16px;
                }
            }
        }
    }

    &__button-holder {
        display: flex;
    }

    &__image-block {
        float: right;
        width: 100%;
        position: relative;

        @include tablet {
            order: 2;
            width: 50%;
        }

        &--left {
            @include tablet {
                position: absolute;
                left: 0;
                width: 50%;
            }
        }

        &--mask {
            position: relative;
            overflow: hidden;
            height: 100%;

            @include tablet {
                overflow: visible;
            }

            @include desktop {
                position: relative;
                text-align: right;
            }

            &:before {
                position: absolute;
                left: -44px;
                content: "";
                background: url('../../../general/img/spectrum-mask.png') no-repeat;
                height: 150%;
                background-size: 195px;
                top: -83px;
                width: 246px;
                z-index: 2;

                @include tablet {
                    background-size: contain;
                    width: 217px;
                    top: 0;
                    left: -33px;
                    height: 100%;
                }

                @include desktop {
                    background-size: cover;
                }
            }

            img {
                width: 100%;
                vertical-align: top;
                height: auto;
                max-width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;

                @include tablet {
                    width: auto;
                    @include border-radius(100px 0 0 100px);
                }

                @include desktop {
                    @include border-radius(150px 0 0 150px);
                }
            }
        }

        &--mask-detailed {
            position: relative;
            overflow: hidden;
            height: 250px;

            @include tablet {
                height: auto;
                overflow: visible;
            }

            @include desktop {
                position: relative;
                text-align: right;
            }

            &:before {
                position: absolute;
                left: -38px;
                content: "";
                background: url('../../../general/img/spectrum-mask-detailed.png') no-repeat;
                height: 100%;
                background-size: contain;
                top: 0;
                width: 180px;
                z-index: 2;

                @include tablet {
                    left: -40px;
                    background-size: contain;
                    width: 100%;
                }

                @include desktop {
                    left: -51px;
                }

                @include desktop-wide {
                    left: -57px;
                }
            }

            img {
                width: 100%;
                vertical-align: top;
                height: auto;
                max-width: 100%;

                @include tablet {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    @include border-radius(20px 0 0 20px);
                }
            }
        }
    }

    &__title {
        @include hero-new-bold;
        margin-bottom: $mobile-small-padding;
        color: $color-violet-blue;
        font-size: 24px;

        @include tablet {
            font-size: 28px;
            margin-bottom: $tablet-xsmall-padding;
        }

        @include desktop {
            font-size: 44px;
        }

        @include desktop-wide {
            font-size: 54px;
        }
    }

    &__actions {
        display: flex;
        margin-bottom: 15px;

        @include tablet {
            margin-bottom: 0;
        }
    }

    &__logo {
        display: inline-flex;
        padding: 0 0 0 $mobile-small-padding;

        @include desktop {
            padding: 0 0 0 $tablet-small-padding;
        }

        img {
            max-height: 40px;
        }
    }

    &__overlay {
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        pointer-events: none;

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__date {
        background: $color-white;
        @include border-radius(15px 15px 0 15px);
        text-align: center;
        height: 70px;
        margin-bottom: $mobile-small-padding;
        width: 70px;
        padding-top: 10px;
        color: $color-purple-pink;

        @include tablet {
            @include spectrum-radius();
        }
    }

    &__month {
        display: block;
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &__day {
        display: block;
        font-size: 20px;
        letter-spacing: 0.5px;
        font-weight: 700;
    }

    &__year {
        display: block;
        font-size: 10px;
        letter-spacing: 0.5px;
        font-weight: 700;
    }
    /*-----Promo Carousel------*/
    &__promo-block {
        background: $color-violet-blue;
        padding: 20px;
        width: 95%;
        @include spectrum-radius();
        z-index: 9;
        clear: both;
        margin: 0 auto;
        position: relative;
        margin-bottom: 40px;
        margin: 0 auto 40px;

        @include tablet {
            width: 387px;
            position: absolute;
            right: 9px;
            bottom: -93px;
            margin-bottom: 0;
        }

        @include desktop {
            padding: 30px;
            right: 40px;
            bottom: -40px;
        }


        .button {
            background: $color-bluish-purple;
            margin: auto auto 0 0;

            &:hover {
                background: $color-white;
                color: $color-bluish-purple;
            }
        }

        .owl-carousel {
            width: 100%;

            .owl-dots {
                position: absolute;
                right: 1em;
                bottom: -6px;
            }
        }

        .owl-buttons {
            width: 80px;
            display: block;
            position: absolute;
            right: 19px;
            bottom: 20px;
            z-index: 22;

            @include tablet {
                right: 19px;
            }

            @include desktop {
                width: 100px;
                bottom: 30px;
            }

            &__button {
                width: 33px;
                @include border-radius(150px);
                height: 33px;
                padding: 10px;
                background: $color-bluish-purple;
                @include transition(all,0.2s,ease);
                text-align: center;

                @include desktop {
                    padding: 12px;
                    width: 40px;
                    height: 40px;
                }

                .owl-buttons__owl-svg {
                    width: 10px;
                    height: 14px;

                    @include desktop {
                        width: 15px;
                        height: 17px;
                    }
                }

                &:hover {
                    cursor: pointer;
                    background: #ffffff;

                    .owl-buttons__owl-svg {
                        fill: $color-bluish-purple;
                    }
                }
            }
        }
    }

    &__promo-slide {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__promo-title {
        color: $color-white;
        margin-bottom: 9px;
        font-weight: 500;
        line-height: 1.27;
        letter-spacing: -0.5px;

        @include tablet {
            font-size: 22px;
        }
    }

    &__promo-text {
        color: $color-white;
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 1.5;

        @include tablet {
            font-size: 16px;
        }
    }

    &__curve {
        fill: $color-white;
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        z-index: 2;
        display: none;

        @include tablet {
            display: block;
        }

        &--mobile {
            display: block;

            @include tablet {
                display: none;
            }
        }

        &--full {
            display: block;
        }
    }


    &__border {
        fill: none;
        stroke: $color-white-three;
        stroke-miterlimit: 10;
        stroke-width: 0.35px;

        @include tablet {
            stroke-width: 0.25px;
        }
    }

    &__curve-left {
        position: absolute;
        height: 100%;
        fill: $color-white;
        left: -1px;
        top: 0;
        display: none;
        fill: $color-dark-slate-blue;

        @include tablet {
            display: block;
        }
    }

    &__arrow-down {
        position: absolute;
        left: 60px;
        z-index: 2;
        height: 15px;
        width: 25px;
        fill: $color-purple-pink;
        display: none;

        @include tablet {
            bottom: 4px;
            display: block;
            left: 30px;
        }

        @include desktop {
            left: 60px;
        }

        @include desktop-wide {
            bottom: 15px;
        }
    }
}
