.product-card,
.related-product {
    $this: &;

    overflow: hidden;
    display: block;
    min-height: 218px;
    border: 1px solid $color-white-two;
    border-radius: 20px 20px 0 20px;
    color: $color-greyish-brown;
    text-decoration: none;

    @include tablet {
        min-height: 320px;
    }

    @include desktop {
        min-height:341px;
    }

    a {
        text-decoration: none;
        color: #3F3F3F;
    }

    &__header {
        position: relative;
        z-index: 0;
        background-color: $color-pale-grey;

        @include tablet {
            height: 175px;
        }

        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 70%;

            @include tablet {
                display: none;
            }
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: -10;
            display: block;
            width: 100%;
            height: 30px;
            background-position: center bottom;
            background-repeat: no-repeat;
            background-size: 105% 100%;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 32' preserveAspectRatio='none'%3E%3Cpath fill='white' d='M0,0C29.61,12.78,82.63,30.18,151,30c67.31-.18,119.53-17.28,149-30V32H0Z' /%3E%3Cpath d='M188,55' /%3E%3Cpath d='M164,56' /%3E%3C/svg%3E%0A");
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -20;
        display: block;
        width: 100%;
        height: 100%;
    }

    &__label {
        position: absolute;
        top: 15px;
        right: 15px;
        display: block;
        margin-left: 15px;
        padding: 8px 12px;
        background-color: $color-barney;
        border-radius: 20px 20px 0 20px;
        color: white;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }

    &__info {
        position: relative;
        min-height: 110px;
        padding: 15px;
        background-color: white;
        @include border-radius(0 0 0 20px);
        @include tablet {
            min-height: 145px;
        }
    }

    &__title {
        color: $color-violet-blue;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.6;
        text-decoration: none;

        @include tablet {
            font-size: 18px;
            line-height: 1.35;
        }
    }

    &__price {
        margin-top: 5px;
        font-size: 14px;
        line-height: 1.5;

        @include tablet {
            margin-top: 14px;
            font-size: 16px;
        }
    }

    &__details {
        position: absolute;
        right: 20px;
        bottom: 20px;
        display: flex;
        align-items: center;

        @include tablet {
            right: 15px;
            bottom: 15px;
        }
    }

    &__detail-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 23px;
        height: 23px;
        padding: 0;
        background-color: white;
        border: 1px solid;
        border-radius: 50%;
        color: $color-lavender;
        cursor: pointer;

        @include tablet {
            width: 30px;
            height: 30px;
        }

        .icon {
            @include tablet {
                width: 16px;
                height: 12px;
            }
        }
    }

    &--featured {
        position: relative;
        min-height: 290px;

        #{$this}__header {
            @include tablet {
                height: 165px;
            }
        }

        #{$this}__info {
            padding: 20px 30px;
        }

        #{$this}__price {
            margin-top: 12px;
        }

        #{$this}__details {
            right: 30px;
            bottom: 20px;
        }
    }

    &--skeleton {
        @include shimmerAnimation();

        #{$this}__image,
        #{$this}__title,
        #{$this}__price,
        #{$this}__detail-link {
            position: relative;
            background: $color-white-three;
        }

        #{$this}__title {
            width: 80%;
            height: 15px;
        }

        #{$this}__price {
            width: 40%;
            height: 15px;
            margin-top: 22px;
        }

        #{$this}__detail-link {
            border: 0;
        }
    }
}
