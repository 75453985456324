.basket-card {
    $this: &;

    position: relative;
    display: flex;
    font-size: 12px;

    @include tablet {
        font-size: 14px;
    }

    &__remove {
        @include reset-button();

        position: absolute;
        top: 0;
        right: 0;
        width: 25px;
        height: 25px;
    }

    &__image {
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
        width: 56px;
        height: 56px;
        background-color: $color-white-three;
        border-radius: 20px 20px 0 20px;

        @include tablet {
            width: 80px;
            height: 80px;
        }
    }

    &__info {
        padding-left: 15px;

        @include tablet {
            padding-left: 25px;
        }
    }

    &__link {
        display: block;
        margin-top: 5px;
        color: $color-greyish-brown;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        text-decoration: none;

        @include tablet {
            font-size: 14px;
        }
    }

    &__title {
        margin-top: 5px;
        font-weight: 400;

        @include tablet {
            margin-top: 7px;
        }
    }

    &__price {
        display: inline-block;
        color: $color-greyish-brown;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.43;

        @include tablet {
            font-size: 16px;
            font-weight: 600;
        }
    }

    &__quantity {
        display: flex;
        align-items: center;
        margin-top: 5px;

    }

    &__quantity-title {
        font-weight: 500;
    }

    &__quantity-control {
        margin-left: 10px;
    }

    &__options {
        @include reset-list();

        color: $color-warm-grey-two;

        @include desktop {
            margin-top: 10px;
        }
    }

    &__option {
        margin-top: 5px;
        margin-right: 10px;

        @include tablet {
            margin-top: 6px;
        }

        &--quantity {
            color: $color-greyish-brown;

            @include tablet {
                margin-top: 12px;
                margin-bottom: 12px
            }

        }
    }

    &__option-title {
        font-weight: 500;
    }

    &__prop {
        font-weight: 500;
    }

    &--checkout {
        font-size: 12px;

        &__remove {
            display: none;
        }

        #{$this}__image {
            @include tablet {
                width: 70px;
                height: 70px;
            }
        }

        #{$this}__info {
            padding-left: 20px;
        }

        #{$this}__title {
            font-size: 12px;
        }

        #{$this}__remove {
            display: none;
        }
    }

}
