.ie11-notification {
    background: $deep-yellow;
    padding: 10px 15px;
    text-align: center;
    display: none;

    @include tablet {
        padding: 15px;
    }

    &__title {
        font-size: 14px;
        line-height: 24px;

        @include tablet {
            font-size: 16px;
        }
    }

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        display:block;
    }
 }
