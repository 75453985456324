.image-gallery {
    position: relative;

    &__image {
        position: relative;
    }

    &__image-block {
        position: relative;

        img {
            vertical-align: top;
            height: auto;
            @include spectrum-radius();
            max-width: 100%;
        }

        &--caption {
            img {
                @include border-radius(20px 20px 0 0);
            }
        }
    }

    &__caption {
        text-align: center;
        border: 1px solid $color-white-three;
        padding: 15px;
        color: $color-warm-grey;
        width: 100%;
        font-size: 12px;
        @include border-radius(0 0 0 20px);

        @include tablet {
            padding: 20px;
            font-size: 14px;
        }
    }

    .owl-buttons {
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 69px;
        z-index: 2;

        @include tablet {
            width: 76px;
            bottom: 30px;
            right: 30px;
        }

        &__button {
            width: 30px;
            @include border-radius(150px);
            height: 30px;
            padding: 0;
            background: $color-white;
            @include transition(all,0.2s,ease);
            text-align: center;
            line-height: 34px;

            &:hover {
                cursor: pointer;
                background: $primary-color;
            }

            &--owl-next {
                float: right;
            }
        }

        &__owl-svg {
            width: 13px;
            height: 13px;
        }
    }
}
