.testimonial-block {
    @include clearfix();
    position: relative;

    &__carousel {
        margin-top: 30px;

        @include desktop {
            margin-top: 0;
        }
    }

    &__title {
        margin-bottom: $mobile-small-padding;
        color: $color-violet-blue;

        @include tablet {
            font-size: 32px;
            margin-bottom: $tablet-small-padding;
        }
    }

    &__item {
        @include grey-border();
        @include spectrum-radius;
        background: $color-white;
        margin: 0 0 $desktop-xsmall-padding;
        @include clearfix();
        padding: 20px;
        display: flex;

        &:nth-child(odd) {
            .testimonial-block__bubble {
                background: $color-rosy-pink
            }
        }
    }

    &__description {
        margin-bottom: $mobile-xsmall-padding;
        font-size: 14px;
        line-height: 24px;
        color: $color-greyish-brown;
    }

    &__icon {
        float: left;
        padding-right: 20px;

        @include tablet {
            width: auto;
        }
    }

    &__bubble {
        background: $color-butterscotch;
        justify-content: center;
        align-items: center;
        height: 55px;
        width: 55px;
        display: flex;
        @include border-radius(150px 150px 0 150px);
        margin-bottom: 20px;
        float: left;
        position: relative;

        @include tablet {
            height: 66px;
            margin-bottom: 0;
            width: 66px;
        }

        img {
            width: 18px;
            height: 15px;

            @include tablet {
                width: 25px;
                height: 22px;
            }
        }
    }

    &__content {
        @include tablet {
            width: 80%;
            float: left;
        }

        @include desktop {
            width: 90%;
        }
    }

    footer {
        color: $color-warm-grey;

        cite {
            font-style: normal;
            font-size: 14px;
        }
    }

    .owl-buttons {
        position: absolute;
        right: 0;
        top: 0;
        width: 100px;
        display: block;

        &__button {
            width: 40px;
            @include border-radius(150px);
            height: 40px;
            padding: 12px;
            background: none;
            border: 1px solid $color-white-three;
            @include transition(all,0.2s,ease);



            svg {
                fill: $color-lavender;
                height: 14px;
                width: 14px;
            }

            &:hover {
                cursor: pointer;
                background: $color-violet-blue;
                border-color: $color-violet-blue;

                .owl-buttons__owl-svg {
                    fill: $color-white;
                }
            }
        }
    }
}
