.text-image-carousel {
    $this: &;

    &--theme-one {
        @include tablet {
            height: 421px;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        height: 100%;

        @include tablet {
            flex-direction: row;
        }
    }

    &__image {
        position: relative;
        width: 100%;
        height: 250px;

        @include tablet {
            height: auto;
            float: left;
            width: 50%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            height: 100%;
            object-fit: cover;

            @include tablet {
                @include border-radius(20px 0 0 20px);
            }
        }

        &--purple {
            .text-image-carousel__curve-right,
            .text-image-carousel__curve {
                fill: $color-barney;
            }
        }

        &--blue {
            .text-image-carousel__curve-right,
            .text-image-carousel__curve {
                fill: $color-violet-blue;
            }
        }

        &--white {
            .text-image-carousel__curve {
                border-left: 1px solid $color-white-three;
                border-right: 1px solid $color-white-three;
            }

            .text-image-carousel__curve-right {
                border-top: 1px solid $color-white-three;
                border-bottom: 1px solid $color-white-three;
            }
        }
    }

    &__curve {
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        fill: $color-white;

        @include tablet {
            display: none;
        }
    }

    &__curve-right {
        position: absolute;
        top: 0;
        right: -2px;
        display: none;
        -moz-transform: scale(-1, -1);
        -o-transform: scale(-1, -1);
        -webkit-transform: scale(-1, -1);
        transform: scale(-1, -1);
        height: 100%;
        fill: $color-white;

        @include tablet {
            display: block;
        }
    }

    &__text {
        width: 100%;
        padding: $mobile-medium-padding;
        border: 1px solid $color-white-three;
        background: $color-white;
        flex: 1;
        display: flex;
        flex-flow: column;

        @include tablet {
            @include border-radius(0 20px 0 0);
            float: right;
            width: 50%;
            flex: initial;
        }

        @include desktop {
            padding: $desktop-medium-padding;
        }

        .owl-buttons {
            display: inline-block;
            width: 100px;

            @include tablet {
                width: 90px;
                margin: 0;
            }


            &__button {
                @include border-radius(150px);
                @include transition(all,0.2s,ease);
                width: 40px;
                height: 40px;
                padding: 12px;
                background: none;
                border: 1px solid $color-lavender;

                &:hover {
                    background: $color-lavender;
                    cursor: pointer;

                    .owl-buttons__owl-svg {
                        fill: $color-white;
                    }
                }
            }

            + .button {
                margin-left: 30px;

                @include desktop-wide {
                    margin-left: 50px;
                }
            }
        }

        &--purple {
            background: $color-barney;
            border-color: transparent;

            .button {
                background: $color-white;
                color: $color-barney;

                &:hover {
                    background: darken($color-barney,10%);
                    color: $color-white;
                }
            }

            .owl-buttons {
                &__button {
                    background: $color-purple-pink-two;
                    border-color: transparent;

                    .owl-buttons__owl-svg {
                        fill: $color-white;
                    }

                    &:hover {
                        background: $color-white;
                        cursor: pointer;

                        .owl-buttons__owl-svg {
                            fill: $color-purple-pink-two;
                        }
                    }
                }
            }

            h2, p {
                color: $color-white;
            }
        }

        &--blue {
            background: $color-violet-blue;
            border-color: transparent;

            .button {
                background: $color-white;
                color: $color-violet-blue;

                &:hover {
                    color: $color-white;
                    background: $color-lavender;
                }
            }

            .text-image-carousel__curve-right {
                fill: $color-violet-blue;
            }

            .owl-buttons {
                &__button {
                    background: $color-bluish-purple;
                    border-color: transparent;

                    .owl-buttons__owl-svg {
                        fill: $color-white;
                    }

                    &:hover {
                        background: #FFFFFF;
                        cursor: pointer;

                        .owl-buttons__owl-svg {
                            fill: $color-bluish-purple;
                        }
                    }
                }
            }

            h2, p {
                color: $color-white;
            }
        }
    }

    &__title {
        margin-bottom: $desktop-xsmall-padding;

        @include desktop {
            font-size: 32px;
        }
    }

    &__description {
        margin-bottom: $mobile-medium-padding;
        font-size: 14px;
        line-height: 24px;

        @include desktop {
            margin-bottom: $desktop-medium-padding;
            font-size: 14px;
        }
    }

    &__actions {
        display: flex;
        margin-top: auto;


        .owl-buttons__owl-svg {
            height: 14px;
            width: 14px;
        }
    }

    .button {
        font-size: 14px;
        padding: 10px 26px;
    }

    &--theme-two {

        #{$this}__heading {
            @include hero-new(600);
            margin-bottom: 15px;
            color: $color-warm-grey;
            font-size: 12px;
            letter-spacing: 0.125em;
            line-height: 1.67;
            text-transform: uppercase;
        }

        #{$this}__title {
            color: $color-violet-blue;

            @include tablet {
                font-size: 26px;
                letter-spacing: -0.5px;
                line-height: 1.38;
            }
        }

        #{$this}__description {
            color: $color-greyish-brown;

            @include tablet {
                font-size: 14px;
                line-height: 1.71;
            }
        }

        #{$this}__text {
            background: $color-pale-grey;
        }

        #{$this}__curve, #{$this}__curve-right {
            z-index: 2;
            fill: $color-pale-grey;

            @include tablet {
                z-index: auto;
            }
        }

        #{$this}__curve-right {
            right: -2px;
        }

        #{$this}__curve {
            bottom: -2px;
        }

        #{$this}__image {
            position: relative;
            overflow: hidden;

            @include tablet {
                overflow: visible;
            }

            &::before {
                content: "";
                position: absolute;
                top: -83px;
                left: -50px;
                z-index: 2;
                width: 246px;
                height: 150%;
                background: url('../../../general/img/spectrum-mask.png') no-repeat;
                background-size: 100%;

                @include tablet {
                    top: -15%;
                    right: -20px;
                    left: auto;
                    width: 35%;
                    height: 130%;
                    background: url('../../../general/img/spectrum-mask-reflected.png') no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
    }
}
