.product-errors {
    @include reset-list();

    padding: 10px 0;

    &__li {
        color: $color-rust-red;

        & + & {
            margin-top: 5px;
        }
    }
}
