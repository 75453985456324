

.tiled-page-list {
    /*background: $color-pale-grey;*/
    /*float: left;*/
    width: 100%;

    &--black {
        background: $color-black;
    }

    &--75 {
        background: $color-greyish-brown;
    }

    &--50 {
        background: $color-warm-grey;
    }

    &--25 {
        background: $color-pinkish-grey;
    }

    &--10 {
        background: $color-white-three;
    }

    &--5 {
        background: $color-white-two
    }

    &--white {
        background: $color-white;
    }

    &--washed-chalk {
        background: $color-pale-grey;
    }

    &--blue-violet {
        background: $color-bluish-purple;
    }

    &--violet-purple {
        background: $color-purple-pink-two;
    }

    &__wrapper {
        /*padding-left: 15px;
        padding-right: 15px;*/
        /*@include tablet {
            padding-left: 30px;
            padding-right: 30px;
        }*/
    }

    &__container {
        max-width: 965px;
        margin: 0 auto;
    }

    &__row {
        box-sizing: border-box;
        /*padding: 40px 0;*/
        width: 100%;

        @include desktop {
            /*padding: 80px 0;*/
        }

        &--flex {
            box-sizing: border-box;
            display: -ms-flexbox;
            display: -webkit-box;
            display: flex;
            -ms-flex: 0 1 auto;
            -webkit-box-flex: 0;
            flex: 0 1 auto;
            -ms-flex-direction: row;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            padding: 40px 1rem;

            @include desktop {
                margin-left: -1rem;
                margin-right: -1rem;
                /*padding: 80px 0;*/
            }
        }
    }

    &__items {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
        box-sizing: border-box;
        flex: 0 0 auto;
        width: 100%;

        @include tablet {
            padding-right: 1rem;
            padding-left: 1rem;
            -ms-flex-preferred-size: 70%;
            flex-basis: 70%;
            max-width: 70%;
        }

        .related-story__column {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            margin-bottom: 15px;
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            max-width: 100%;
            box-sizing: border-box;
            flex: 0 0 auto;
            width: 100%;

            @include tablet {
                padding-right: 1rem;
                padding-left: 1rem;
                margin-bottom: 30px;
            }

            .related-story {
                height: 100%;
            }
        }

        &--full {
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            max-width: 100%;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            align-self: flex-start;

            .tiled-page-list__column {
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                max-width: 100%;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                flex: 0 0 auto;
                float: left;
                width: 100%;

                @include tablet {
                    padding-right: 1rem;
                    padding-left: 1rem;
                    -ms-flex-preferred-size: 33.333%;
                    flex-basis: 33.333%;
                    max-width: 33.333%;
                }
            }

            .related-story__column {

                @include desktop {
                    -ms-flex-preferred-size: 50%;
                    flex-basis: 50%;
                    max-width: 50%;
                }
            }

            .related-event,
            .related-page,
            .related-news,
            .results__column,
            .service-card {
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                max-width: 100%;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                flex: 0 0 auto;
                width: 100%;
                margin-bottom: 15px;
                height: 350px;

                @include tablet {
                    margin-bottom: 30px;
                    padding-right: 1rem;
                    padding-left: 1rem;
                    -ms-flex-preferred-size: 33.333%;
                    flex-basis: 33.333%;
                    max-width: 33.333%;
                    float: left;
                }
            }

            .results__column {
                height: auto;
            }

            .results__column a{
                text-decoration:none;
            }

            .service-card, .results__item {
                margin-bottom: 10px;

                @include tablet {
                    height: 350px;
                    margin-bottom: 15px;
                }

                .service-card__item {
                    height: 100%;
                    margin-bottom: 0;
                }
            }

            .tiled-page-list__item--news {
                @include tablet {
                    min-height: 200px;
                }

                .tiled-page-list__title {
                    width: 70%;

                    @include tablet {
                        max-height: 66px;
                        overflow: hidden;
                        width: 70%;
                    }
                }

                .tiled-page-list__description {
                    width: 100%;
                }
            }

            .tiled-page-list__item--service {
                .tiled-page-list__description {
                    width: 100%;
                }
            }

            .tiled-page-list__item--event {
                @include tablet {
                    min-height: 200px;
                }

                .tiled-page-list__title {
                    width: 70%;

                    @include tablet {
                        max-height: 66px;
                        overflow: hidden;
                        width: 53%;
                    }
                }

                .tiled-page-list__description {
                    width: 100%;
                }
            }

            .tiled-page-list__item--story {
                @include tablet {
                    min-height: 185px;
                }

                .tiled-page-list__title {
                    width: 70%;

                    @include tablet {
                        overflow: hidden;
                        width: 53%;
                    }
                }
            }

            .related-column {
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                max-width: 100%;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                flex: 0 0 auto;
                width: 100%;

                @include tablet {
                    padding-right: 1rem;
                    padding-left: 1rem;
                    -ms-flex-preferred-size: 25%;
                    flex-basis: 25%;
                    max-width: 25%;
                    float: left;
                }
            }

            .tiled-page-list__item--service {
                height: 100%;
            }
        }

        &--left {
            padding: 0;

            @include tablet {
                flex-basis: 70%;
                max-width: 70%;
            }

            .tiled-page-list__column {
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                max-width: 100%;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                flex: 0 0 auto;
                float: left;

                @include tablet {
                    padding-right: 1rem;
                    padding-left: 1rem;
                }
            }

            .related-story__column {
                min-height: initial;
                flex-basis: 100%;
                max-width: 100%;
            }

            .related-column, .related-event,
            .related-news,
            .results__column,
            .service-card {
                @include tablet {
                    -ms-flex-preferred-size: 50%;
                    flex-basis: 50%;
                    max-width: 50%;
                    float: left;
                }
            }

            .service-card {
                min-height: initial;
                flex-basis: 100%;
                max-width: 100%;
            }

            .tiled-page-list__item--event,
            .tiled-page-list__item--story,
            .tiled-page-list__item--news {
                min-height: initial;
            }
        }
    }

    &__column {
        margin-bottom: 15px;

        @include tablet {
            margin-bottom: 30px;
        }
    }

    &__item {
        padding: 30px;
        background: $color-white;
        @include grey-border();
        @include spectrum-radius();
        position: relative;

        a {
            text-decoration: none;
            display: block;
            height: 100%;
            width: 100%;
        }

        &--news {
            .tiled-page-list__title {
                width: 70%;
            }
        }

        &--story {
            overflow: hidden;

            .tiled-page-list__title {
                width: 70%;

                @include tablet {
                    width: 60%;
                    min-height: 44px;
                    max-height: 66px;
                    overflow: hidden;
                }
            }

            .tiled-page-list__description {
                white-space: nowrap;
                height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .related-story__icon {
                max-width: 60px;
                position: absolute;
                top: -5px;
                right: 30px;
            }
        }
    }


    &__content {
        position: relative;
    }


    &__title {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: normal;
        color: $color-violet-blue;
        line-height: 22px;
        margin-bottom: 10px;
    }

    &__location {
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 500;
        line-height: 1.8;
        letter-spacing: 1.5px;
        margin-bottom: 10px;
        color: $color-warm-grey-two;

        @include tablet {
            margin-bottom: 15px;
            font-size: 12px;
        }
    }

    &__description {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 22px;
        width: 70%;
        color: $color-greyish-brown;
    }

    &__tag {
        background: $color-teal;
        @include border-radius(150px 150px 0 150px);
        padding: 6px 10px 4px;
        font-weight: 500;
        font-size: 12px;
        color: $color-white;
        text-transform: uppercase;
        position: absolute;
        right: 0;
        letter-spacing: 1.5px;
        font-size: 8px;

        @include desktop {
            font-size: 12px;
        }
    }

    &__spectrum {
        position: absolute;
        height: 120%;
        width: 40px;
        right: -6px;
        overflow: hidden;
        top: 0;

        img {
            height: 100%;
        }
    }
}
