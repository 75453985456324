.blockquotes {
    &__inner {
        font-weight: 500;
        position: relative;
        margin: 0;
        padding: 0 0 0 20px;
        background: transparent;
        border: 0;
        color: $color-violet-blue;
        font-size: 20px;
        line-height: 26px;
        outline: 0;

        @include tablet {
            margin:0 auto;
            max-width: 550px;
            padding-left: 30px;
            font-size: 22px;
            line-height: 1.45;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            display: block;
            width: 4px;
            background: $color-violet-blue;
        }

        &::after {
            display: none;
        }
    }

    &__author {
        font-weight: 400;
        margin-top: 10px;
        color: $color-warm-grey;
        font-size: 12px;
        line-height: 1.57;

        @include tablet {
            font-size: 14px;
        }
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

/*Rich Text Editor Blockquotes*/

.rich-text-editor {
    blockquote {
        position: relative;
        margin: 15px 0;
        padding: 0 0 0 20px;
        background: transparent;
        border: 0;

        @include tablet {
            margin: 30px auto;
            max-width: 550px;
            padding-left: 30px;
            font-size: 22px;
            line-height: 1.45;
        }

        p {
            font-weight: 500;
            position: relative;
            margin: 0;
            border: 0;
            color: $color-violet-blue;
            font-size: 20px;
            line-height: 26px;
            outline: 0;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            display: block;
            width: 4px;
            background: $color-violet-blue;
        }

        &::after {
            display: none;
        }
    }
}
