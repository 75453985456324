
.wizard .validation-summary {
     border: 1px solid #A22602;
     padding:10px;
}

.wizard .steps hidden {
    display: none;
}

.wizard .wizard-step {
    display: none;
}

.wizard .wizard-step.active {
    display: inherit;
}

.wizard .footer {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.wizard .footer .button {
    margin-right: 20px;
}

.wizard .wizard-step-heading {
    margin-top: 20px;
    margin-bottom: 20px;
}


/*  accordion */
.wizard .accordion {
    margin-bottom: 20px;
}

.wizard .entity-row-controls {
    margin-top: 30px!important;
    margin-left:0px!important;
}

.wizard .button--secondary {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding: 7px;
    font-size: 0.7em;
}

.wizard .checkbox-label {
    display: block;
    padding: 2px 12px 15px 35px;
    transition: opacity .15s ease-in-out;
    position: relative;
}
.wizard .form-field__textarea {
    height: auto;
}

.wizard input:checked ~ .checkmark:after {
    display: block;
}

.wizard .accordion--deleted,
.wizard .accordion--deleted input,
.wizard .accordion--deleted select,
.wizard .accordion--deleted .accordion__title,
.wizard .accordion--deleted .form-field__label,
.wizard .accordion--deleted .form-field--radio .form-field__radio ~ label {
    background-color: #656565;
    color: white !important;
}

.wizard .accordion--deleted .tooltip-button {
    display: none;
}

.wizard .accordion--deleted .accordion__icon span {
    background: white !important;
}

/*  label & info icon container */
.wizard .label-container {
    display: flex;
    margin-bottom: 5px;
}

.wizard .label-container > div {
    border: solid 0px red;
    align-self: center;
}

.wizard .form-field__label {
    margin-bottom: 2px;
    padding-top:3px;
}

.wizard .tooltip-button {
    border: none;
    background-color: transparent;
    height: 20px;
    font-family: initial !important;
    font-size: initial !important;
    border: solid 0px blue;
    cursor: pointer;
}

.wizard .dropdown__submenu.active {
    max-height: 200vh !important;
}

.wizard .form-field__input,
.wizard .form-field__email,
.wizard .form-field__date,
.wizard .form-field__number,
.wizard .form-field__dropdown,
.wizard .form-field__textarea {
    font-size: 0.875rem;
}

.wizard .ql-container {
    font-size: 0.875rem;
}

.wizard .ktc-radio label:before,
.wizard .form-field--checkbox .form-field__label::before,
.wizard .form-field--checkbox .ktc-checkbox label::before,
.wizard .form-field .ktc-checkbox-list .form-field__label::before,
.wizard .form-field .ktc-checkbox-list .ktc-checkbox label::before {
    background-color: white;
}
