.notifications {
    position: fixed;
    right: 15px;
    bottom: 15px;
    z-index: $layer-notification;
    width: calc(100% - 30px);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;

    @include tablet {
        right: 30px;
        bottom: 30px;
        width: auto;
        max-width: 50%;
        font-size: 16px;
    }

    &__item {
        & + & {
            margin-top: 15px;

            @include tablet {
                margin-top: 30px;
            }
        }
    }
}
