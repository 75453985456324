.profile-dropdown {
    $this: &;

    position: relative;
    margin-right: 15px;
    padding: 5px 15px 5px 5px;
    background-color: $color-pale-grey;
    border-radius: 20px;

    @include desktop {
        margin-right: 0;
        padding: 0 15px 0 0;
    }

    &__accordion {
        padding: 0;
        border: none;
    }

    &__trigger {
        @include reset-button;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__account-initial {
        @include border-radius(20px 20px 0 20px);
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        background-color: $color-rosy-pink;
        color: white;
        font-size: 13px;
        font-weight: 600;
        text-align: center;
        pointer-events: none;

        @include mobile-only {
            @media screen and (max-height:680px) {
                margin-right: 0;
            }
        }
    }

    &__account-name {
        color: $color-greyish-brown;
        font-size: 12px;
        font-weight: 700;
        pointer-events: none;

        @include mobile-only {
            @media screen and (max-height:680px) {
                display: none;
            }
        }
    }

    &__chevron-icon {
        transform: rotate(-90deg);
        margin-left: 10px;
        color: $color-pinkish-grey;
        pointer-events: none;

        @include desktop {
            transform: rotate(0);
        }
    }

    &__panel {
        @include border-radius(20px 20px 20px 0);
        position: absolute;
        bottom: 100%;
        left: 0;
        z-index: $layer-selector;
        width: calc(100% + 30px);
        min-width: 300px;
        padding: 30px;
        background-color: $color-violet-blue;
        color: white;

        @include desktop {
            @include border-radius(20px 0 20px 20px);
            top: 100%;
            right: 0;
            bottom: auto;
            left: auto;
        }

        &:not([hidden]) {
            display: flex;
            flex-direction: column;
        }
    }

    &__list {
        @include reset-list;
    }

    &__item {
        padding: 10px 0;

        #{$this}__link {
            color: white;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 1.5;
        }

        & + & {
            border-top: 1px solid $color-bluish-purple;
        }
    }

    &__link {
        display: block;
        color: $color-greyish-brown;
        font-size: 12px;
        font-weight: 700;
        line-height: 1.67;
        text-decoration: none;

        &--sign-out {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                display: block;
                transform: translateY(-50%);
                width: 18px;
                height: 20px;
                background-repeat: no-repeat;
                background-size: 18px 20px;
                background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' fill='%23ffffff' xml:space='preserve'%3E%3Cpath d='M255.15,468.625H63.787c-11.737,0-21.262-9.526-21.262-21.262V64.638c0-11.737,9.526-21.262,21.262-21.262H255.15 c11.758,0,21.262-9.504,21.262-21.262S266.908,0.85,255.15,0.85H63.787C28.619,0.85,0,29.47,0,64.638v382.724 c0,35.168,28.619,63.787,63.787,63.787H255.15c11.758,0,21.262-9.504,21.262-21.262 C276.412,478.129,266.908,468.625,255.15,468.625z'/%3E%3Cpath d='M505.664,240.861L376.388,113.286c-8.335-8.25-21.815-8.143-30.065,0.213s-8.165,21.815,0.213,30.065l92.385,91.173 H191.362c-11.758,0-21.262,9.504-21.262,21.262c0,11.758,9.504,21.263,21.262,21.263h247.559l-92.385,91.173 c-8.377,8.25-8.441,21.709-0.213,30.065c4.167,4.21,9.653,6.336,15.139,6.336c5.401,0,10.801-2.041,14.926-6.124l129.276-127.575 c4.04-3.997,6.336-9.441,6.336-15.139C512,250.302,509.725,244.88,505.664,240.861z'/%3E%3C/svg%3E");
            }
        }
    }

    &__separator {
        margin: 0 5px;
        color: $color-greyish-brown;
        font-size: 12px;
        line-height: 1.67;
    }

    &--login {
        display: flex;
        align-items: center;
        margin-right: 15px;
        padding: 10px 20px;
        background-color: $color-pale-grey;
        border-radius: 20px;

        @include desktop {
            margin-right: 0;
        }
    }

    &.is-active {
        @include border-radius(0 0 20px 20px);
        background-color: $color-violet-blue;

        @include desktop {
            @include border-radius(20px 0 0 0);
            min-height: 50px;
            margin-left: -10px;
            padding-left: 10px;
        }

        #{$this}__account-name {
            color: white;
        }

        #{$this}__trigger {
            &[aria-expanded="true"] {
                @include desktop {
                    padding: 0; //override accordion styles
                }
            }
        }

        #{$this}__chevron-icon {
            transform: rotate(180deg);
            color: white;
        }
    }
}
