.news-article {
    padding-top: 15px;

    @include tablet {
        padding-top: 0;
    }

    &__header {
        font-weight: bold;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: -0.5px;
        color: $color-violet-blue;
        margin-bottom: 15px;

        @include tablet {
            font-size: 46px;
        }
    }

    &__posted-on {
        font-size: 14px;
        color: $color-warm-grey;
        margin-bottom: 30px;
    }

    &__image {
        img {
            vertical-align: top;
            height: auto;
            max-width: 100%;
            @include spectrum-radius();
        }
    }

    &__container {
        max-width: 964px;
        margin: 0 auto;

        > .row {
            @include tablet {
                margin-left: -1rem;
                margin-right: -1rem;
            }
        }
    }

    &__share {
        margin-top: 15px;

        @include tablet {
            margin-top: 30px;
        }

        p {
            margin-bottom: 15px;
            font-size: 14px;
            color: $color-greyish-brown;
        }

        &--mobile {
            display: block;

            @include tablet {
                display: none;
            }
        }
    }


    .generic-section {
        padding-left: 0;
        padding-right: 0;
    }
}
