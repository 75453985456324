.product-page {
    overflow: hidden;

    &__product-wrap {
        padding: 40px $padding-side-mobile 0;

        @include tablet {
            padding: 30px $padding-side-tablet 0;
        }
    }

    &__top {
        display: flex;
        flex-direction: column;

        @include tablet {
            flex-direction: column-reverse;
        }
    }

    &__heading {
        margin-bottom: 15px;
        color: $color-violet-blue;

        @include tablet {
            margin-bottom: 0;
            font-size: 46px;
        }
    }

    &__back {
        @include tablet {
            margin-bottom: 60px;
        }
    }

    &__product {
        margin-top: 20px;
        margin-bottom: 15px;

        @include tablet {
            margin-top: 30px;
            margin-bottom: 40px;
        }
    }

    &__related {
        padding: 15px 15px 15px;

        @include tablet {
            padding: 40px 30px 40px;
        }
    }

    &__related-title {
        margin-bottom: 20px;
        color: $color-violet-blue;
        font-size: 22px;
        font-weight: 500;

        @include tablet {
            margin-bottom: 30px;
            font-size: 32px;
            font-weight: 600;
        }
    }

    &__cards-list {
        overflow-y: auto;
        padding-bottom: 20px;

        &::-webkit-scrollbar {
            height: 3px;
        }

        &::-webkit-scrollbar-track {
            background-color: rgba($color-violet-blue, 0.15);
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-violet-blue;
        }
    }

    &__card {
        padding-right: 0.5rem;
        padding-left: 0.5rem;

        @include tablet {
            padding-right: 1rem;
            padding-left: 1rem;
        }
    }
}
