
.results__categories {
    color: $primary-color;
}

.search-button {
    background: #D31ABC;
    border-radius: 25px 25px 0;
    padding: 10px;
    color: #FFFFFF;
    height: 70px;
    font-weight: bold;
    border: none;
    text-align: center;

    @include desktop {
        width: 200px;
    }
}
.search-results {
    position: relative;

    .hero-banner-svg {
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: 1;

        &__svg {
            position: absolute;
            right: -300px;
            height: 800px;
            top: -113px;
            width: 80vw;
            z-index: 1;
        }
    }

    .content {
        z-index: 2;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;

        .form-container {
            display: flex;
            flex-direction: column;

            h1 {
                margin-top: 20px;
            }

            &__form {
                display: flex;
                flex-direction: column;
                gap: 15px;
                width: 100%;

                .search-filter-controls {
                    width: 100%;
                    padding: 0 15px 15px;

                    @include desktop {
                        padding: 0px 60px;
                    }

                    @include tablet {
                        padding: 30px 60px;
                    }

                    .filter-header {
                        color: $color-white;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-bottom: 20px;
                    }

                    .filter-toggle-button {
                        color: $color-white;
                        cursor: pointer;
                        margin-right: 20px;

                        &__icon {
                            fill: $color-white;
                            width: 15px;
                            height: 15px;
                            pointer-events: none;
                            position: relative;
                            top: 2px;
                            left: 6px;
                            transition: .1s;
                            transform: rotate(90deg);
                            border: solid 1px white;
                            border-radius: 50%;
                            padding: 1px;
                            margin-left: 3px;

                            @include desktop {
                                left: 2px;
                            }
                        }

                        &__icon.active {
                            transform: rotate(-90deg);
                        }
                    }

                    .search-filter-component-container_hidden {
                        display: none;
                    }

                    .search-filter-component-container {
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        gap: 15px;
                        height: 100%;

                        .search-button {
                            cursor: pointer;
                        }


                        @include desktop {
                            flex-direction: row;
                            flex-wrap: wrap;
                        }

                        .filter-wrapper {
                            display: flex;
                            flex: 1;
                            flex-direction: column;
                            gap: 15px;


                            @include desktop {
                                max-width: 200px;
                            }

                            .filter-name {
                                color: white;
                            }

                            .text-component {
                                position: relative;
                                width: 100%;
                                min-width: 200px;
                                display: flex;
                                flex-direction: row;

                                input[type=text] {
                                    height: 70px;
                                    border-radius: 25px 25px 0 25px;
                                    padding-left: 15px;
                                    width: 100%;
                                    border: none;
                                    z-index: 99;

                                    .button-zones {
                                        position: absolute !important;
                                        left: -16px;
                                    }
                                }
                            }

                            .dropdown-component {
                                position: relative;
                                width: 100%;
                                min-width: 200px;
                                display: flex;
                                flex-direction: row;

                                .selection-value {
                                    height: 70px;
                                    display: flex;
                                    background-color: white;
                                    border-radius: 25px 0 0 25px;
                                    align-items: center;
                                    padding-left: 15px;
                                    position: relative;
                                    transition: all 0.3s ease;
                                    border: solid 0px red;
                                    width: 100%;
                                    overflow:hidden;

                                    &:hover {
                                        cursor: pointer;
                                    }
                                }

                                &__options {
                                    position: absolute;
                                    top: 100%;
                                    left: 0;
                                    background-color: white;
                                    border-radius: 10px;
                                    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
                                    margin-top: 5px;
                                    z-index: 3000;
                                    width: 100%;

                                    .option-selected {
                                        background: #d31abc;
                                        color: $color-white;
                                    }

                                    &__option {
                                        padding: 10px 15px;
                                        cursor: pointer;
                                        transition: background-color 0.3s ease;

                                        &:hover {
                                            color: #0d0d0d;
                                            background-color: #f0f0f0;
                                        }
                                    }
                                }

                                .button-zones {
                                    height: 70px;
                                    right: 0;
                                    top: 0;
                                    width: 90px;
                                    position: relative;
                                    display: flex;
                                    flex-direction: row;
                                    z-index: 1;

                                    .button-zone-button {
                                        height: 100%;
                                        background-color: transparent;
                                        border: none;

                                        &:hover {
                                            cursor: pointer;
                                        }
                                    }

                                    .zone1 {
                                        background-color: white;
                                        height: 100%;
                                        width: 40%;
                                        border: solid 0px red;
                                    }

                                    .zone2 {
                                        background: #D31ABC;
                                        height: 100%;
                                        width: 60%;
                                        border-radius: 0 20px 0 0;
                                    }
                                }

                                .selected-option {
                                    color: #3c3f7c;
                                    margin-right: 10px;
                                }

                                .open-options-button {

                                    &__icon {
                                        fill: $color-white;
                                        width: 20px;
                                        height: 18px;
                                        pointer-events: none;
                                        position: relative;
                                        top: 2px;
                                        left: 6px;
                                        transition: .1s;
                                        transform: rotate(90deg);

                                        @include desktop {
                                            left: 2px;
                                        }
                                    }

                                    &__icon.active {
                                        transform: rotate(-90deg);
                                    }
                                }

                                .clear-selection-button {
                                    &__icon {
                                        margin-left: 0px;
                                        width: 12px;
                                        height: 12px;
                                        position: relative;
                                        top: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.search-results-tabs {

    padding: 30px 0 0 0;

    .search-results-tabs__results {
        padding-top: 20px;
        padding-bottom: 20px;

        .generic-section__container {
            padding-left: 20px;
            padding-right: 20px;
        }

        .map-container {
            padding-left: 20px;
            padding-right: 20px;
            background: $color-pale-grey;

            .map {
                margin-bottom: 20px;
            }
        }

        .sort-by {
            text-align: right;

            select {
                @include border-radius(10px);
                padding: 5px;
                border: none;
                background-color: transparent;
                font: inherit;
            }
        }
    }

    .is-active {
    }

    &__results {
        background: #f0edf6;
    }

    &__map {
        display: block;
    }

    &__nav-list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 13%;
        background: transparent;
        clear: both;
        width: 100%;
        border-radius: 0;

        @include tablet {
            @include border-radius(0 20px 0 0);
            height: 10%;
        }

        @include desktop {
            flex-direction: row;
            align-items: flex-end;
        }
    }

    &__nav-li {
        flex: 1 1;
        height: 100%;
        text-align: center;
        width: 100%;

        &:last-child {
            .autism-map__nav-link {
                @include tablet {
                    @include border-radius(0 20px 0 0);
                }
            }
        }
    }

    &__nav-link {
        color: $primary-color !important;
        background: transparent;
        text-align: center;
        font-size: 14px;
        text-decoration: none;
        display: block;
        @include transition(all,0.5s,ease);
        padding: 10px 0;
        height: 100%;
        line-height: 24px;
        background: $black-10;

        @include desktop {
            font-weight: 500;
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
        }

        @include tablet {
            line-height: 20px;
            padding: 16px 0;
            font-size: 16px;
        }

        &.is-active {
            border-radius: 0;
            background: #F0EDF6;
            color: $washed-chalk;

            @include desktop {
                font-weight: 500;
                border-top-right-radius: 20px;
                border-top-left-radius: 20px;
            }
        }
    }

    .results__item {
        margin: 20px 0;
    }

    .results__description {
        color: #000;
    }

    .results__button {
        svg {
            width: 30px;
            height: 30px;
        }
    }

    &__nav-list {
        display: flex;
        align-items: flex-end;
        height: 13%;
    }

    &__nav-li {
        flex: 1 1;
        height: 100%;
        text-align: center;
        margin-bottom: 0;

        .search-results-tabs__results {
            display: none;
        }
    }

    &__nav-link {
        text-align: center;
        width: auto;
        padding: 16px 0;
        font-size: 16px;
        line-height: 20px;
    }

    &__results, &__map, .autism-map__map {
        display: none;
    }

    &__results.is-active, &__map.is-active, .autism-map__map.is-active {
        display: block;
    }
}


.map-text {
    display: none;
}

.map-container {
    margin: auto;
}

/* Hides blank space at the top of the map marker info window */
.gm-style-iw-chr {
    height:0px!important;
}

.map-info-window {
    padding-top: 10px;
    max-width: 300px;
    font-family: $font-hero-new;

    p {
        color: $body-font-color;
        margin-bottom: 10px;
    }

    p.accent {
        color: $primary-color;
    }

    img {
        width: 20px;
        height: 20px;
    }

    .footer {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
    }

    h3 {
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: normal;
        color: $primary-color;
        line-height: 22px;
        margin-bottom: 10px;
    }
}

.search-header {
    position: relative;
    padding: 40px 15px;


    @include tablet {
        padding: 80px 10px 120px;
    }

    &__title {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: -0.5px;
        text-align: center;
        color: $color-violet-blue;
        margin-bottom: 15px;

        @include tablet {
            margin-bottom: 30px;
            font-size: 46px;
        }
    }

    &__search-box {
        position: relative;
        max-width: 633px;
        margin: 0 auto;
        width: 100%;
    }

    &__search {
        color: #bbbbbb;
        position: relative;
        z-index: 5;
        color: $color-greyish-brown;
        font-weight: 600;
        transition: z-index 0.8s, width 0.5s, background 0.3s ease, border 0.3s;
        height: 50px;
        margin: 0;
        padding: 5px 0 5px 40px;
        font-size: 1rem;
        cursor: pointer;
        right: 0;
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDUxMiA1MTIiIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiB3aWR0aD0iNTEycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik01MDMuODY2LDQ3Ny45NzRMMzYwLjk1OCwzMzUuMDUyYzI4LjcyNS0zNC41NDQsNDYuMDE3LTc4LjkxMiw0Ni4wMTctMTI3LjMzNiAgYzAtMTEwLjA4NC04OS4yMjctMTk5LjMxMi0xOTkuMzEyLTE5OS4zMTJDOTcuNTk5LDguNDAzLDguMzUxLDk3LjYzMSw4LjM1MSwyMDcuNzE1YzAsMTEwLjA2NCw4OS4yNDgsMTk5LjMxMiwxOTkuMzEyLDE5OS4zMTIgIGM0OC40MzUsMCw5Mi43OTItMTcuMjkyLDEyNy4zMzYtNDYuMDE3bDE0Mi45MDgsMTQyLjkyMkw1MDMuODY2LDQ3Ny45NzR6IE0yOS4zMzEsMjA3LjcxNWMwLTk4LjMzNCw3OS45ODctMTc4LjMzMiwxNzguMzMyLTE3OC4zMzIgIGM5OC4zMjUsMCwxNzguMzMyLDc5Ljk5OCwxNzguMzMyLDE3OC4zMzJzLTgwLjAwNywxNzguMzMyLTE3OC4zMzIsMTc4LjMzMkMxMDkuMzE4LDM4Ni4wNDcsMjkuMzMxLDMwNi4wNSwyOS4zMzEsMjA3LjcxNXoiIGZpbGw9IiMzNzQwNEQiLz48L3N2Zz4=) no-repeat left 9px center transparent;
        @include border-radius(10px);
        border: none;
        background-size: 19px;
        width: 100%;
        border: 1px solid $color-white-three;
        background-color: white;

        &:focus {
            z-index: 3;

            @include desktop {
                border: 1px solid #bbbbbb;
                background-color: white;
                outline: none;
                cursor: auto;
                padding-right: 10px;
            }

            &:after {
                content: "";
                height: 20px;
                width: 20px;
                line-height: 20px;
                position: absolute;
                right: 10px;
                top: 50%;
                border: 1px solid #D6C9F1;
            }
        }
    }

    &__submit {
        position: absolute;
        right: 1em;
        height: 30px;
        width: 30px;
        fill: #D0C0EF;
        z-index: 993;
        top: 10px;
        opacity: 0;

        &:hover {
            cursor: pointer;
        }
    }

    &__arrow {
        position: absolute;
        right: 1em;
        height: 30px;
        width: 30px;
        fill: $color-lavender;
        z-index: 99;
        top: 10px;

        &:hover {
            cursor: pointer;
        }
    }

    &__curve {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        fill: $color-pale-grey;
        display: none;

        @include tablet {
            display: block;
        }
    }
}


.results {
    background: $color-pale-grey;
    /*float: left;*/
    width: 100%;

    &--transparent {
        background: none;
    }

    &--black {
        background: $color-black;
    }

    &--75 {
        background: $color-greyish-brown;
    }

    &--50 {
        background: $color-warm-grey;
    }

    &--25 {
        background: $color-pinkish-grey;
    }

    &--10 {
        background: $color-white-three;
    }

    &--5 {
        background: $color-white-two
    }

    &--white {
        background: $color-white;
    }

    &--washed-chalk {
        background: $color-pale-grey;
    }

    &--blue-violet {
        background: $color-violet-blue;
    }

    &--violet-purple {
        background: $color-purple-pink-two;
    }

    &__wrapper {
        padding-left: 15px;
        padding-right: 15px;

        @include tablet {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    &__container {
        max-width: 965px;
        margin: 0 auto;
    }

    &__row {
        box-sizing: border-box;
        padding: 40px 0;
        width: 100%;

        @include desktop {
            padding: 80px 0;
        }

        &--flex {
            box-sizing: border-box;
            display: -ms-flexbox;
            display: -webkit-box;
            display: flex;
            -ms-flex: 0 1 auto;
            -webkit-box-flex: 0;
            flex: 0 1 auto;
            -ms-flex-direction: row;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            padding: 40px 1rem;

            @include desktop {
                margin-left: -1rem;
                margin-right: -1rem;
                padding: 80px 0;
            }
        }
    }

    .sidebar-filter {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
        box-sizing: border-box;
        flex: 0 0 auto;
        width: 100%;

        @include tablet {
            padding-right: 1rem;
            padding-left: 1rem;
            -ms-flex-preferred-size: 30%;
            flex-basis: 30%;
            max-width: 30%;
        }
    }

    &__count {
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: $color-warm-grey;
        display: block;
        font-size: 10px;
        margin-bottom: 10px;

        @include tablet {
            margin-bottom: 30px;
            font-size: 18px;
        }
    }

    &__download {
        @include grey-border();
        @include spectrum-radius();
        padding: 15px;
        background: $color-white;
        position: relative;

        @include tablet {
            padding: 15px;
        }

        &--large {
            padding: 15px;

            @include tablet {
                padding: 20px;
            }

            .results__download-title {
                font-size: 16px;
                margin-bottom: 10px;
            }

            .results__download-publish {
                font-size: 12px;
            }

            .results__download-svg {
                max-height: 40px;
                max-width: 40px;
            }
        }

        &--search {
            .results__download-content {
                width: 80%;

                @include tablet {
                    width: 40%;
                }
            }

            .results__tag {
                right: 20px;
            }
        }
    }

    &__download-content {
        display: flex;
        justify-content: space-between;
    }

    &__download-title {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: normal;
        color: $color-violet-blue;
        line-height: 22px;
        display: block;
        margin-bottom: 5px;
        margin-right: 5px;
    }

    &__download-publish {
        font-size: 11px;
    }

    &__download-svg {
        max-height: 20px;
        max-width: 20px;
        fill: $primary-color;
        @include transition(all,0.5s,ease);

        &:hover {
            fill: $secondary-color;
        }
    }

    &__items {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
        box-sizing: border-box;
        flex: 0 0 auto;
        width: 100%;

        @include tablet {
            padding-right: 1rem;
            padding-left: 1rem;
            -ms-flex-preferred-size: 70%;
            flex-basis: 70%;
            max-width: 70%;
        }

        .related-story__column {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            margin-bottom: 15px;
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            max-width: 100%;
            box-sizing: border-box;
            flex: 0 0 auto;
            width: 100%;

            @include tablet {
                padding-right: 1rem;
                padding-left: 1rem;
                margin-bottom: 30px;
            }

            .related-story {
                height: 100%;
            }
        }

        &--full {
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            max-width: 100%;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            align-self: flex-start;

            .results__column {
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                max-width: 100%;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                flex: 0 0 auto;
                float: left;
                width: 100%;

                @include tablet {
                    padding-right: 1rem;
                    padding-left: 1rem;
                    -ms-flex-preferred-size: 33.333%;
                    flex-basis: 33.333%;
                    max-width: 33.333%;
                }

                &--hyperlink-nolist {
                    width: 50%;

                    @include tablet {
                        -ms-flex-preferred-size: 33.333%;
                        flex-basis: 33.333%;
                        max-width: 33.333%;
                    }

                    @include desktop {
                        -ms-flex-preferred-size: 25%;
                        flex-basis: 25%;
                        max-width: 25%;
                    }
                }

                &--download,
                &--download-full {
                    @include tablet {
                        -ms-flex-preferred-size: 50%;
                        flex-basis: 50%;
                        max-width: 50%;
                    }
                }

                &--download-full {
                    .results__download-content {
                        @include tablet {
                            width: 80%;
                        }
                    }
                }
            }



            .related-story__column {

                @include desktop {
                    -ms-flex-preferred-size: 50%;
                    flex-basis: 50%;
                    max-width: 50%;
                }
            }

            .related-product-outer {
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                max-width: 100%;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                flex: 0 0 auto;
                width: 100%;
                margin-bottom: 15px;

                @include tablet {
                    margin-bottom: 30px;
                    padding-right: 1rem;
                    padding-left: 1rem;
                    -ms-flex-preferred-size: 33.333%;
                    flex-basis: 33.333%;
                    max-width: 33.333%;
                    float: left;
                }

                @include desktop {
                    flex-basis: 33.33333%;
                    max-width: 33.33333%;
                }

                @include desktop-wide {
                    flex-basis: 25%;
                    max-width: 25%;
                }
            }

            .related-event,
            .related-news,
            .service-card {
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                max-width: 100%;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                flex: 0 0 auto;
                width: 100%;
                margin-bottom: 15px;

                @include tablet {
                    margin-bottom: 30px;
                    padding-right: 1rem;
                    padding-left: 1rem;
                    -ms-flex-preferred-size: 33.333%;
                    flex-basis: 33.333%;
                    max-width: 33.333%;
                    float: left;
                }
            }

            .service-card {
                margin-bottom: 10px;

                @include tablet {
                    min-height: 300px;
                    margin-bottom: 15px;
                }

                .service-card__item {
                    height: 100%;
                    margin-bottom: 0;
                }
            }

            .results__item--news {
                @include tablet {
                    min-height: 200px;
                }

                .results__title {
                    width: 70%;
                    position: relative;

                    @include tablet {
                        max-height: 66px;
                        overflow: hidden;
                        width: 70%;
                    }
                }

                .results__description {
                    width: 100%;
                }
            }

            .results__item--service {
                .results__description {
                    width: 100%;
                }
            }

            .results__item--event {
                @include tablet {
                    min-height: 200px;
                }

                .results__title {
                    width: 70%;

                    @include tablet {
                        max-height: 66px;
                        overflow: hidden;
                        width: 53%;
                    }
                }

                .results__description {
                    width: 100%;
                }
            }

            .results__item--story {
                @include tablet {
                    min-height: 185px;
                }

                .results__title {
                    width: 70%;

                    @include tablet {
                        overflow: hidden;
                        width: 53%;
                    }
                }
            }

            .related-column {
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                max-width: 100%;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                flex: 0 0 auto;
                width: 100%;

                @include tablet {
                    padding-right: 1rem;
                    padding-left: 1rem;
                    -ms-flex-preferred-size: 25%;
                    flex-basis: 25%;
                    max-width: 25%;
                    float: left;
                }
            }

            .results__item--service {
                height: 100%;
            }
        }

        &--left {
            padding: 0;

            @include tablet {
                flex-basis: 70%;
                max-width: 70%;
            }

            .results__column {
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                max-width: 100%;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                flex: 0 0 auto;
                float: left;

                @include tablet {
                    padding-right: 1rem;
                    padding-left: 1rem;
                }

                &--hyperlink-nolist {
                    @include tablet {
                        width: 33.33333%;
                    }
                }

                &--download {
                    @include tablet {
                        width: 50%;
                    }
                }
            }

            .related-story__column {
                min-height: initial;
                flex-basis: 100%;
                max-width: 100%;
            }

            .related-product-outer {
                @include tablet {
                    -ms-flex-preferred-size: 50%;
                    flex-basis: 50%;
                    max-width: 50%;
                }

                @include desktop {
                    -ms-flex-preferred-size: 33.333%;
                    flex-basis: 33.333%;
                    max-width: 33.333%;
                }

                .related-product__info {
                    @include tablet {
                        min-height: 186px;
                    }
                }
            }

            .related-column,
            .related-event,
            .related-news,
            .service-card,
            .related-story__column {
                @include tablet {
                    -ms-flex-preferred-size: 50%;
                    flex-basis: 50%;
                    max-width: 50%;
                    float: left;
                }
            }



            .service-card {
                min-height: initial;
                flex-basis: 100%;
                max-width: 100%;
            }

            .related-story {
                &__image {
                    height: 180px;
                }
            }

            .results__item--event,
            .results__item--story,
            .results__item--news {
                min-height: initial;
            }
        }
    }

    &__column {
        margin-bottom: 15px;

        @include tablet {
            margin-bottom: 30px;
        }

        &--hyperlink {
        }

        &--hyperlink-nolist {
            list-style: none;

            .results__download {
                height: 100%;
            }
        }
    }

    &__item {
        padding: 30px;
        background: $color-white;
        @include grey-border();
        @include spectrum-radius();
        position: relative;

        a {
            text-decoration: none;
            display: block;
            height: 100%;
            width: 100%;

            &.map__link {
                display: inline;
                color: $color-violet-blue;
            }
        }

        &--news {
            .results__title {
                width: 70%;
            }
        }

        &--story {
            overflow: hidden;

            .results__title {
                width: 70%;

                @include tablet {
                    width: 60%;
                    min-height: 44px;
                    max-height: 66px;
                    overflow: hidden;
                }
            }

            .results__description {
                white-space: nowrap;
                height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .related-story__icon {
                max-width: 60px;
                position: absolute;
                top: -5px;
                right: 30px;
            }
        }

        &--service {
            .results__button {
                right: 0;
                top: 0;
                position: absolute;
            }
        }
    }


    &__content {
        position: relative;
    }


    &__title {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: normal;
        color: $color-violet-blue;
        line-height: 22px;
        margin-bottom: 10px;
    }

    &__location {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 500;
        line-height: 1.8;
        letter-spacing: 1.5px;
        margin-bottom: 10px;
        color: $color-violet-blue;

        svg {
            height: 30px;
            width: 40px;
            padding-right: 10px;
        }

        @include tablet {
            margin-bottom: 15px;
            font-size: 12px;
        }
    }

    &__description {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 22px;
        width: 70%;
        color: $color-greyish-brown;
    }

    &__tag {
        background: $color-teal;
        @include border-radius(150px 150px 0 150px);
        padding: 6px 10px 4px;
        font-weight: 500;
        font-size: 12px;
        color: $color-white;
        text-transform: uppercase;
        position: absolute;
        right: 0;
        letter-spacing: 1.5px;
        font-size: 8px;

        @include desktop {
            font-size: 12px;
        }
    }

    &__spectrum {
        position: absolute;
        height: 120%;
        width: 40px;
        right: -6px;
        overflow: hidden;
        top: 0;

        img {
            height: 100%;
        }
    }

    &__paging {
        display: flex;
        align-content: center;
        justify-content: center;
        clear: both;
        width: 100%;
        align-items: center;

        @include tablet {
            margin-top: 35px;
        }

        .disabled {
            display: flex;
            align-items: center;
            width: 20px;
        }

        a:not(:last-child), .current {
            height: 40px;
            width: 40px;
            background: $color-white;
            border: 1px solid $color-white-three;
            color: $color-warm-grey;
            text-align: center;
            @include border-radius(150px);
            line-height: 40px;
            text-decoration: none;
            margin-right: 10px;
            font-size: 14px;
        }

        a {
            &:last-child {
                display: flex;
                align-items: center;
                width: 20px;
                text-decoration: none;
                color: $color-violet-blue;
            }
        }

        .current {
            color: $color-violet-blue;
        }

        &--hide {
            display: none;
        }
    }

    &__toolbar {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 34px;
        justify-content: space-between;
        padding-right: 1rem;
        padding-left: 1rem;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;

        p {
            float: left;
            color: $color-dark-grey;
        }
    }

    .sort-by {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &__title {
            margin-right: 5px;
            font-weight: 400;
            font-size: 12px;
            color: $black-75;
        }

        &__select {
            background: none;
            border: 0;
            font-size: 12px;
            font-weight: 500;
            visibility: hidden;
        }

        .s-hidden {
            visibility: hidden;
            padding-right: 10px;
        }

        .select {
            cursor: pointer;
            display: inline-block;
            position: relative;
            color: black;
            font-size: 12px;
            line-height: 18px;
        }

        .option-select {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 0 10px;

            &:after {
                display: block;
                text-indent: -9999px;
                width: 12px;
                height: 8px;
                background: url('../../../general/img/down.svg');
                background-size: 12px 8px;
                position: absolute;
                content: '';
                right: 0;
                top: 6px;
            }
        }

        .options {
            display: none;
            position: absolute;
            top: 25px;
            right: 0;
            left: auto;
            z-index: 999;
            margin: 0 0;
            list-style: none;
            padding: 0;
            border: 1px solid #ccc;
            background-color: white;
            border-radius: 20px 0 20px 20px;
            background-color: white;
            border: 1px solid #E0E0E0;
            width: 170px;
        }

        .options li {
            margin: 0 0;
            font-size: 0.875rem;
            padding: 15px 20px;
            color: #3F3F3F;

            + li {
                padding-top: 10px;
            }
        }
    }
}
