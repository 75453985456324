.press-media {
    &__title {
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: -0.5px;
        color: $color-violet-blue;
        margin-bottom: 15px;

        @include tablet {
            font-size: 26px;
        }
    }

    &__description {
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: normal;
        color: $color-greyish-brown;
        margin-bottom:15px;
        @include tablet {
            margin-bottom: 30px;
            width: 60%;
            font-size: 16px;
        }
    }

    &__contact {
        padding: 15px 0;
        border-top: 1px solid $color-white-three;

        @includes desktop {
            padding: 15px 0 0 0;
        }
    }

    &__contact-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.38;
        letter-spacing: normal;
        color: $color-violet-blue;
        margin-bottom: 15px;

        @include tablet {
            font-size: 16px;
        }
    }

    &__contact-text {
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: normal;
        color: $color-greyish-brown;
        margin-bottom: 10px;

        a {
            color: $color-violet-blue;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
