.azure-sso {
    $this: &;

    padding: 30px $padding-side-mobile;

    @include tablet {
        padding: 60px $padding-side-tablet;
    }

    &__container {
        max-width: 800px;
    }

    &__back {
        margin-bottom: 30px;

        @include tablet {
            margin-bottom: 60px;
        }
    }

    &__title {
        color: $color-violet-blue;
    }

    &__text {
        margin-top: 30px;
    }

    &__paragraph {
        color: $color-greyish-brown;

        & + & {
            margin-top: 15px;
        }
    }

    &__link {
        color: $color-violet-blue;
    }

    &__signpost-title {
        margin-bottom: 15px;
        color: $color-greyish-brown;
        font-weight: 500;

        p {
            margin-bottom: 15px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 30px;
        margin-bottom: 30px;

        @include tablet {
            flex-direction: row-reverse;
            margin-bottom: 80px;
        }

        .social {
            margin-top: 30px;

            @include tablet {
                flex-basis: 41.667%;
                max-width: 41.667%;
                margin-top: 0;
                margin-left: 15px;
                padding: 0 30px;
            }

            .options {
                margin-top: 15px;
            }

            .intro h2 {
                color: $color-greyish-brown;
                font-size: 16px;
                font-weight: 500;
                line-height: 1.38;
            }
        }

        .entry {
            padding: 30px;
        }

        .entry-item {
            position: relative;
            display: flex;
            flex-direction: column;
        }

        .entry-item + .entry-item {
            margin-top: 30px;
        }

        .verifying_blurb,
        .error {
            color: $color-rust-red;
            font-size: 12px;
            line-height: 1.5;
        }

        .error.itemLevel {
            display: none;

            &[aria-hidden="false"] {
                display: block;
                order: 1;
                margin-top: 15px;
            }
        }

        .error[aria-hidden="false"] + input {
            border-color: $color-rust-red;
        }

        .verifying-modal {
            margin-top: 15px;
        }

        .divider {
            display: none;
        }

        .password-label {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }

        .create {
            padding: 20px 30px;
            background-color: $color-violet-blue;
            color: $color-white;
            font-size: 14px;
            line-height: 1.5;

            p {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
            }

            #createAccount {
                @include reset-button();
                @include border-radius(150px);
                @include transition(all,0.2s,ease);

                margin-top: 10px;
                margin-bottom: 10px;
                padding: 0.65em 1.5em;
                background: $color-bluish-purple;
                border: 2px solid transparent;
                color: #FFFFFF;
                font-size: 12px;
                font-weight: 700;
                line-height: 1.2;
                text-align: center;
                text-decoration: none;
                cursor: pointer;
                outline: none;

                @include tablet {
                    margin-top: 0;
                    margin-bottom: 0;
                }

                @include desktop {
                    padding: 0.65em 2.5em;
                    font-size: 12px;
                }

                &:hover {
                    background: darken($color-bluish-purple, 10%);
                    text-decoration: none;
                }
            }
        }

        .attrEntry.validate {
            display: flex;
            flex-direction: column;
        }

        .verificationInfoText[aria-hidden="false"],
        .verificationSuccessText[aria-hidden="false"],
        .verificationErrorText[aria-hidden="false"] {
            display: block !important;
            margin-bottom: 15px;
            font-size: 14px;
            line-height: 1.43;

            @include tablet {
                font-size: 16px;
                line-height: 1.5;
            }
        }

        .verificationInfoText,
        .verificationSuccessText {
            color: $color-greyish-brown;
        }

        form {
            @include spectrum-radius();

            overflow: hidden;
            border: 1px solid $color-white-three;

            .intro {
                display: none;
            }

            &[id="localAccountForm"] {
                @include tablet {
                    flex-basis: 58.333%;
                    max-width: 58.333%;
                    margin-right: 15px;
                }

                .error.pageLevel {
                    padding: 30px 30px 0;
                }

                .buttons {
                    margin-top: 80px;
                }
            }

            &[id="attributeVerification"] {
                width: 100%;
                padding: 30px;

                .error.pageLevel[aria-hidden="false"] {
                    margin-bottom: 15px;
                }

                .buttons {
                    margin-top: 30px;
                }
            }
        }

        a {
            color: $color-violet-blue;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.5;
            text-decoration: underline;


            &[id="forgotPassword"] {
                margin-bottom: 10px;
                font-size: 12px;
            }

            &[data-help] {
                display: none;
            }
        }

        ul {
            @include reset-list();
        }

        li + li {
            margin-top: 30px;
        }

        div[data-claim_id="email"] {
            order: 2;
            margin-top: 30px;
        }

        label[aria-hidden="false"],
        input[aria-hidden="false"] {
            display: block !important;
        }

        label {
            display: block;
            margin-bottom: 10px;
            color: $color-warm-grey;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1.5px;
            line-height: 1.67;
            text-align: left;
            text-transform: uppercase;

            &[for="password"] {
                margin-right: 15px;
            }
        }

        input {
            width: 100%;
            padding: 13px 20px;
            background-color: white;
            border: solid 1px $color-white-three;
            border-radius: 10px;
            color: $color-greyish-brown;
            font-size: 16px;

            &[id="email_ver_input"] {
                margin-bottom: 30px;
            }

            &:focus {
                border-color: $color-violet-blue;
            }
        }

        button {
            @include transition(background-color, 0.2s, ease);

            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 0.65em 1.5em;
            background-color: $color-purple-pink;
            border: 2px solid transparent;
            border-radius: 25px;
            color: white;
            font-size: 14px;
            font-weight: 700;
            line-height: 1.2;
            text-align: center;
            text-decoration: none;
            cursor: pointer;
            outline: none;

            @include tablet {
                width: auto;
                min-width: 200px;
                padding: 0.65em 2.5em;
                font-size: 16px;
            }

            &[disabled] {
                background-color: lighten($color-purple-pink, 10%);
                cursor: default;
            }

            &[id="cancel"] {
                display: none;
            }

            &:not([disabled]):hover {
                background-color: darken($color-purple-pink, 10%);
            }

            &#continue[disabled] {
                display: none;
            }

            &#email_ver_but_send,
            &#email_ver_but_resend,
            &#email_ver_but_edit {
                background: $primary-color;

                &:hover {
                    background: darken($primary-color, 15%);
                    text-decoration: none;
                }
            }
        }

        button.accountButton {
            position: relative;
            overflow: hidden;
            display: block;
            width: 100%;
            margin-bottom: 15px;
            padding: 13px 15px 13px 65px;
            border: none;
            border-radius: 10px;
            font-size: 14px;
            font-weight: 500;
            text-align: left;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 50px;
                height: 100%;
                background-color: rgba(black, 0.15);
                background-position: center center;
                background-repeat: no-repeat;
            }
        }

        button[id="GoogleExchange"] {
            background-color: #DB4A39;

            &::before {
                background-size: 15px 15px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3E%3Cpath fill='%23FFF' fill-rule='evenodd' d='M14.822 5.879H9.584L6.518 8.938h4.208c-.549 1.23-1.785 2.087-3.219 2.087-1.944 0-3.522-1.578-3.522-3.522 0-1.947 1.578-3.528 3.522-3.528.706 0 1.363.207 1.913.564l2.838-2.836C10.961.639 9.305 0 7.497 0 3.357 0 0 3.358 0 7.503 0 11.643 3.356 15 7.497 15 11.642 15 15 11.643 15 7.503c0-.559-.063-1.104-.178-1.624'/%3E%3C/svg%3E");
            }

            &:hover,
            &:focus {
                background-color: darken(#DB4A39, 10%);
            }
        }

        //TODO - rename IDs for social providers
        button[id="TwitterExchange"] {
            background-color: #00ACEE;

            &::before {
                background-size: 20px 20px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='%23FFF' fill-rule='evenodd' d='M20 4.179c-.736.321-1.526.539-2.356.636.848-.5 1.496-1.292 1.804-2.234-.795.463-1.673.799-2.607.981-.749-.786-1.814-1.276-2.995-1.276-2.266 0-4.103 1.81-4.103 4.04 0 .316.036.625.106.92C6.44 7.077 3.417 5.47 1.393 3.024 1.04 3.62.84 4.314.84 5.055c0 1.402.724 2.64 1.824 3.363-.672-.022-1.305-.204-1.858-.507v.05c0 1.957 1.415 3.59 3.29 3.962-.343.091-.706.142-1.08.142-.266 0-.522-.026-.773-.075.522 1.606 2.037 2.774 3.832 2.806C4.67 15.88 2.9 16.523.979 16.523c-.332 0-.658-.02-.979-.055 1.816 1.148 3.972 1.818 6.289 1.818 7.547 0 11.673-6.157 11.673-11.497l-.014-.523C18.754 5.7 19.45 4.99 20 4.18z'/%3E%3C/svg%3E");
            }

            &:hover,
            &:focus {
                background-color: darken(#00ACEE, 10%);
            }
        }

        button[id="LinkedInExchange"] {
            background-color: #0072B1;

            &::before {
                background-size: 20px 20px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='%23FFF' fill-rule='evenodd' d='M18.522 20c.768 0 1.4-.568 1.471-1.295l.007-.138V1.433c0-.745-.586-1.357-1.336-1.426L18.522 0H1.478C.71 0 .078.568.007 1.295L0 1.433v17.134c0 .745.586 1.357 1.336 1.426l.142.007h17.044zM4.552 6.478h-.02c-1.013 0-1.669-.694-1.669-1.56 0-.886.676-1.56 1.71-1.56 1.033 0 1.669.674 1.688 1.56 0 .866-.655 1.56-1.709 1.56zm6.203 10.264h-3.02v-.175c.005-1.164.034-7.757.002-8.79l-.003-.066h3.021v1.278l.039-.058c.388-.58 1.06-1.35 2.457-1.426l.226-.006c1.926 0 3.385 1.211 3.474 3.809l.004.256v5.178h-3.02v-4.831c0-1.214-.438-2.043-1.53-2.043-.835 0-1.332.559-1.55 1.098-.064.155-.09.358-.097.571l-.003.162v5.043zm-4.692 0H3.042V7.711h3.02v9.031z'/%3E%3C/svg%3E");
            }

            &:hover,
            &:focus {
                background-color: darken(#0072B1, 10%);
            }
        }


        button[aria-hidden="false"] + button[aria-hidden="false"] {
            margin-top: 15px;

            @include tablet {
                margin-top: 0;
                margin-left: 15px;
            }
        }

        & > .intro {
            display: none;
        }
    }

    .sign-post {
        margin-bottom: 0;
    }

    &--error-page {

        .error-page-help {
            margin-bottom: 15px;
            text-align: center;
        }

        .error-page-messagedetails {
            margin-top: 50px;
            color: $color-warm-grey;
            font-size: 13px;
            line-height: 1.5;
        }

        #{$this}__content {
            display: block;

            h2 {
                margin-bottom: 10px;
                color: $color-violet-blue;
                font-size: 22px;
                font-weight: 500;
                letter-spacing: -0.5px;
                line-height: 1.27;
                text-align: center;

                @include tablet {
                    margin-bottom: 15px;
                    font-size: 32px;
                    font-weight: 600;
                    line-height: 1.31;
                }
            }
        }
    }
}
