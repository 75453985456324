.breadcrumb {

    &__inner {
        margin-left: auto;
        margin-right: auto;
        position: relative;
        display: none;

        @include tablet {
            display: block;
            padding: 20px 12px;
        }

        @include desktop {
            padding: 30px;
        }

        @include desktop-wide {
            padding: 30px 60px;
        }
    }

    &--border {
        border-bottom: 1px solid $color-white-two;
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
    }

    &__item {
        position: relative;

        &:not(:last-child) {
            &:after {
                content: "";
                position: absolute;
                top: 37%;
                right: -5px;
                width: 9px;
                height: 9px;
                background: url('../../../general/img/chevron.svg') no-repeat;
                background-size: contain;
            }
        }

        &--home {
            img {
                height: 13px;
                width: 13px;
            }
        }

        &:last-child {
            .breadcrumb__link {
                color: $color-warm-grey;
            }
        }
    }

    &__link {
        padding: 10px 15px;
        text-transform: uppercase;
        font-size: 10px;
        display: block;
        letter-spacing: 1.5px;
        text-decoration: none;
        color: $color-greyish-brown;
        font-weight: 500;
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &--current {
            color: #848484;
        }
    }
}
