
.dw_pledge_single_page {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 500px));
    padding: 20px;
    align-items: start;
    justify-content: center;
    gap: 4rem;
}

.dw_pledge_single_page_top_section {
    text-align: center;
}

.dw_pledge_single_page_top_section h1 {
    margin-bottom: 30px;
    font-size: 2.875rem;
    color: #4203bf;
}
.dw_pledge_single_page h1 {
    margin: 8px 0;
}

span.dw_single_pledge_id {
    font-size: 2rem;
    font-weight: 700;
    background: linear-gradient(135deg, #f064d9, #c85bf3, #f2b926, #44b9e5, #7b9ed7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2rem;
    display: inline-flex;
}

.dw_pledge_single_page_section p {
    font-size: 1.1rem;
    line-height: 1.8rem;
}

.dw_pledge_single_page_section button {
    position: relative;
    color: white;
    border: none;
    border-radius: 24px;
    padding: 16px 24px;
    font-weight: 700;
    cursor: pointer;
    width: 100%;
    font-size: 1.2rem;
    transform: translateY(0%);
    margin: 1.5rem 0 0;
}

