.form-widget {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    border: solid 1px $color-white-three;
    border-radius: 20px 20px 0 20px;

    @include tablet {
        padding: 30px;
    }

    &__columns {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        @include tablet {
            flex-direction: row;
        }
    }

    &__column {
        @include tablet {
            width: 50%;
        }

        & + & {
            margin-top: 20px;

            @include tablet {
                margin-top: 0;
                margin-left: 30px;
            }
        }
    }

    &__submit {
        margin-top: 20px;

        @include tablet {
            margin-top: 30px;
        }
    }

    .ktc-default-section + .ktc-default-section {
        margin-top: 20px;

        @include tablet {
            margin-top: 30px;
        }
    }

    &__title {
        font-weight: bold;
        margin-bottom: 15px;

        @include tablet {
            font-size: 19px;
        }
    }

    &__description,
    p {
        font-size: 14px;
        line-height: 24px;
        color: $color-greyish-brown;
        margin-bottom: 15px;

    }
}

.formwidget-submit-text {
    padding-top: 40px;
    padding-bottom: 40px;
    color: $color-violet-blue;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: -0.5px;
    line-height: 1.3;
    text-align: center;


    @include tablet {
        padding-top: 80px;
        padding-bottom: 80px;
        font-size: 32px;
    }
}
