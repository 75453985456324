.col-sm-4,
.col.split {
    .content-block{
        @include tablet {
            height:100%;
        }
    }
}

.content-block {
    padding: 20px;
    @include spectrum-radius();
    position: relative;
    margin: 0 0 15px;
    display: flex;
    flex-flow: column nowrap;

    @include tablet {
        padding: 30px;
        margin: 0;
    }


    &--vibrant-purple {
        background: $primary-color;
    }

    &--rich-purple {
        background: $color-barney;
    }

    &--deep-blue {
        background: $color-dark-slate-blue;
    }


    &--image {
        p, ul {
            @include desktop {
                width: 80%;
            }
        }
    }

    p {
        color: $color-white;
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 1.5;

        @include tablet {
            font-size: 16px;
        }

        a {
            color: $color-white;
            text-decoration: underline;
        }
    }

    ul {
        padding: 0 0 0 18px;
        margin: 0 0 15px;

        li {
            line-height: 24px;
            color: $color-white;

            a {
                color: $color-white;
                text-decoration: underline;
            }
        }
    }

    &__title {
        color: $color-white;
        margin-bottom: 10px;

        @include tablet {
            font-size: 26px;
            margin-bottom: 15px;
        }
    }

    &__icon {
        display: none;

        @include desktop {
            display: block;
            position: absolute;
            bottom: 20px;
            right: 20px;
        }

        img {
            max-width: 70px;
        }
    }

    .button {
        width: 100%;

        @include tablet {
            margin-right: auto;
            margin-top: auto;
            width: auto;
        }
    }
}
