.related-stories {

    &__row {
        box-sizing: border-box;
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex: 0 1 auto;
        -webkit-box-flex: 0;
        flex: 0 1 auto;
        -ms-flex-direction: row;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -1rem;
        margin-left: -1rem;
    }

    &__column {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
        margin-bottom: 15px;

        &--three {
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            max-width: 100%;

            @include tablet {
                margin-bottom: 0;
                -ms-flex-preferred-size: 33.333%;
                flex-basis: 33.333%;
                max-width: 33.333%;
            }

            .related-story {
                @include tablet {
                    margin-bottom: 0;
                }

                &__image {
                    height: 180px;
                }

                .button {
                    width: 100%;
                    font-size: 13px;
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &--two {
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            max-width: 100%;

            @include tablet {
                margin-bottom: 0;
                -ms-flex-preferred-size: 50%;
                flex-basis: 50%;
                max-width: 50%;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &--full {
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            max-width: 100%;

            .related-story--blue {
                .related-story__content {
                    @include tablet {
                        @include border-radius(20px 0 0 20px);
                    }
                }

                .related-story__image {
                    background: $color-violet-blue;
                }
            }
        }
    }

    &__main-title {
        color: $color-violet-blue;
        letter-spacing: -0.5px;
        font-size: 24px;
        margin-bottom: 15px;
        font-weight: 600;
        line-height: 42px;

        @include tablet {
            margin-bottom: 30px;
            font-size: 32px;
        }
    }
}


/*Related Story Block*/

.related-story {
    @include grey-border();
    @include spectrum-radius();
    background: $color-white;
    @include clearfix();

    &--blue {

        .related-story__title,
        .related-story__subtitle {
            color: $color-white
        }

        .related-story__content {
            background: $color-violet-blue;
        }

        .button--clear {
            background: $color-bluish-purple;
            color: $color-white;
            border: none;

            &:hover {
                background: $color-white;
                color: $color-bluish-purple;
            }
        }

        .related-story__curve {
            fill: $color-violet-blue;
            bottom: -1px;

            &--border {
                display:none;
            }
        }
    }

    &--full {
        @include tablet {
            display: flex;
        }

        .related-story__image {
            height: 200px;

            @include tablet {
                float: right;
                overflow: visible;
                width: 50%;
                height:auto;
                @include border-radius(0 20px 0 0);
            }

            &--mask {
                &:before {
                    @include tablet {
                        height: 100%;
                        top: 0;
                    }
                }

                img {
                    @include tablet {
                        @include border-radius(80px 20px 0 85px);
                    }
                }
            }
        }

        .related-story__content {
            @include tablet {
                float: left;
                width: 50%;
            }
        }

        .related-story__title {
            @include tablet {
                font-size: 24px;
                line-height: 1.2;
            }
        }

        .related-story__curve {
            @include tablet {
                display: none;
            }
        }
    }

    &__main-title {
        color: $color-violet-blue;
        letter-spacing: -0.5px;
        font-size: 24px;
        margin-bottom: 15px;
        font-weight: 600;
        line-height: 42px;

        @include tablet {
            margin-bottom: 30px;
            font-size: 32px;
        }
    }

    &__content {
        padding: $mobile-medium-padding;
        @include border-radius(0 0 0 20px);

        @include tablet {
            padding: $tablet-small-padding;
        }
    }

    &__subtitle {
        font-size: 12px;
        color: $color-warm-grey-two;
        margin-bottom: 15px;
        font-weight: normal;
        line-height: 18px;

        @include tablet {
            line-height: 22px;
            font-size: 14px;
        }

        strong {
            font-weight: bold;
        }
    }

    &__title {
        color: $color-violet-blue;
        line-height: 24px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;

        @include desktop {
            font-size: 18px;
        }
    }

    &__image {
        overflow: hidden;
        height: 200px;
        @include border-radius(20px 20px 0 0);
        position: relative;

        @include tablet {
            height: 260px;
            order: 2;
        }

        &--mask {
            position: relative;

            &:before {
                position: absolute;
                left: -35px;
                content: "";
                background: url('../../../general/img/spectrum-mask.png') no-repeat;
                height: 200%;
                background-size: contain;
                top: -100px;
                width: 190px;
                z-index: 22;

                @include tablet {
                    left: -22px;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                display: block;
            }
        }
    }

    &__category {
        background: $color-teal;
        @include border-radius(150px 150px 0 150px);
        padding: 6px 10px 4px;
        font-weight: 500;
        font-size: 12px;
        color: $color-white;
        text-transform: uppercase;
        position: absolute;
        right: 13px;
        z-index: 22;
        top: 13px;
        letter-spacing: 1.5px;
        font-size: 10px;
    }

    .button {
        font-size: 14px;
        width: 100%;

        @include tablet {
            width: auto;
        }
    }

    &__curve {
        position: absolute;
        left: -2px;
        bottom: -1px;
        width: 101%;
        z-index: 222;
        fill: $color-white;

        &--border {
            fill: none;
            stroke: #E0E0E0;
            stroke-miterlimit: 10;
            stroke-width: 1px;
        }
    }
}


// Related Content Component

.related-content {
    .related-story__column {
        margin-bottom: 15px;

        @include tablet {
            margin-bottom: 30px;
        }
    }
}
