.search-widget {
    .generic-section.first {
        padding-left: 0;
        padding-right: 0;

        @include mobile-only {
            position: relative;
            z-index: 222;
        }

        @include tablet {
            padding-right: 30px;
            padding-left: 30px;
        }
    }

    &__inner {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px;

        @include tablet {
            padding: 0;
        }
    }

    .hero-banner {
        text-align: center;
        display: block;
        padding-top: 40px;

        @include tablet {
            padding-top: 80px;
        }

        &__text-block {
            padding: 0;
        }

        &__title {
            margin-bottom: 15px;

            @include tablet {
                font-size: 46px;
            }
        }

        p {
            font-size: 14px;
            color: $color-white;
            opacity: 0.8;
            line-height: 24px;
            width: 80%;
            margin: 0 auto;
            margin-bottom: 30px;

            @include tablet {
                margin-bottom: 60px;
                width: 70%;
                font-size: 16px;
            }

            @include desktop {
                width: 40%;
            }
        }

        &__curve {
            fill: $color-pale-grey;
        }
    }

    &__search-holder {
        background: $color-pale-grey;

        @include tablet {
            background: $color-violet-blue;
            @include border-radius(0 0 20px 20px);
            padding-bottom: 100px;
        }
    }

    .browse-all {
        padding-top: 40px;

        @include tablet {
            padding-top: 80px;
        }

        &__title {
            font-size: 26px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: -0.5px;
            color: $color-violet-blue;
            border-bottom: 1px solid $color-lavender;
            padding-bottom: 15px;
            margin-bottom: 30px;

            @include tablet {
                font-size: 26px;
            }
        }

        &__list {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
        }

        &__item {
            position: relative;
            max-width: 50%;
            flex-basis: 50%;
            display: flex;

            @include tablet {
                max-width: 33.333%;
                flex-basis: 33.3333%;
            }


            @include desktop {
                max-width: 25%;
                flex-basis: 25%;
            }

            &:hover {
                .browse-all__arrow {
                    opacity: 1;
                }
            }
        }

        &__arrow {
            position: absolute;
            top: 10px;
            right: 30px;
            max-height: 15px;
            max-width: 20px;
            fill: $color-lavender;
            opacity: 0;
            @include transition(all,0.25s,ease);
        }

        &__link {
            color: $color-violet-blue;
            font-weight: 500;
            line-height: 1.57;
            display: block;
            font-size: 14px;
            text-decoration: none;
            padding: 10px 10px 10px 0;
            width: 75%;
        }
    }
}


.search-widget {
    position: relative;
    clear: both;
    margin: 0 auto;

    @include tablet {
        width: 90%;
        display: flex;
    }

    &__icon {
        position: absolute;
        left: 25px;
        top: 18px;
        width: 16px;
        height: 16px;
        fill: $color-pinkish-grey;

        @include tablet {
            top: 21px;
            width: 20px;
            height: 20px;
        }
    }

    &__input {
        height: 50px;
        width: 100%;
        background: $color-white;
        border: 1px solid $color-white-three;
        border-right: 0;
        padding: 16px 30px 16px 50px;
        @include border-radius(20px);
        margin-bottom: 15px;
        font-size: 12px;

        @include tablet {
            padding: 30px 30px 30px 57px;
            font-size: 14px;
            margin-bottom: 0;
            @include border-radius(20px 0 0 20px);
            width: 63%;
        }

        @include desktop {
            width: 68%;
        }
    }

    &__submit {
        height: 35px;
        width: 100%;
        background: $color-purple-pink;
        color: $color-white;
        border: none;
        outline: none;
        @include border-radius(17.5px);

        @include tablet {
            height: 62px;
            @include border-radius(0 20px 0 0);
            font-size: 0;
            width: 10%;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__arrow {
        fill: $color-white;
        width: 50px;
        height: 25px;
        position: absolute;
        top: 19px;
        right: 19px;
        pointer-events: none;
        display: none;

        @include tablet {
            display: block;
        }
    }

    .dropdown {
        padding: 0;
        width: 100%;

        @include tablet {
            position: initial;
        }

        &.on {

            .dropdown-label {
                @include border-radius(20px 20px 0 0);

                @include tablet {
                    @include border-radius(0);
                }
            }
        }

        @include tablet {
            width: 27%;
        }

        @include desktop {
            width: 22%;
        }

        .dropdown-label {
            width: auto;
            height: 50px;
            font-weight: 500;
            border: 0;
            color: $color-greyish-brown;
            @include border-radius(10px);
            line-height: 41px;
            border-top: 1px solid $color-white-three;
            border-left: 1px solid $color-white-three;
            border-bottom: 1px solid $color-white-three;
            padding-left: 62px;
            font-size: 14px;

            @include tablet {
                line-height: 49px;
                @include border-radius(0);
                height: 62px;
            }

            &:before {
                display: none;
            }
        }

        &__icon {
            fill: #bbbbbb;
            max-width: 23px;
            height: 30px;
            position: absolute;
            left: 22px;
            top: 11px;

            @include tablet {
                left: 30px;
                top: 16px;
            }
        }

        &__apply {
            margin-top: 0;
        }

        .dropdown-title {
            margin-bottom: 20px;
            color: $color-violet-blue;
            font-size: 16px;
            font-weight: 500;
            display: block;
        }

        .dropdown-list {
            left: 0;
            top: 49px;
            text-align: left;
            width: 100%;
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
            border: 1px solid $color-white-three;
            overflow-y: hidden;
            /* Scrollbar Styling */
            ::-webkit-scrollbar {
                width: 10px;
            }


            @include tablet {
                top:62px;
            }

            @include mobile-only {
                max-height: 200vh;
                overflow: scroll;
            }

            ::-webkit-scrollbar-track {
                background-color: none;
                -webkit-border-radius: 10px;
                border-radius: 10px;
            }

            ::-webkit-scrollbar-thumb {
                @include border-radius(3px);
                opacity: 0.2;
                background: $color-white-two;
                padding: 10px;
            }
        }

        .dropdown-toolbar {
            border-top: 1px solid $color-white-three;
            padding-top: 15px;
            float: left;
            width: 100%;
        }

        .dropdown-inner {
            float: left;
            width: 100%;
            overflow-y: scroll;
            height: 100%;
            overflow-x: hidden;
            position: relative;

            @include tablet {
                max-height: 400px;
            }

            @include desktop {
                max-height: 500px;
            }

            &:after {
                content: "";
                position: absolute;
                z-index: 1;
                bottom: 0;
                left: 0;
                pointer-events: none;
                background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 90%);
                width: 100%;
                height: 10%;
            }
        }
    }
}
