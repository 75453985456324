.service {
    &__container {
        margin-left: auto;
        margin-right: auto;
        position: relative;
        max-width: 1200px;
    }

    &__banner {
        background: $color-violet-blue;
        padding: 30px 15px;

        @include tablet {
            padding: 30px;

            @include border-radius(20px 20px 0 0);
        }
    }

    &__logo {
        margin-bottom: 30px;

        img {
            max-width: 110px;
        }
    }

    &__banner-title {
        color: $color-white;
        font-size: 26px;
        font-weight: 500;
        line-height: 1.38;
        letter-spacing: -0.5px;
    }

    &__content {
        background: $color-pale-grey;
        padding: 30px 15px;

        @include tablet {
            @include border-radius(0 0 0 20px);
            padding: 60px 0 70px;
            margin-bottom: 80px;
        }

        .generic-section {
            padding-left: 0;
            padding-right: 0;
        }

        &--service {
            border: 1px solid $color-white-three;
            border-top: 0;
            background: $color-white;

            .provider__title {
                margin-bottom: 0;
                padding-bottom: 15px;
                border-bottom: 0;
            }
        }
    }

    &__row {
        max-width: 965px;
        margin: 0 auto;
    }

    &__title {
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: -0.5px;
        color: $color-violet-blue;
        border-bottom: 1px solid $color-white-three;
        font-size: 36px;
        padding-bottom: 15px;
        margin-bottom: 15px;

        @include tablet {
            font-size: 46px;
            padding-bottom: 30px;
            margin-bottom: 30px;
        }
    }

    &__right-col-section {
        border: 1px solid $color-white-three;
        @include spectrum-radius();
        width: 100%;
        margin-bottom: 15px;
        padding-top: 20px;

        @include tablet {
            margin-bottom: 30px;
            padding-top: 30px;
        }

        .button {
            width: 100%;
            margin-top: 10px;
            font-size: 14px;
        }

        /* for the right column sections, the map needs to be 100% of the width, so all other
            elements within the sections need side padding adding via this class */
        .left-right-padding {
            padding-left: 20px;
            padding-right: 20px;

            @include tablet {
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }

    &__right-col-section-title {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 15px;
        line-height: 1.27;
        letter-spacing: -0.5px;
        color: $color-violet-blue;
    }

    &__right-col-section-icon {
        width: 40px;

        img {
            width: 50px;
            height: 50px;
            vertical-align: top;
        }
    }

    &__right-col-section-description {
        color: $color-greyish-brown;
        font-size: 14px;
        line-height: 1.5;

        @include tablet {
            word-break: break-word;
            font-size: 16px;
        }
    }

    &__right-col-section-block {
        border-bottom: 1px solid $color-white-three;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;

        &:last-child {
            border-bottom: 0;
        }

        .provider__right-col-section-description {
            width: 100%;
        }

        &__right-col-section-lines {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .contact-line {
                display: flex;
                align-items: center;
                width: 100%;

                .service__right-col-section-description {
                    padding-left: 15px;
                }
            }
        }
    }

    &__right-col-section-logo {
        img {
            max-width: 100%;
            height: auto;
            vertical-align: top;
        }
    }





    &__disclaimer {
        border: 1px solid $color-white-three;
        @include spectrum-radius();
        padding: 20px;
        width: 100%;
        margin-bottom: 15px;

        @include tablet {
            margin-bottom: 30px;
            padding: 30px;
        }

        p {
            font-size: 12px;
            line-height: 22px;
        }

        &--search {
            @include mobile-only {
                margin-bottom: 15px;
            }

            padding: 20px;
        }
    }

    &__information {
        h2 {
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: -0.5px;
            color: $color-violet-blue;
            font-size: 22px;
            margin-bottom: 15px;

            @include tablet {
                font-size: 32px;
            }
        }

        p {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 15px;
            color: $color-greyish-brown;

            @include tablet {
                font-size: 16px;
            }
        }
    }

    &__paging {
        display: flex;
        align-content: center;
        justify-content: center;
        clear: both;
        margin: 30px 0;
        padding: 0;
        list-style: none;
        align-items: center;

        @include tablet {
            margin: 60px 0 0;
        }

        .disabled {
            display: flex;
            align-items: center;
            width: 20px;
        }

        a {
            height: 40px;
            width: 40px;
            display: block;
            background: $color-white;
            border: 1px solid $color-white-three;
            color: $color-warm-grey;
            text-align: center;
            @include border-radius(150px);
            line-height: 40px;
            text-decoration: none;
            margin-right: 10px;
            font-size: 14px;
            @include transition(all,0.2s,ease);

            &:hover {
                cursor: pointer;
                color: $color-violet-blue;
            }
        }

        .current {
            font-weight: 500;
            color: $color-violet-blue;
        }

        .next {
            position: relative;
            height: 40px;
            width: 40px;
            line-height: 40px;
            color: $color-violet-blue;
            text-align: center;
            font-size: 0;

            &:after {
                float: right;
                content: '';
                position: absolute;
                top: 50%;
                width: 9px;
                height: 9px;
                color: #C4C4C4;
                border: 1px solid currentcolor;
                transform: translateY(-90%) rotateZ(-45deg);
                transition: transform 0.3s;
                transform-origin: 100%;
                right: 17px;
                border-color: transparent currentcolor currentcolor transparent;
            }
        }

        .prev {
            position: relative;
            height: 40px;
            width: 40px;
            line-height: 40px;
            color: $color-violet-blue;
            text-align: center;
            font-size: 0;

            &:after {
                float: right;
                content: '';
                position: absolute;
                top: 50%;
                width: 9px;
                height: 9px;
                color: #C4C4C4;
                border: 1px solid currentcolor;
                transform: translateY(-13%) rotateZ(136deg);
                transition: transform 0.3s;
                transform-origin: 100%;
                right: 17px;
                border-color: transparent currentcolor currentcolor transparent;
            }
        }
    }
}
