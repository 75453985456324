.featured-products {
    padding: 20px $padding-side-mobile;

    @include tablet {
        padding: 30px $padding-side-tablet;
    }

    &__col {
        margin-top: 15px;

        @include tablet {
            margin-top: 0;
        }
    }
}
