.member-discount {
    $this: &;

    &--hidden {
        display: none;
    }

    &--has-new {
        #{$this}__orig {
            color: $color-warm-grey-two;
            font-weight: 400;
            text-decoration: line-through;
        }

        #{$this}__new {
            color: $color-rust-red;
        }

        #{$this}__orig + #{$this}__new {
            margin-left: 0.65em;
        }
    }
}
