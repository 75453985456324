.guidance-navigation {
    padding: $mobile-medium-padding $mobile-small-padding;
    background: $color-violet-blue;

    @include desktop {
        background: none;
        padding: 0;
    }

    &__header {
        background: $color-violet-blue;
        color: $color-white;
        font-weight: 500;
        letter-spacing: -0.5px;

        @include tablet {
            font-size: 26px;
        }

        @include desktop {
            @include border-radius(20px 20px 0 0);
            padding: $desktop-small-padding $desktop-medium-padding;
        }
    }

    &__mobile-header {
        font-size: 12px;
        display: block;
        clear: both;
        letter-spacing: normal;
        margin: 20px 0;

        @include desktop {
            display: none;
        }
    }

    &__description {
        background: $color-violet-blue;
        padding: 0 0 30px 0;

        @include desktop {
            padding: 0 $desktop-medium-padding $desktop-medium-padding;
        }

        p {
            color: $color-white;
            opacity: 1;
            line-height: 1.5;

            @include tablet {
                opacity: 0.8;
                width: 60%;
                font-size: 16px;
            }
        }
    }

    &__navigation {
        padding: 0 $mobile-small-padding;
        width: 100%;
        max-height: 48px;
        @include border-radius(10px 10px 0 10px);
        @include transition(all,0.5s,ease);
        border: 1px solid $color-white-three;
        background: $color-white;
        position: relative;
        overflow: hidden;

        @include desktop {
            overflow: visible;
        }

        &.active {
            max-height: 400px;

            @include desktop {
                max-height: none;
            }

            &:after {
                border-color: currentcolor currentcolor transparent transparent;
                top: 28px;

                @include desktop {
                    display: none;
                }
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 22px;
            right: 15px;
            width: 9px;
            height: 9px;
            color: #C4C4C4;
            border: 1px solid currentcolor;
            transform: translateY(-90%) rotateZ(-45deg);
            transition: transform 0.3s;
            transform-origin: 100%;
            border-color: transparent transparent currentcolor currentcolor;

            @include desktop {
                display: none;
            }
        }

        @include desktop {
            max-height: none;
            border: none;
            @include border-radius(0);
            background: #320094;
            padding: 0 0 0 40px;
        }
    }

    &__list {
        margin: 0;
        width: 100%;
        padding: 0;
    }

    &__item {
        list-style: none;
        display: block;

        @include desktop {
            display: inline-block;
        }

        &:first-child {
            .guidance-navigation__link {
                pointer-events: none;
                cursor: default;

                @include desktop {
                    pointer-events: visible;
                    cursor: pointer;
                }
            }
        }
    }

    &__link {
        font-size: 16px;
        padding: 15px 0;
        display: block;
        color: $color-greyish-brown;
        text-decoration: none;
        font-weight: bold;
        @include transition(all,0.2s,ease);
        border-bottom: 3px solid transparent;

        @include tablet {
            font-size: 14px;
        }

        @include desktop {
            color: $color-white;
            font-weight: normal;
            opacity: 0.8;
            padding: 21px;
        }

        @include desktop-wide {
            font-size: 16px;
        }

        &:hover {
            color: $color-violet-blue;

            @include desktop {
                color: $color-white;
                opacity: 1;
                border-bottom: 3px solid $color-purple-pink;
            }
        }

        &--current {
            @include desktop {
                opacity: 1;
                border-bottom: 3px solid $color-purple-pink;
            }
        }
    }

    &__three-dots {
        @include desktop {
            position: relative;
            margin-left: 20px;
            display: none;
        }

        &.has-items {
            @include desktop {
                display: inline-block;
            }
        }

        &.active {
            ul {
                @include desktop {
                    display: block;
                }
            }

            .guidance-navigation__dot {
                background: $color-butterscotch;
            }
        }

        ul {
            padding: 0;

            @include desktop {
                position: absolute;
                left: 0;
                display: none;
                top: 30px;
                width: 188px;
                background: $color-white;
                @include border-radius(0 15px 0 15px);
                z-index: 2;
                border: solid 1px $color-white-three;
            }


            .guidance-navigation__item {
                display: block;

                &:first-child {
                    .guidance-navigation__link {
                        &:hover {
                            @include border-radius(0 15px 0 0);
                        }
                    }
                }

                &:last-child {
                    .guidance-navigation__link {
                        &:hover {
                            @include border-radius(0 0 0 15px);
                        }
                    }
                }
            }

            .guidance-navigation__link {
                color: $color-greyish-brown;

                @include desktop {
                    font-size: 14px;
                }

                &:hover {
                    @include desktop {
                        background: $color-pale-grey;
                        @include border-radius(0);
                        border-bottom: 3px solid transparent;
                    }
                }
            }
        }

        &:hover {
            cursor: pointer;

            .guidance-navigation__dot {
                background: $color-butterscotch;
            }
        }
    }

    &__dot {
        height: 5px;
        width: 5px;
        margin-right: 5px;
        @include border-radius(150px);
        background: $color-white;
        @include transition(all,0.5s,ease);
        display: none;

        @include desktop {
            display: inline-block;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &--subtopic {
        .guidance-navigation__header {
            font-weight: bold;

            @include tablet {
                font-size: 36px;
            }

            @include desktop {
                font-size: 46px;
                padding: $desktop-medium-padding $desktop-medium-padding $desktop-small-padding;
            }
        }
    }

    &--subtopic-v2 {
        .guidance-navigation__header {
            padding: 0 0 15px;

            @include desktop {
                padding: 60px 60px 30px;
            }
        }

        .guidance-navigation__description {
            padding: 0;

            @include desktop {
                padding: 0 60px 60px;
            }
        }
    }

    &__side-navigation {
        padding: 0 $mobile-small-padding;
        width: 100%;
        max-height: 48px;
        @include border-radius(10px 10px 0 10px);
        @include transition(all,0.5s,ease);
        border: 1px solid $color-white-three;
        background: $color-white;
        position: relative;
        overflow: hidden;
        margin-top:15px;

        @include desktop {
            margin-top:0;
            overflow: visible;
        }

        &.active {
            max-height: 400px;

            @include desktop {
                max-height: none;
            }

            &:after {
                border-color: currentcolor currentcolor transparent transparent;
                top: 28px;

                @include desktop {
                    display: none;
                }
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 22px;
            right: 15px;
            width: 9px;
            height: 9px;
            color: #C4C4C4;
            border: 1px solid currentcolor;
            transform: translateY(-90%) rotateZ(-45deg);
            transition: transform 0.3s;
            transform-origin: 100%;
            border-color: transparent transparent currentcolor currentcolor;

            @include desktop {
                display: none;
            }
        }

        @include desktop {
            max-height: none;
            border: none;
            @include border-radius(0);
            padding: 0;
            background: none;
        }
    }

    &__side-navigation-link {
        color: $color-violet-blue;
        text-decoration: none;

        @include desktop {
            color: $color-white;
        }

        &--current {
            text-decoration: underline;
        }
    }

    &__side-navigation-item {
        color: $color-violet-blue;
        margin-bottom: 15px;

        @include desktop {
            color: $color-white;
        }
    }
}
