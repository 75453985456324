.cookie-settings {
    padding: 15px;

    @include tablet {
        padding: 30px;
    }

    &__fieldset {
        @include reset-fieldset;
    }

    &__legend {
        margin-bottom: 15px;
        color: $color-violet-blue;

        @include tablet {
            margin-bottom: 30px;
        }
    }

    &__pre-load {

        .dot-flashing {
            position: relative;
            width: 5px;
            height: 5px;
            border-radius: 5px;
            background-color: $color-violet-blue;
            color: $color-violet-blue;
            animation: dotFlashing 1s infinite linear alternate;
            animation-delay: .5s;
            align-self: flex-end;
            margin-left: 25px;
            margin-bottom:5px;
        }

        .dot-flashing::before, .dot-flashing::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
        }

        .dot-flashing::before {
            left: -15px;
            width: 5px;
            height: 5px;
            border-radius: 5px;
            background-color: #9880ff;
            color: #9880ff;
            animation: dotFlashing 1s infinite alternate;
            animation-delay: 0s;
        }

        .dot-flashing::after {
            left: 15px;
            width: 5px;
            height: 5px;
            border-radius: 5px;
            background-color: #9880ff;
            color: #9880ff;
            animation: dotFlashing 1s infinite alternate;
            animation-delay: 1s;
        }

        @keyframes dotFlashing {
            0% {
                background-color: #9880ff;
            }

            50%, 100% {
                background-color: #ebe6ff;
            }
        }
    }

    &__loading-title {
        color: $color-violet-blue;
        display: flex;
        font-size:18px;
        @include tablet {
            font-size:24px;
        }
    }

    &__form {
        display:none;
    }

    &__submit {
        margin-top: 15px;

        @include tablet {
            margin-top: 30px;
        }
    }
}
