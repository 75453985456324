.instagram-feed {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background-image: linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 1) 90%);
        height: 4em;
        width: -webkit-calc(100% - 17px);
        width: -moz-calc(100% - 17px);
        width: calc(100% - 17px);

    }


    &__title {
        font-size: 24px;
        color: $color-violet-blue;
        margin-bottom: 15px;
        font-weight: 600;

        @include tablet {
            margin-bottom: 15px;
            font-size: 32px;
        }
    }

    &__feed {
        position: relative;
        overflow-y: scroll;
    }
}
