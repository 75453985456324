.page-header {
    border-bottom: 1px solid $color-white-three;

    &__skip-link {
        position: absolute;
        left: -9999px;
        display: block;
        width: 0;
        height: 0;
        background: $color-white;
        font-size: 12px;
        text-align: center;

        &:focus {
            left: 0;
            width: auto;
            height: auto;
            margin: 5px;
        }
    }

    &--simple {
        .page-header__inner {
            @include desktop {
                padding-top: 20px;
            }
        }

        .inner-right {
            &__back {
                float: right;
                display: flex;
            }

            &__back-text {
                color: #000000;
                padding-left: 1em;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 2px;
                font-size: 0.8em;
                display: flex;
                align-items: center;

                @include desktop {
                    font-size: 0.9em;
                }
            }
        }
    }

    .utility-microsite {
        + .page-header__inner {
            align-items: center;
        }
    }

    &__back {
        display: flex;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.67;
        letter-spacing: 1.5px;
        color: $color-greyish-brown;
        text-transform: uppercase;
        text-decoration: none;
        align-items: center;

        img {
            margin-right: 10px;
            webkit-transform: scaleX(-1);
            transform: scaleX(-1);

            @include tablet {
                margin-right: 20px;
            }
        }
    }

    &__inner {
        -ms-flex-align: center;
        -ms-flex-pack: justify;
        -webkit-box-align: center;
        -webkit-box-pack: justify;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 1260px;
        align-items: center;
        padding: 15px;

        @media screen and (min-width:1125px) {
            min-height: 90px;
            align-items: unset;
            padding: 0 0 14px;
        }

        @media screen and (min-width:1325px) {
            max-width: none;
            padding: 0 50px 14px 28px;
        }

        &--simple {
            @include desktop {
                padding: 30px 0;
            }

            @media screen and (min-width:1325px) {
                padding: 30px 30px 30px 0;
            }
        }

        .logo {
            @media screen and (min-width:1125px) {
                display: flex;
                padding: 15px 0.5rem 0 30px;
                align-items: center;
            }

            img {
                width: 120px;
                image-rendering: -moz-crisp-edges; /* Firefox */
                image-rendering: -o-crisp-edges; /* Opera */
                image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
                image-rendering: crisp-edges;
                -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */

                @include desktop {
                    width: 132px;
                }

                @include desktop-wide {
                    width: 166px;
                }

                &.largerlogo {
                    width: 150px;

                    @include tablet {
                        width: 200px;
                    }

                    @include desktop-wide {
                        width: 220px;
                    }
                }
            }

            &--school {
                @include desktop {
                    padding: 0 0.5rem 0 30px;
                }

                img {
                    width: 155px;

                    @include tablet {
                        width: auto;
                    }
                }
            }

            &--branch {
                img {
                    width: 140px;

                    @include desktop {
                        width: 230px;
                    }
                }
            }
        }

        .inner-right {
            float: right;
            display: flex;
            align-items: center;

            @media screen and (min-width:1125px) {
                display: initial;
            }

            &__button {
                @media screen and (max-width:390px) {
                    padding: 10px 15px;
                }

                @media screen and (min-width:1125px) {
                    display: none;
                }
            }

            &__menu-button {
                background: none;
                outline: 0;
                border: 0;
                -webkit-appearance: none;
                width: 30px;
                margin-left: 15px;
                height: 21px;
                padding: 0;

                @media screen and (max-height:560px) {
                    margin-left: 15px;
                }

                &:hover {
                    cursor: pointer;
                }

                @media screen and (min-width:1125px) {
                    margin-left: 0;
                    display: none;
                }

                img {
                    vertical-align: top;
                    height: auto;
                    max-width: 100%;
                }
            }

            &__button {
                @media screen and (min-width:1125px) {
                    float: right;
                }
            }

            &__search-form {
                width: 90%;

                @media screen and (min-width:1125px) {
                    width: auto;
                }
            }

            &__search-box {
                align-self: flex-end;
                align-items: center;
                display: flex;
                position: absolute;
                width: 100%;
                transition: transform .3s;
                top: 1em;
                padding: 0 2.5rem 0 1rem;

                @media screen and (min-width:1125px) {
                    position: relative;
                    top: auto;
                    float: left;
                    padding: 0;
                    margin-right: 1em;
                    width: auto;
                    margin-left: 10px;
                }

                @include desktop-wide {
                    margin-right: 1.5em;
                    margin-left: 20px;
                }
            }

            .search-bar {
                width: 100%;

                &__search {
                    color: #7c7c7c;
                    position: relative;
                    transition: z-index 0.8s, width 0.5s, background 0.3s ease, border 0.3s;
                    height: 46px;
                    margin: 0;
                    padding: 5px 0 5px 40px;
                    font-size: 1rem;
                    cursor: pointer;
                    right: 0;
                    @include border-radius(10px);
                    border: none;
                    width: 100%;
                    border: 1px solid #e0e0e0;
                    background-color: white;
                    background: url('../../../general/img/search-header.svg') no-repeat;
                    background-size: 15px;
                    background-position: 13px;
                    font-size: 14px;
                    font-weight: normal;

                    @include mobile-only {
                        @media screen and (max-height:680px) {
                            height: 37px;
                        }
                    }

                    @media screen and (min-width:1125px) {
                        position: absolute;
                        width: 0;
                        border: 0;
                        background-color: none;
                    }

                    &:focus, &.focus {
                        z-index: 3;

                        @media screen and (min-width:1125px) {
                            width: 620px;
                            border: 1px solid #e0e0e0;
                            background-color: white;
                            outline: none;
                            cursor: auto;
                            padding-right: 10px;
                        }

                        + .search-bar__search-icon {
                            @media screen and (min-width:1125px) {
                                display: block;
                                z-index: 22;
                                pointer-events: none;
                            }
                        }
                    }

                    ::-webkit-search-cancel-button {
                        cursor: pointer;
                    }
                }


                &__search-icon {
                    position: absolute;
                    top: 8px;
                    right: 0;
                    display: none;

                    &:after {
                        content: "";
                        line-height: 30px;
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        background: $color-white url('../../../general/img/search-right.svg') no-repeat;
                        height: 30px;
                        width: 30px;
                    }
                }

                &__icon {
                    display: none;
                    fill: $color-pinkish-grey;
                    height: 15px;
                    width: 15px;
                    position: absolute;
                    z-index: 22;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    top: 17px;
                    pointer-events: none;


                    @include tablet {
                        display: block;
                    }
                }

                &__submit {
                    position: relative;
                    display: none;
                    width: 45px;
                    height: 45px;
                    margin: 0;
                    padding: 0;
                    border: 0;
                    border-radius: 30px;
                    cursor: pointer;
                    background: none;
                    font-size: 0;

                    @media screen and (min-width:1125px) {
                        display: inline-block;
                    }
                }
            }

            &__navigation-block {
                @media screen and (min-width:1125px) {
                    align-items: center;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    float: right;
                    width: auto;
                    clear: both;
                    padding-right: 30px;
                }

                @include clearfix();
                width: 100%;

                .main-nav {
                    float: left;
                    display: none;

                    @media screen and (min-width:1125px) {
                        display: block;
                        align-self: flex-end;
                    }

                    clear: both;
                    margin-right: 1em;

                    &__list {
                        position: relative;
                    }

                    &__item {
                        display: inline-block;
                        position: relative;
                    }

                    &__link {
                        display: block;
                        color: #3F3F3F;
                        font-weight: bold;
                        padding: 10px 20px;
                        font-size: 1.2em;
                        @include transition(all,0.2s,ease);
                        position: relative;

                        &--has-menu {
                            &:after {
                                position: absolute;
                                content: "";
                                height: 15px;
                                width: 15px;
                                background-size: cover;
                                right: -9px;
                                top: 15px;
                            }

                            &:hover {
                                &:after {
                                    background-size: cover;
                                }

                                + .main-nav__sub-menu {
                                    display: flex;
                                    @include translate(0,0);
                                }
                            }
                        }

                        &:hover {
                            text-decoration: none;
                            color: #E425CC;
                        }
                    }

                    &__sub-menu {
                        position: absolute;
                        left: 0;
                        top: 63px;
                        width: 141%;
                        z-index: 999;
                        background: #ffffff;
                        @include border-radius(0 0 0 10px);
                        display: flex;
                        flex-wrap: wrap;
                        border-bottom: 1px solid #eee;
                        border-right: 1px solid #eee;
                        z-index: 99;
                        border-left: 1px solid #eee;
                        display: none;
                        @include translate(0,-2em);
                        @include transition(transform,2.5s,ease);

                        @media screen and (min-width:1125px) {
                            width: 800px;
                        }
                    }

                    &__sub-menu-list {
                        &--small {
                            width: 50%;
                            float: left;
                            margin-bottom: 2em;

                            .main-nav__sub-menu-link {
                                font-size: 0.9em;
                                color: #424242;
                            }

                            .main-nav__sub-menu-title {
                                margin-bottom: 0.2em;
                                color: #8e8e8e;
                                font-size: 0.7em;
                                text-transform: uppercase;
                            }
                        }
                    }

                    &__sub-menu-link {
                        color: $color-warm-grey-two;
                        display: block;
                        padding: 0.4em 0;
                        font-weight: 500;
                        font-size: 1.05em;

                        &:hover {
                            text-decoration: none;
                            color: darken(#7C7C7C, 20%);
                        }
                    }

                    &__sub-menu-title {
                        color: #3F3F3F;
                        font-size: 1.2em;
                        margin-bottom: 1em;
                    }

                    &__sub-menu-left {
                        padding: 2em;
                        flex-grow: 1.5;
                        flex-shrink: 1.5;
                        flex-basis: 0;
                    }

                    &__sub-menu-right {
                        padding: 2em;
                        flex-grow: 2.5;
                        flex-shrink: 2.5;
                        flex-basis: 0;
                        background: #F0EDF6;
                    }
                }

                .button {
                    @media screen and (min-width:1125px) {
                        float: right;
                        display: flex;
                    }
                }
            }
        }
    }

    .social {
        @media screen and (min-width:1125px) {
            margin: 0 $desktop-small-padding 0 0;
        }

        &__list {
            margin: 0;
            padding: 0 1rem;

            @media screen and (min-width:1125px) {
                padding: 0;
            }
        }

        &__item {
            text-align: center;
            display: inline-block;

            &:not(:last-child) {
                margin-right: 5px;
            }
        }

        &__link {
            text-decoration: none;
            color: $primary-color;
            @include grey-border();
            height: 30px;
            width: 30px;
            line-height: 30px;
            @include border-radius(150px);
            display: block;
            align-items: center;
            display: flex;
            justify-content: center;

            @include tablet {
                height: 40px;
                width: 40px;
                line-height: 40px;
            }

            img {
                max-width: 17px;
            }
        }

        &__icon {
            fill: $color-violet-blue;
            width: 17px;
            height: 17px;
        }
    }
}
