.autism-map {
    position: relative;
    background: $color-white;
    @include border-radius(20px 20px 0 0);

    @include tablet {
        background: none;
        height: 536px;
        @include border-radius(20px 20px 0 20px);
        display: flex;
    }

    &__icon {
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: -1;

        @include tablet {
            position: absolute;
            right: 40px;
            top: 27px;
        }

        img {
            height: 45px;
            width: 45px;
        }
    }

    &__intro {
        &.hide {
            @include mobile-only {
                display: none;
            }
        }
    }

    &__content {
        padding: 20px;
        border: 1px solid $color-white-three;
        position: relative;
        border-bottom: 0;
        @include border-radius(20px 20px 0 0 );
        background: $color-white;

        @include tablet {
            border: 1px solid $color-white-three;
            flex-basis: 35%;
            -ms-flex-preferred-size: 35%;
            max-width: 35%;
            padding: 30px;
            @include border-radius(20px 0 0 20px);
        }

        .button {
            width: 100%;
        }
    }

    &__section {
        background: $color-white;
        transition: visibility 0.25s, opacity 0.25s linear;
        z-index: 2;
        width: 100%;
        opacity: 0;
        display: none;
        height: 340px;

        @include tablet {
            position: absolute;
            height: 100%;
            padding: 30px;
            left: 0;
            top: 0;
            @include border-radius(20px 0 0 20px);
        }

        &.active {
            display: block;
            opacity: 1;
        }
    }

    &__overlay {
        height: 100%;
        width: 100%;
        position: relative;

        .autism-map__block {
            flex-basis: 100%;
            -ms-flex-preferred-size: 100%;
            max-width: 100%;
            padding: 0;
        }

        .autism-map__action {
            flex-basis: 100%;
            -ms-flex-preferred-size: 100%;
            max-width: 100%;
            padding: 0;
        }
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        height: 30px;
        border: 1px solid $color-white-three;
        width: 30px;
        text-align: center;
        z-index: 999;
        @include border-radius(150px);
        padding: 9px;

        svg {
            vertical-align: top;
            height: 100%;
            width: 100%;
            fill: $color-violet-blue;
        }
    }

    &__title {
        font-size: 22px;
        font-weight: 600;
        line-height: 1.31;
        letter-spacing: -0.5px;
        color: $color-violet-blue;
        margin-bottom: 15px;

        @include tablet {
            font-size: 32px;
        }
    }

    &__description {
        opacity: 0.8;
        font-size: 14px;
        color: $color-black;
        line-height: 1.5;
        letter-spacing: normal;
        margin-bottom: 15px;

        @include tablet {
            font-size: 16px;
            margin-bottom: 30px;
        }
    }

    &__view {
        border: 1px solid $color-white-three;
        position: relative;
        height: 360px;

        @include mobile-only {
            border-top: 0;
            clear: both;
            @include border-radius(0 0 0 20px);
        }

        @include tablet {
            height: auto;
            border-left: 0;
            flex-basis: 65%;
            -ms-flex-preferred-size: 65%;
            max-width: 65%;
            @include border-radius(0 20px 0 0);
        }
    }

    &__results {
        display: none;
        position: relative;
        height: 87%;

        @include tablet {
            height: 90%;
        }

        &.is-active {
            display: block;
        }

        &:after {
            content: "";
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            pointer-events: none;
            background-image: linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 1) 90%);
            width: 100%;
            height: 10%;
            @include border-radius(0 0 0 20px);

            @include tablet {
                border-radius: 0;
            }
        }
        /* Scrollbar Styling */
        ::-webkit-scrollbar {
            width: 10px;
        }

        ::-webkit-scrollbar-track {
            background-color: $color-pale-grey;
            -webkit-border-radius: 10px;
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb {
            @include border-radius(3px);
            opacity: 0.2;
            background: #DFD5F0;
            padding: 10px;
        }
    }

    &__subheader {
        font-size: 22px;
        color: $color-violet-blue;
        line-height: 1.45;
        letter-spacing: -0.5px;
        font-weight: 500;
        margin-bottom: 15px;
    }

    &__list {
        background: $color-pale-grey;
        padding: 14px 14px 0 14px;
        overflow-y: scroll;
        height: 100%;
        overflow-x: hidden;
        @include border-radius(0 0 0 20px);

        @include tablet {
            border-radius: 0;
        }
    }

    &__nav-list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: flex-end;
        height: 13%;
        background: $color-white;
        clear: both;

        @include tablet {
            @include border-radius(0 20px 0 0);
            height: 10%;
        }
    }

    &__nav-li {
        flex: 1 1;
        height: 100%;
        text-align: center;

        &:last-child {
            .autism-map__nav-link {

                @include tablet {
                    @include border-radius(0 20px 0 0);
                }
            }
        }
    }

    &__nav-link {
        color: $color-violet-blue;
        text-align: center;
        font-size: 14px;
        text-decoration: none;
        display: block;
        @include transition(all,0.5s,ease);
        font-size: 14px;
        padding: 10px 0;
        height: 100%;
        border: 1px solid $color-violet-blue;
        line-height:24px;
        @include tablet {
            line-height:20px;
            padding: 16px 0;
            font-size: 16px;
        }

        &:hover {
            color: $color-white;
            font-weight: 500;
            background: $color-violet-blue;
        }

        &.is-active {
            color: $color-white;
            font-weight: 500;
            background: $color-violet-blue;
        }
    }


    &__item {
        padding: 30px;
        background: $color-white;
        border: 1px solid $color-white-three;
        @include border-radius(15px 15px 0 15px);
        margin-bottom: 15px;

        .autism-map__row {
            flex: 0 1 auto;
            flex-direction: row;
            flex-wrap: wrap;
            overflow: hidden;
            margin-left: -1rem;
            margin-right: -1rem;
        }
    }

    &__subtitle {
        color: $color-violet-blue;
        font-size: 22px;
        font-weight: 500;
        line-height: 1.45;
        letter-spacing: -0.5px;
    }

    &__information {
        font-size: 12px;
        line-height: 1.5;
        color: $color-greyish-brown;
        margin-bottom: 30px;
    }

    &__block {
        padding-left: 1rem;
        padding-right: 1rem;

        @include tablet {
            float: left;
            flex-basis: 50%;
            -ms-flex-preferred-size: 50%;
            max-width: 50%;
            width: 100%;
        }

        p {
            font-size: 12px;
            color: $color-greyish-brown;
            line-height: 1.5;
            margin-bottom: 15px;

            span {
                color: $color-warm-grey;
            }
        }
    }

    &__action {
        flex-basis: 100%;
        width: 100%;
        -ms-flex-preferred-size: 100%;
        max-width: 100%;
        float: left;
        clear: both;
        padding-left: 1rem;
        padding-right: 1rem;

        .button {
            font-size: 14px;
            padding: 10px 20px;
        }
    }

    &__logo {
        img {
            max-width: 150px;
            @include transition(all,0.5s,ease);
            margin-bottom: 15px;

            @include desktop {
                margin-bottom: 30px;
                max-width: 200px;
            }
        }
    }

    &__map {
        width: 100%;
        left: 0;
        top: 0;
        display: none;
        height: 87%;
        @include border-radius(0 0 0 20px);
        overflow: hidden;

        @include tablet {
            height: 90%;
            @include border-radius(0);
        }

        &.is-active {
            display: block;
        }

        .map-widget {
            width: 100%;
            height: 100%;
        }
    }
}
