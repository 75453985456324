*,
*::before,
*::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    scroll-behavior: smooth;
    position: relative;
}

.html {
    position: relative;
    font-size: 100%;
}

body {
    @include hero-new-regular();
    @include transition(filter, 0.2s, ease);
    position: relative;
    height: 100vh;
    color: $color-dark-grey;
    font-size: 100%;
}

.container {
    $this: &;

    @include container();

    position: relative;

    &--narrow {
        @include desktop {
            max-width: $narrow-container-max-width;
        }
    }

    &--extra-narrow {
        max-width: $container-mid-max-width;
    }

    &--flex {
        display: flex;
        flex-wrap: wrap;
    }

    &#{$this}--w-auto {
        width: auto;
    }
}

*:focus {
    outline: $outline-default;
}

[data-whatintent='mouse'] *:focus,
[data-whatintent='touch'] *:focus {
    outline: none;
}

.curved-line {
    position: relative;

    &::before,
    &::after {
        content: '';
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
    }

    &::before {
        bottom: 60px;
        z-index: -90;
        height: 40px;
        background-position: center top;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' x='0px' y='0px' viewBox='0 0 1900 80'%3E%3Cpath fill='%23ffffff' d='M-1-0.5c271.4,46,580.7,81.7,951,80.5c355-1.1,686.8-35.9,950-80.5C1266.3-0.5,632.7-0.5-1-0.5z'/%3E%3C/svg%3E");

        @include tablet {
            bottom: 120px;
            height: 80px;
        }
    }

    &::after {
        bottom: 0;
        z-index: -100;
        height: 100px;
        background-color: $color-pale-grey;

        @include tablet {
            height: 200px;
        }
    }

    &--no-mobile {
        &::before,
        &::after {
            @include mobile-only {
                background: none;
            }
        }
    }
}

.filter {
    filter: saturate(50%);
}
