.help-tip {
    position: relative;
    right: -10px;
    width: 270px;
    padding: 20px 10px 16px 30px;
    background-color: white;
    border-radius: 20px 20px 0 20px;
    box-shadow: 0 0 20px 0 rgba(black, 0.15);

    @include tablet {
        width: 370px;
        padding: 20px;
    }

    @include desktop {
        padding: 20px 30px;
    }

    &::before {
        content: '';
        position: absolute;
        top: -20px;
        left: -20px;
        display: block;
        width: 50px;
        height: 50px;
        background-color: $color-rosy-pink;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 24px 20px;
        border-radius: 50% 50% 50% 0;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='20' viewBox='0 0 24 20'%3E%3Cpath fill='%23FFF' fill-rule='evenodd' d='M12 20C22.047 13.489 25.785 7.959 23.215 3.411c-2.57-4.548-6.308-4.548-11.214 0M12 20C1.953 13.489-1.785 7.959.785 3.411c2.57-4.548 6.308-4.548 11.214 0'/%3E%3C/svg%3E");

        @include tablet {
            top: -30px;
            left: -30px;
            width: 60px;
            height: 60px;
        }
    }

    &__text {
        color: $color-greyish-brown;
        font-size: 14px;
        line-height: 1.5;

        @include tablet {
            font-size: 16px;
        }
    }

    &--mobile {
        display: block;

        @include tablet {
            display: none;
        }
    }

    &--desktop {
        display: none;

        @include tablet {
            display: block;
        }
    }
}
