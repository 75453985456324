.event-expanded {
    &__title {
        margin-bottom: 10px;
        color: $color-warm-grey-two;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 1.5px;
        line-height: 1.8;
        text-transform: uppercase;

        @include tablet {
            font-size: 12px;
            line-height: 1.67;
        }
    }

    &__item {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__spin {
        height: 100px;
    }
}
