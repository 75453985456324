.order-summary {
    color: $color-greyish-brown;

    &__line {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        line-height: 1.5;

        @include tablet {
            font-size: 16px;
        }

        & + & {
            margin-top: 15px;

            @include tablet {
                margin-top: 10px;
            }
        }

        &--calculated {
            align-items: center;
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid $color-white-three;

            @include tablet {
                margin-top: 30px;
                padding-top: 15px;
                font-weight: 500;
            }
        }
    }

    &__title {
        margin-right: 10px;
    }

    &__value {
        font-weight: 500;
    }

    &__vat {
        font-size: 12px;
        font-weight: 400;
    }

    &__note {
        margin-top: 15px;
        color: $color-greyish-brown;
        font-size: 12px;
        font-style: italic;
        line-height: 1.5;

        @include tablet {
            line-height: 1.67;
        }
    }
}
