.benefits-list {
    &__title {
        font-size: 22px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: -0.5px;
        color: $color-violet-blue;
        margin-bottom: 15px;

        @include tablet {
            margin-bottom: 30px;
            font-size: 32px;
        }
    }

    &__list {
        background: $color-white;
        border: 1px solid $color-white-three;
        padding: 20px;
        list-style: none;
        @include spectrum-radius();
        margin: 0;

        @include tablet {
            @include column-count(2,20px);
            padding: 30px;
        }
    }

    &__item {
        margin-bottom: 15px;
        padding-left: 20px;
        position: relative;
        font-size: 14px;
        line-height: 24px;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;

        @include tablet {
            font-size: 16px;
        }

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 8px;
            width: 6px;
            height: 6px;
            background-color: $color-purple-pink;
            border-radius: 50%;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
        }
    }
}
