.community-signpost {
    @include grey-border();
    box-shadow: 5px 10px 10px 0 rgba(144, 121, 192, 0.09);
    @include spectrum-radius();
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    background: $color-white;

    &__content {
        padding: 20px;

        @include tablet {
            -ms-flex-preferred-size: 65%;
            flex-basis: 65%;
            max-width: 65%;
            padding: 60px;
        }
    }

    &__supertitle {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.17;
        letter-spacing: -0.5px;
        color: $color-violet-blue;
        margin-bottom: 15px;

        @include tablet {
            font-size: 19px;
        }
    }

    &__title {
        font-size: 26px;
        font-weight: bold;
        line-height: 1.17;
        letter-spacing: -0.5px;
        color: $color-violet-blue;
        margin-bottom: 15px;

        @include tablet {
            font-size: 46px;
        }
    }

    &__description {
        font-size: 14px;
        color: $color-greyish-brown;
        line-height: 1.5;
        margin-bottom: 15px;

        @include tablet {
            margin-bottom: 30px;
            font-size: 16px;
        }
    }

    &__speech {
        background-color: #f6f6f6;
        @include spectrum-radius();
        display: flex;
        width: 179px;
        padding: 12px 17px;
        margin-bottom: 15px;

        @include tablet {
            width: 213px;
            margin-bottom: 30px;
            padding: 15px 20px;
        }

        img {
            max-height: 45px;
            margin-right: 15px;
        }

        &:nth-child(2) {
            float: right;
        }

        &:last-child {
            display: none;

            @include tablet {
                display: flex;
            }
        }
    }

    &__image {
        position: relative;
        max-width: 70%;
        flex-basis: 70%;
        margin: -1em auto 0;

        @include tablet {
            flex-basis: 35%;
            margin: -1em 0 0;
            padding-right: 60px;
            order: 2;
            max-width: 35%;
        }
    }

    &__quote {
        display: inline-block;
        width: 60%;
        align-self: center;
        padding-right: 10px;

        @include tablet {
            padding-right: 15px;
        }

        span {
            background-color: #e6e4e4;
            width: 100%;
            display: block;
            height: 5px;
            @include border-radius(3px);

            &:first-child {
                width: 50%;
                background-color: #d5d2d2;
            }

            &:not(:last-child) {
                margin-bottom: 9px;
            }
        }
    }

    &__heart {
        display: inline-block;
        fill: #e1e1e1;
        align-self: flex-start;
        width: 20px;
        height: 17px;

        &--pink {
            fill: $color-rosy-pink;
        }
    }

    &__icon {
        height: 39px;
        width: 39px;
        margin-right: 15px;
        text-align: center;
        color: $color-white;
        font-size: 13px;
        font-weight: 400;
        line-height: 42px;
        @include border-radius(150px 150px 0 150px);
        background: $color-rosy-pink;
    }

    .button {
        width: 100%;

        @include tablet {
            width: auto;
        }
    }

    &__dots {
        position: absolute;
        display: none;

        @include tablet {
            display: block;
            right: -185px;
            height: 94%;
            top: 27px;
        }

        &--left {
            @include tablet {
                left: -165px;
                height: 94%;
                top: 8px;
            }
        }
    }

    &__dots-mobile-left {
        position: absolute;
        left: 15px;
        top: 73px;
        width: 44px;

        @media screen and (max-width:330px) {
            height: 36px;
        }

        @include tablet {
            display: none;
        }
    }

    &__dots-mobile-right {
        right: 15px;
        top: 20px;
        height: 44px;
        position: absolute;

        @media screen and (max-width:330px) {
            height: 25px;
        }

        @include tablet {
            display: none;
        }
    }
}
