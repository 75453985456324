.featured-news {
    padding-left: 15px;
    padding-right: 15px;
    background: $color-pale-grey;
    position: relative;

    @include tablet {
        padding: 0 30px;
    }

    @include desktop {
        padding: 0 15px;
    }

    &__item {
        @include grey-border();
        @include spectrum-radius();
        @include clearfix();
        margin-bottom: 30px;
        background: $color-white;

        @include tablet {
            margin-bottom: 0;
            display: flex;
            align-items: stretch;
            min-height: 280px;
        }

        @include desktop {
            min-height: 220px;
        }
    }

    &__content {
        padding: $mobile-medium-padding;

        @include tablet {
            padding: 30px 20px 30px 30px;
            width: 50%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__content a {
        width:100%;
        text-decoration: none;
    }

    &__content p {
        @include tablet {
            position: absolute;
            bottom: $mobile-medium-padding;
        }
    }

    &__subtitle {
        font-size: 8px;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        color: $color-warm-grey-two;
        margin-bottom: $mobile-xsmall-padding;
        font-weight: 500;

        @include tablet {
            font-size: 10px;
            margin-bottom: $desktop-xsmall-padding;
        }
    }

    &__title {
        color: $color-violet-blue;
        line-height: 24px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 20px;

        @include tablet {
            font-size: 18px;
        }
    }

    &__category {
        background: $color-teal;
        color: $color-white;
        font-weight: 500;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        @include spectrum-radius();
        padding: 0.4em 0.8em;
        position: absolute;
        top: 20px;
        font-size: 12px;
        right: 30px;
    }

    &__description {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $color-warm-grey;
    }

    &__image {
        overflow: hidden;
        position: relative;
        width: 100%;

        @include tablet {
            float: right;
            margin-left: 1px;
            order: 2;
            overflow: visible;
            position: relative;
            width: 50%;
            text-align: right;
        }

        img {
            @include border-radius(20px 20px 0 20px);
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include tablet {
                position: absolute;
            }
        }
    }

    .button {
        font-size: 14px;
        width: 100%;

        @include tablet {
            width: auto;
        }
    }

    &__curve {
        position: absolute;
        left: 0;
        width: 100%;
        fill: $color-white;
        z-index: 22;
        bottom: 3px;

        @include tablet {
            display: none;
        }
    }

    &__curve-right {
        position: absolute;
        top: 0;
        left: -1px;
        display: none;
        height: 100%;
        fill: $color-white;
        z-index: 222;

        @include tablet {
            display: block;
        }
    }

    &__main-curve {
        fill: $color-pale-grey;
        background: $color-white;
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        display: block;
    }
}
