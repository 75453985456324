.account-details-form {
    &__col {
        & + & {
            margin-top: 15px;

            @include tablet {
                margin-top: 0;
            }
        }
    }

    &__fields {
        & + & {
            margin-top: 30px;

            @include tablet {
                margin-top: 60px;
            }
        }
    }

    &__field {
        position: relative;

        & + & {
            margin-top: 15px;

            @include tablet {
                margin-top: 30px;
            }
        }

        &--name {
            @include tablet {
                display: flex;
            }
        }
    }

    &__fieldset {
        margin: 10px 0 0 0;
        padding: 0;
        border: none;
    }

    &__dropdown-title {
        display: block;
        max-width: 140px;

        @include tablet {
            max-width: 190px;
        }
    }

    &__link-change {
        position: absolute;
        right: 5px;
        bottom: 5px;
        display: inline-block;
        padding: 10px 10px 10px 0;
        background-color: white;
        color: $color-violet-blue;
        font-size: 10px;
        line-height: 1.5;

        @include tablet {
            right: 8px;
            padding: 12px 12px 12px 0;
            font-size: 12px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -35px;
            display: block;
            width: 35px;
            height: 100%;
            background-image: linear-gradient(to left, white, rgba(white, 0));
        }
    }

    &__address-title {
        margin-bottom: 15px;
        color: $color-violet-blue;

        @include tablet {
            margin-bottom: 30px;
        }
    }

    &__legend {
        margin-bottom: 10px;
        color: $color-warm-grey-two;

        @include tablet {
            margin-bottom: 15px;
        }
        &--label {
            margin-bottom:10px;
        }
    }

    &__checkbox {
        & + & {
            margin-top: 15px;
        }
    }

    &__submit {
        width: 100%;
        margin-top: 30px;

        @include tablet {
            width: auto;
            min-width: 200px;
            margin-top: 60px;
        }
    }
}
