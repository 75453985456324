.account {
    padding-bottom: 40px;

    @include tablet {
        padding: 80px $padding-side-tablet;
    }

    &__username-wrapper {
        display: flex;
        padding: 15px $padding-side-mobile;
        background-color: $color-pale-grey;

        @include tablet {
            @include spectrum-radius();
            padding: 30px $padding-side-tablet;
        }
    }

    &__wrapper {
        margin-top: 15px;
        padding: 0 $padding-side-mobile;

        @include tablet {
            margin-top: 30px;
            padding: 0;
        }
    }

    &__information-wrapper {
        padding: 0 $padding-side-mobile;

        @include tablet {
            padding: 0;
        }
    }

    &__initials {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        width: 52px;
        height: 52px;
        margin-right: 20px;
        background-color: $color-rosy-pink;
        border-radius: 50% 50% 0 50%;
        color: white;
        font-size: 16px;
        font-weight: 600;

        @include tablet {
            margin-right: 15px;
        }
    }

    &__username {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $color-greyish-brown;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.5px;
        line-height: 1.25;

        @include tablet {
            font-size: 22px;
        }
    }

    &__greeting {
        display: block;
        width: 21px;
        height: 24px;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 1.5;

        @include tablet {
            font-size: 16px;
        }
    }

    &__selector {
        @include tablet {
            display: none;
        }
    }

    &__sections {
        @include reset-list();
        @include spectrum-radius();
        display: none;
        padding: 15px 30px;
        border: 1px solid $color-white-three;

        @include tablet {
            display: block;
        }
    }

    &__section {
        padding: 15px 0;

        & + & {
            border-top: 1px solid $color-white-three;
        }
    }

    &__link {
        @include transition(color, 0.3s, ease);
        display: block;
        vertical-align: middle;
        color: $color-greyish-brown;
        font-weight: 500;
        line-height: 1.4;
        text-decoration: none;

        &:hover,
        &:focus {
            color: darken($color-greyish-brown, 30%);
        }

        &--sign-out {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                display: block;
                transform: translateY(-50%);
                width: 18px;
                height: 20px;
                background-repeat: no-repeat;
                background-size: 18px 20px;
                background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' fill='%233f3f3f' xml:space='preserve'%3E%3Cpath d='M255.15,468.625H63.787c-11.737,0-21.262-9.526-21.262-21.262V64.638c0-11.737,9.526-21.262,21.262-21.262H255.15 c11.758,0,21.262-9.504,21.262-21.262S266.908,0.85,255.15,0.85H63.787C28.619,0.85,0,29.47,0,64.638v382.724 c0,35.168,28.619,63.787,63.787,63.787H255.15c11.758,0,21.262-9.504,21.262-21.262 C276.412,478.129,266.908,468.625,255.15,468.625z'/%3E%3Cpath d='M505.664,240.861L376.388,113.286c-8.335-8.25-21.815-8.143-30.065,0.213s-8.165,21.815,0.213,30.065l92.385,91.173 H191.362c-11.758,0-21.262,9.504-21.262,21.262c0,11.758,9.504,21.263,21.262,21.263h247.559l-92.385,91.173 c-8.377,8.25-8.441,21.709-0.213,30.065c4.167,4.21,9.653,6.336,15.139,6.336c5.401,0,10.801-2.041,14.926-6.124l129.276-127.575 c4.04-3.997,6.336-9.441,6.336-15.139C512,250.302,509.725,244.88,505.664,240.861z'/%3E%3C/svg%3E");
            }
        }

        &.current {
            color: $color-violet-blue;
            font-weight: 700;

            &:hover,
            &:focus {
                color: darken($color-violet-blue, 30%);
            }
        }
    }

    &__information {
        margin-top: 30px;

        @include tablet {
            margin-top: 0;
        }
    }

    &__home {
        @include spectrum-radius();
        position: relative;
        overflow: hidden;
        z-index: 0;
        min-height: 450px;
        padding: 30px;
        background-color: $color-violet-blue;

        @include tablet {
            height: 540px;
            padding: 60px;
        }
    }

    &__title-home {
        color: white;
    }

    &__spectrum {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -10;
        width: 150%;
        height: 70%;

        @include tablet {
            width: 530px;
            height: 350px;
        }
    }

    &__partition {
        @include spectrum-radius();
        padding: 20px;
        border: 1px solid $color-white-three;

        @include tablet {
            padding: 30px;
        }
    }

    &__title-partition {
        color: $color-violet-blue;
    }

    &__text-partition {
        margin-top: 10px;

        @include tablet {
            margin-top: 15px;
        }
    }

    &__text-relationship {
        margin-bottom: 15px;
    }

    &__col-membership {
        & + & {
            margin-top: 15px;

            @include tablet {
                margin-top: 0;
            }
        }
    }

    &__subtext-membership {
        color: $color-warm-grey-two;
    }

    &__details-form {
        margin-top: 15px;

        @include tablet {
            margin-top: 60px;
        }
    }

    &__btn-community {
        width: 100%;
        margin-top: 30px;

        @include tablet {
            width: auto;
        }
    }

    &__extra {
        display: none;

        @include tablet {
            display: block;
            margin-top: 30px;
        }
    }

    &__widget-container {
        padding: 0 15px;

        @include tablet {
            padding: 0;
        }
    }
}
