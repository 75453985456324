.notification-item {
    $this: &;

    @include spectrum-radius();
    @include fadeIn();


    padding: 10px $padding-side-mobile;

    @include tablet {
        padding: 18px $padding-side-tablet;
    }

    &__inner {
        position: relative;
        padding-right: 20px;

        @include mobile-wide {
            display: flex;
        }
    }

    &__label {
        margin-bottom: 5px;
        font-weight: bold;

        @include mobile-wide {
            display: flex;
            margin-right: 20px;
            margin-bottom: 0;
        }
    }

    &__close-btn {
        @include reset-button;

        position: absolute;
        top: 5px;
        right: 0;
        width: 15px;
        height: 15px;
    }

    &--error {
        background-color: $color-pale-pink;

        #{$this}__inner {
            color: $color-falu-red;
        }
    }

    &--info {
        background-color: $color-solitude;

        #{$this}__inner {
            color: $color-dark-slate-blue;
        }
    }

    &--success {
        background-color: $color-hint-green;

        #{$this}__inner {
            color: $color-myrtle;
        }
    }

    &--warning {
        background-color: $color-corn-silk;

        #{$this}__inner {
            color: $color-raw-umber;
        }
    }
}
