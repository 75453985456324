.sign-post-block {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex: 0 1 auto;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -1rem;
    margin-left: -1rem;

    .sign-post-block__column {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
        margin-bottom: $desktop-small-padding;
    }

    &--one-of-one {
        .sign-post-block__column {
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            max-width: 100%;
            margin-bottom: 15px;

            @include tablet {
                margin-bottom: 30px;
            }
        }
    }

    &--one-of-two {
        .sign-post-block__column {
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            max-width: 100%;
            margin-bottom: 15px;

            @include tablet {
                -ms-flex-preferred-size: 50%;
                margin-bottom: 0;
                flex-basis: 50%;
                max-width: 50%;
            }
        }
    }

    &--one-of-three {
        .sign-post-block__column {
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            max-width: 100%;
            margin-bottom: 15px;

            @include tablet {
                -ms-flex-preferred-size: 33.333%;
                flex-basis: 33.333%;
                margin-bottom: 0;
                max-width: 33.333%;
            }

            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9) {
                @include tablet {
                    margin-top: 30px;
                }
            }
        }
    }

    &--two-of-two {
        .sign-post-block__column {
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            max-width: 100%;
            margin-bottom: 15px;

            @include tablet {
                -ms-flex-preferred-size: 50%;
                flex-basis: 50%;
                margin-bottom: 30px;
                max-width: 50%;
            }

            &:last-child {
                @include tablet {
                    margin-bottom: 30px;
                }
            }
        }
    }

    &--two-of-three {
        .sign-post-block__column {
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            max-width: 100%;
            margin-bottom: 15px;

            @include tablet {
                -ms-flex-preferred-size: 33.333%;
                flex-basis: 33.333%;
                margin-bottom: 30px;
                max-width: 33.333%;
            }

            &:last-child {
                @include tablet {
                    margin-bottom: 30px;
                }
            }
        }
    }

    .sign-post__title {
        float: left;
    }

    &__column {
        box-sizing: border-box;
        flex: 0 0 auto;
        padding-right: 1rem;
        padding-left: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.sign-post {
    background: #ffffff;
    @include clearfix();
    @include spectrum-radius;
    @include grey-border();
    position: relative;

    @include tablet {
        height: 100%;
    }

    @include desktop {
        margin: 0;
    }

    &__content {
        padding: 20px;

        @include tablet {
            padding: 30px;
        }

        .button {
            &:first-of-type {
                margin: 0 0 $mobile-small-padding 0;

                @include tablet {
                    margin: 0 14px 0 0;
                }
            }

            @include mobile-only {
                width: 100%;
            }
        }

        .sign-post__title {
            width: 100%;
        }
    }

    &__link {
        display: flex;
        height: 100%;
        width: 100%;
    }

    &--simple-narrow {
        display: flex;
        flex-flow: column nowrap;
        padding: 20px;

        @include tablet {
            padding: 30px;
        }

        .sign-post__button {
            clear: both;
            float: left;
            margin-top: auto;
        }

        .sign-post__link {
            flex-flow: column nowrap;
        }

        .sign-post__title {
            width: 70%;
            float: left;

            @include tablet {
                width: 50%;
            }

            @include desktop {
                width: 60%;
            }
        }

        .sign-post__description {
            margin-bottom: 15px;
        }
    }

    &--simple-full {
        display: flex;
        align-items: center;
        padding: 20px;

        @include tablet {
            padding: 30px;
        }

        .sign-post__title {
            width: 70%;
            margin: 0;

            @include tablet {
                width: 70%;
            }

            @include desktop {
                width: 60%;
            }

            float: left;
        }

        .sign-post__button {
            margin-left: auto;
        }

        .sign-post__button--left {
            float: left;
            clear: both;
        }
    }

    &--detailed-full {
        .sign-post__image {

            @include tablet {
                height: 100%;
                float: right;
                width: 50%;
            }

            img {
                @include tablet {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .sign-post__curve-left {
            display: none;

            @include tablet {
                display: block;
            }
        }

        .sign-post__curve {
            @include tablet {
                display: none;
            }
        }

        .sign-post__content {
            @include tablet {
                float: left;
                width: 50%;
            }
        }

        .sign-post__description {
            margin-bottom: 30px;
        }

        .sign-post__image {
            @include tablet {
                text-align: right;
            }
        }

        .sign-post__content .button {
            @include tablet {
                width: 45%;
                float: left;
            }
        }

        .sign-post__title {
            line-height: 36px;

            @include tablet {
                font-size: 26px;
            }
        }
    }

    &--detailed-narrow {
        display: flex;
        flex-direction: column;

        .sign-post__content {
            flex: 1;
            display: flex;
            flex-flow: column nowrap;

            .button {
                width: 100%;
                margin-right: 1em;

                &:last-of-type {
                    @include tablet {
                        margin-top: 15px;
                    }
                }
            }
        }

        .sign-post__title {
            line-height: 36px;

            @include tablet {
                font-size: 26px;
            }
        }

        .sign-post__image {
            background: $color-white;
            @include border-radius(20px 20px 0 0);
        }

        .sign-post__description {
            width: 100%;
        }
    }

    &--lozenge-full {
        display: flex;
        flex-direction: column;

        .sign-post__link {
            display: revert;
            text-decoration: inherit;
            color: $body-font-color;
        }

        .sign-post__content {
            flex: 1;
            display: flex;
            flex-flow: column nowrap;
            padding: 20px;
        }

        .sign-post__title-zone {
            padding: 10px;
            padding-left: 15px;
            border-radius: 20px 20px 0px 0px;
            background-color: $color-violet-blue;
            display: flex;
            justify-content: space-between;

            .sign-post__title {
                color: $black-5;
                margin-bottom: 0px;
                width: auto;

                h3 {
                    font-size: 12pt !important;
                }
            }

            .sign-post__button {
                img {
                    border: none;
                }
            }
        }
    }


    &--purple {
        background: $color-violet-blue;
        border: none;

        .sign-post__title,
        .sign-post__description {
            color: $color-white;
        }

        .sign-post__image img {
            border: 1px solid $color-white-three;
            border-bottom: 0;
        }

        .button--primary {
            background: $color-bluish-purple;
            border-color: $color-bluish-purple;

            &:hover {
                background: darken($color-bluish-purple,10%);
                border-color: darken($color-bluish-purple,10%);
            }
        }

        .button--clear {
            border-color: $color-white;
            color: $color-white;

            &:hover {
                background: darken($color-bluish-purple,10%);
                border-color: darken($color-bluish-purple,10%);
            }
        }

        .sign-post__curve {
            fill: $color-violet-blue;
        }

        .sign-post__curve-left {
            fill: $color-violet-blue;
        }
    }

    &--pink {
        background: $color-purple-pink;
        border: none;

        .sign-post__title,
        .sign-post__description {
            color: $color-white;
        }

        .sign-post__image img {
            border: 1px solid $color-white-three;
            border-bottom: 0;
        }

        .button--primary {
            background: lighten($color-purple-pink,10%);
            border-color: lighten($color-purple-pink,10%);
            color: $color-white;

            &:hover {
                background: darken($color-purple-pink,10%);
                border-color: darken($color-purple-pink,10%);
            }
        }

        .button--clear {
            border-color: $color-white;
            color: $color-white;

            &:hover {
                background: darken($color-purple-pink,10%);
                border-color: darken($color-purple-pink,10%);
            }
        }

        .sign-post__curve {
            fill: $color-purple-pink;
        }

        .sign-post__curve-left {
            fill: $color-purple-pink;
        }
    }

    &--richpurple {
        background: $color-barney;
        border: none;

        .sign-post__title,
        .sign-post__description {
            color: $color-white;
        }

        .sign-post__image img {
            border: 1px solid $color-white-three;
            border-bottom: 0;
        }

        .button--primary {
            background: lighten($color-barney,10%);
            border-color: lighten($color-barney,10%);
            color: $color-white;

            &:hover {
                background: darken($color-barney,10%);
                border-color: darken($color-barney,10%);
            }
        }

        .button--clear {
            border-color: $color-white;
            color: $color-white;

            &:hover {
                background: darken($color-barney,10%);
                border-color: darken($color-barney,10%);
            }
        }

        .sign-post__curve {
            fill: $color-barney;
        }

        .sign-post__curve-left {
            fill: $color-barney;
        }
    }

    &__inner-link {
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__image {
        position: relative;
        height: 200px;

        @include tablet {
            height: auto;
        }

        img {
            @include border-radius(20px 20px 0 0);
            max-width: 100%;
            vertical-align: top;
            height: auto;
            width: 100%;

            @include tablet {
                width: auto;
            }

            @include mobile-only {
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }

    &__icon {
        position: absolute;
        right: 30px;
        top: 30px;

        @include tablet {
            top: 34px;
        }

        img {
            max-width: 50px;

            @include desktop {
                max-width: 60px;
            }
        }
    }

    &__title {
        color: $color-violet-blue;
        font-size: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: -0.5px;

        @include tablet {
            width: 70%;
            font-size: 22px;
        }

        @include desktop {
            width: 60%;
        }
    }

    &__description {
        margin-bottom: 15px;
        width: 90%;
        float: left;
        font-size: 14px;
        line-height: 24px;

        @include tablet {
            margin-bottom: 30px;
            font-size: 16px;
        }
    }

    &__arrow {
        height: 30px;
        width: 30px;
        fill: $color-lavender;
    }

    &__curve {
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        fill: $color-white;

        &--border {
            fill: none;
            stroke: #E0E0E0;
            stroke-miterlimit: 10;
            stroke-width: 1px;
        }
    }

    &__curve-left {
        position: absolute;
        height: 100%;
        fill: $color-white;
        left: 0;
    }

    &__button {
        img {
            border: 1px solid $color-lavender;
            @include border-radius(150px);
            padding: 6px;
        }
    }

    .button {
        font-size: 14px;
        padding-left: 0;
        padding-right: 0;
    }

    &__actions {
        width: 100%;
        float: left;
        margin-top: auto;
    }
}
