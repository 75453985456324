// Vars from Webpack config
$viewports: (mobile-wide: 580, tablet: 768, desktop: 964, desktop-wide: 1200);
$containerMaxWidth: 1440;
$defaultTransitionDuration: 300;

.engagement_panel {
    display: flex;
    border-top: 1px solid #bbb;
    margin-top: 20px;
    padding-top: 20px;
}

.engagement_panel img {
    max-height: 30px;
}

.engagement_panel div {
    text-align: center;
    margin-bottom: 0;
    width: 50%;
}

.engagement_panel p {
    margin: 0;
}

.engagement_panel span {
    display: inline;
}

.engagement_panel span.engagement_like_count,
.engagement_panel span.engagement_share_count {
    text-align: center;
}

.engagement_panel button {
    background: none !important;
    color: inherit;
    padding: 0;
    border: none;
}

.engagement_like img,
.engagement_share img {
    filter: saturate(0%);
    transition: transform 0.2s ease;
    cursor: pointer;
}

.engagement_like img.liked {
    filter: saturate(100%);
}

.engagement_like img:hover,
.engagement_share img:hover {
    transform: scale(1.2);
    filter: saturate(100%);
}

.engagement_like_tooltip {
    position: absolute;
    background: #ffffff;
    color: #000000;
    padding: 24px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    white-space: nowrap;
    width: 90% !important;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
}

.engagement_like_tooltip.show {
    opacity: 1;
}





/* Share targets icon styling */
.engagement_share_link {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

#engagement_share_input {
    width: 70%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/*#dw_pledge_copy_button {
    padding: 8px 12px;
    background-color: #4203bf;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

#dw_pledge_copy_button:hover {
    background-color: #30008f;
}*/

.engagement_social_icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 30px 0 40px;
}

.engagement_social_icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.engagement_social_icons a {
    margin: 0 5px;
}

.engagement_social_icons img {
    width: 40px;
    height: 40px;
    transition: transform 0.2s ease;
}

.engagement_social_icons img:hover {
    transform: scale(1.2);
}







/* Modal Styling */
.engagement_modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

}

.engagement_modal_content {
    background: white;
    padding: 32px 16px;
    width: 90%;
    max-width: 800px;
    margin: 5% auto;
    border-radius: 10px;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
    text-align: center;
}

/* Disable background scrolling when modal is open */
body.modal-open {
    overflow: hidden;
}

.engagement_modal_content h2 {
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 20px;
}

.engagement_modal_close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 36px;
    font-weight: 700;
    cursor: pointer;
}
