.guidance-landing-page {

    .generic-section {

        @include tablet {
            padding-left: 60px;
            padding-right: 60px;
        }
    }

    .generic-section.first {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 10px;
        background-color: $color-pale-grey;
        z-index: unset;

        @include tablet {
            padding: 30px 60px;
        }
    }

    .generic-section__container {
        max-width: unset !important;
        margin: auto !important;
    }

    &__inner {
        padding-top: 15px;

        @include tablet {
            padding: 0;
        }
    }

    .hero-banner {
        text-align: left;
        display: block;
        padding-top: 40px;
        background-color: $color-pale-grey;
        color: $primary-color;

        @include tablet {
            padding-top: 55px;
        }

        &__text-block {
            padding: 0;
            text-align: left;
        }

        &__title {
            margin-bottom: 40px;

            @include tablet {
                font-size: 46px;
            }
        }

        p {
            font-size: 14px;
            opacity: 0.8;
            line-height: 24px;
            margin: 0 auto;


            @include tablet {
                margin-bottom: 40px;
                font-size: 16px;
            }
        }
    }

    &__search-container {
        padding-bottom: 0px;

        @include tablet {
            width: 800px;
        }

        .guidance-search {
            position: relative;
            clear: both;
            margin: 0 auto;
            width: 100%;

            @include tablet {
                display: flex;
            }

            &__icon {
                position: absolute;
                left: 25px;
                top: 18px;
                width: 16px;
                height: 16px;
                fill: $color-pinkish-grey;

                @include tablet {
                    top: 31px;
                    width: 20px;
                    height: 20px;
                }
            }

            &__input {
                height: 50px;
                width: 100%;
                background: $color-white;
                border: 1px solid $color-white;
                padding: 16px 30px 16px 50px;
                @include border-radius(20px);
                margin-bottom: 15px;
                font-size: 12px;

                @include tablet {
                    padding: 30px 30px 30px 57px;
                    font-size: 14px;
                    margin-bottom: 0;
                    @include border-radius(20px 0 0 20px);
                    height: 80px;
                    width: 69%;
                }

                @include desktop {
                    width: 90%;
                }
            }

            &__submit {
                height: 35px;
                width: 70px;
                background: $color-purple-pink;
                color: $color-white;
                @include border-radius(17.5px);
                text-align: center;

                @include tablet {
                    height: 80px;
                    @include border-radius(0 20px 0 0);
                    width: 10%;
                }

                button {
                    height: 100%;
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    border: none;
                    outline: none;
                }

                .button-text {
                    color: $color-white;

                    @include tablet {
                        display: none;
                    }
                }
            }

            &__arrow {
                fill: $color-white;
                width: 50px;
                height: 25px;
                display: none;

                @include tablet {
                    display: block;
                }
            }
        }
    }
}
