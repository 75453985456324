.signin-modal {
    padding: 60px $default-mobile-side-padding 30px;
    text-align: center;

    @include tablet {
        padding: 60px 50px;
    }

    &__copy {
        margin-top: 15px;
    }

    &__controls {
        margin-top: 30px;
    }

    &__btn {
        min-width: 200px;

        & + & {
            margin-top: 20px;

            @include tablet {
                margin-top: 0;
                margin-left: 30px;
            }
        }
    }
}
