.pullout-text {

    &--white {
        .pullout-text__item {
            background: $color-white;
            border: 1px solid $color-white-three;
        }
    }

    &--grey {
        .pullout-text__item {
            background: $color-pale-grey;
        }
    }

    &__header {
        font-size: 22px;
        line-height: 1.38;
        letter-spacing: -0.5px;
        color: $color-violet-blue;
        margin-bottom: 15px;
        font-weight: 600;

        @include tablet {
            margin-bottom: 30px;
            font-size: 32px;
        }
    }

    &__row {
        box-sizing: border-box;
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex: 0 1 auto;
        -webkit-box-flex: 0;
        flex: 0 1 auto;
        -ms-flex-direction: row;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -1rem;
        margin-left: -1rem;
    }

    &__column {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
        box-sizing: border-box;
        flex: 0 0 auto;
        padding-right: 1rem;
        padding-left: 1rem;
        width: 100%;
        margin-bottom: 10px;

        @include tablet {
            -ms-flex-preferred-size: 33.33333%;
            flex-basis: 33.33333%;
            max-width: 33.33333%;
            margin-bottom: 0;
        }

        &:nth-child(2) {
            .pullout-text__item-header {
                color: $color-barney;
            }
        }

        &:last-child {
            .pullout-text__item-header {
                color: $color-purple-pink;
            }
        }
    }

    &__item {
        @include spectrum-radius();
        padding: 20px;

        @include tablet {
            height: 100%;
            padding: 30px;
        }
    }

    &__item-header {
        font-size: 22px;
        letter-spacing: -0.5px;
        color: $color-violet-blue;
        margin-bottom: 10px;
        font-weight: 600;

        @include tablet {
            line-height: 42px;
            font-size: 32px;
        }

        &--purple {
            color: $color-barney;
        }

        &--pink {
            color: $color-purple-pink;
        }
    }

    &__item-description {
        color: $color-greyish-brown;
        font-size: 14px;
        line-height: 1.57;
    }
}
