.twitter-feed {
    padding-top:15px;
    @include tablet {
        padding-top:30px;
    }
    &__title {
        font-size: 24px;
        color: $color-violet-blue;
        margin-bottom: 10px;
        font-weight: 600;

        @include tablet {
            font-size: 32px;
        }
    }

    .timeline-Footer {
        .u-floatLeft {
            display:none !important;
        }
    }
}
