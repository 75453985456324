.facebook-feed {
    padding-top: 15px;

    @include tablet {
        padding-top: 30px;
    }

    &__title {
        font-size: 24px;
        color: $color-violet-blue;
        margin-bottom: 15px;
        font-weight: 600;

        @include tablet {
            font-size: 32px;
            margin-bottom: 30px;
        }
    }

    .fb-page,
    .fb-page span,
    .fb-page span iframe[style] {
        width: 100% !important;
    }
}
