.account-extra {
    @include spectrum-radius;

    overflow: hidden;
    border: 1px solid $color-white-three;

    &__image {
        position: relative;
        width: 100%;
        min-height: 200px;
        background-color: $color-white-three;
    }

    &__curve {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        fill: white;
    }

    &__content {
        padding: 15px $padding-side-mobile;

        @include tablet {
            padding: 30px $padding-side-tablet;
        }
    }

    &__heading {
        color: $color-violet-blue;
    }

    &__text {
        margin-top: 15px;
    }

    &__button {
        width: 100%;
    }
}
