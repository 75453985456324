.nav-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    transition: transform 0.3s;
    transform: translateX(-200%);
    background: #ffffff;
    top: 0;
    left: 0;

    @media screen and (min-width:1125px) {
        float: right;
        width: auto;
        position: relative;
        transform: none;
        background: none;
    }

    &.show-menu {
        z-index: 2222;
        transform: none;
    }

    .js-close-menu {
        position: absolute;
        top: 1em;
        right: 15px;
        background: $primary-color;
        height: 40px;
        width: 40px;
        text-align: center;
        z-index: 999;
        @include border-radius(150px);
        padding: 12px;

        @media screen and (min-width:1125px)  {
            display: none;
        }

        img {
            vertical-align: top;
            height: auto;
            max-width: 100%;
        }
    }

    .inner-right__button {
        display: none;

        @media screen and (min-width:1125px)  {
            display: inline-flex;
        }
    }
}

.navigation {
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    left: 0;
    top: 0;
    height: 50vh;
    width: 100%;

    &--upper-level {
        height: 100vh;
    }

    &__arrow {
        fill: $color-pinkish-grey;
        height: 10px;
        width: 14px;
        position: absolute;
        right: -3px;
        top: 20px;
        transform: rotate(-90deg) scaleX(-1);

        @media screen and (min-width:1125px) {
            right: -1px;
            top: 15px;
            transform: none;
        }

        &--back {
            transform: rotate(90deg) scaleX(-1);
            left: -5px;
            top: 9px;
        }
    }

    @media screen and (min-width:1125px) {
        position: initial;
        height: auto;
        width: auto;
        display: block;
        background: none;
        float: left;
        align-self: center;
        overflow-x: inherit;
        overflow-y: inherit;
    }

    .nav-toggle {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0.85em 1rem;
        color: #000000;
        z-index: 100;
        cursor: pointer;
        left: 100%;
        transition: transform 0.3s;

        @media screen and (min-width:1125px) {
            display: none;
        }

        &.back-visible {
            .nav-back {
                opacity: 1;
            }

            .nav-title {
                transform: translateX(40px);
            }
        }
    }

    .nav-title {
        position: relative;
        left: 0;
        padding-left: 0.2em;
        transition: transform 0.3s;
    }

    .nav-back {
        display: inline-block;
        position: relative;
        width: 14px;
        height: 30px;
        vertical-align: middle;
        z-index: 1;
        opacity: 0;
        transition: opacity 0.2s;
    }

    &__link {
        display: block;
        position: relative;
        padding: 1em 0.7em 1em 0;
        border-bottom: 1px solid #eee;
        color: $color-greyish-brown;
        text-decoration: none;
        transition: color 0.15s, background-color 0.15s;
        font-size: 16px;
        font-weight: 500;

        @media only screen and (max-height: 680px) {
            font-size: 15px;
        }

        @media screen and (min-width:1125px) {
            padding: 10px 20px;
            border-bottom: 0;
            font-size: 15px;
        }

        @include desktop-wide {
            font-size: 16px;
            padding: 10px 25px;
        }

        &:hover {
            text-decoration: none;
            color: $primary-color;
        }

        &--primary {
            display: none;

            @media screen and (min-width:1125px) {
                display: block;
            }
        }
    }

    &__list {
        margin: 0;
        position: absolute;
        list-style: none;
        padding: 5rem 2.5rem 0 1rem;
        transition: transform 0.3s;
        top: 0;
        left: 0;
        width: 100%;

        @include mobile-only {
            @media screen and (max-height:680px) {
                padding: 3.5rem 2.5rem 0 1rem;
            }
        }

        @media screen and (min-width:1125px) {
            position: initial;
            padding: 0;
            width: auto;
            @include clearfix();
            background: none;
            top: auto;
            left: auto;
        }

        &--main {
            @media screen and (min-width:1125px) {
                border-bottom: 1px solid #eee;
                @include clearfix();
            }
        }

        .navigation__list {
            display: none;
            left: 100%;
            padding: 3rem 2.5rem 0 1rem;

            @media screen and (min-width:1125px) {
                position: absolute;
                z-index: 999;
                @include border-radius(0 0 0 10px);
                display: none;
                @include transition(transform,2.5s,ease);
                width: 100%;
                height: 510px;
                padding: 16px 0 0 0;
                left: initial;
                right: 0;
            }

            li {
                margin: 0;
                padding: 0;
            }

            &:hover {
                @media screen and (min-width:1125px) {
                    display: flex;
                }
            }

            &--show-menu {
                margin: 0;

                @media screen and (min-width:1125px) {
                    display: flex;
                    @include translate(0,0);
                }
            }

            .navigation__item {
                @media screen and (min-width:1125px) {
                    width: 35%;
                    border-left: 1px solid #eee;
                    @include border-radius(0 0 0 10px);
                    background: $color-white;
                    vertical-align: top;
                    padding: 1.5em 0;
                    height: 100%;
                }

                .navigation__item {
                    @media screen and (min-width:1125px) {
                        width: 100%;
                        background: none;
                        clear: both;
                        border-left: 0;
                        border-bottom: 0;
                        padding: 0;
                    }
                }

                .navigation__item--first {
                    .navigation__link {
                        color: $color-greyish-brown;
                        font-size: 16px;
                    }
                }

                .navigation__link {
                    color: $color-warm-grey-two;
                    border-bottom: 0;
                    font-size: 14px;

                    @media screen and (min-width:1125px) {
                        font-size: 16px;
                    }
                }
            }


            .navigation__item--has-dropdown {
                > .navigation__link {
                    @media screen and (min-width:1125px) {
                        color: $primary-color;
                        font-size: 18px;
                        margin-bottom: 0.5em;
                        border-radius: 150px 0 0 150px;

                        &:hover {
                            background: $color-pale-grey;
                        }
                    }



                    .navigation__arrow {
                        @media screen and (min-width:1125px) {
                            transform: rotate(-90deg) scaleX(-1);
                            display: block;
                            right: 20px;
                        }
                    }

                    &:after {
                        @media screen and (min-width:1125px) {
                            display: none;
                        }
                    }
                }

                .navigation__list {
                    @media screen and (min-width:1125px) {
                        width: 100%;
                        display: block;
                        height: auto;
                        top: auto;
                        left: auto;
                        @include translate(0,0);
                        padding: 0;
                        position: relative;
                    }

                    .navigation__list {
                        @media screen and (min-width:1125px) {
                            background: #f0edf6;
                            display: none;
                            position: absolute;
                            top: -2px;
                            border-radius: 0;
                            width: 186%;
                            padding-top: 20px;
                            padding-left: 11px;
                            height: 480px;
                            transform: translate(0,-66px);
                            left: 100%;
                        }

                        &:focus, &:hover {
                            display: block;
                        }


                        &--show-menu {
                            display: block;
                        }

                        > a {
                            @media screen and (min-width:1125px) {
                                color: #4203BF;
                                margin-bottom: 0.5em;
                            }
                        }

                        li {
                            &:first-child {
                                .navigation__link {
                                    @media screen and (min-width:1125px) {
                                        font-weight: 500;
                                        color: $color-violet-blue;
                                        margin-bottom: 0.5em;
                                        font-size: 18px;
                                    }
                                }
                            }

                            .navigation__link {

                                @media screen and (min-width:1125px) {
                                    color: $color-greyish-brown;
                                    padding-bottom: 0.5em;
                                    font-size: 16px;
                                    padding-left: 30px;
                                    padding-right: 30px;
                                }
                            }
                        }
                    }

                    > .navigation__link {
                        @media screen and (min-width:1125px) {
                            color: #3f3f3f;
                            margin-bottom: 1em;
                        }
                    }

                    .navigation__link {
                        @media screen and (min-width:1125px) {
                            color: $color-warm-grey-two;
                            display: block;
                            margin-bottom: 0;
                            padding-top: 9px;
                            padding-bottom: 9px;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 22px;
                            position: relative;
                            word-break: normal;
                            white-space: normal;
                            border-radius: 150px 0 0 150px;
                            padding-right: 34px;
                        }

                        &:hover,
                        &:focus {
                            @media screen and (min-width:1125px) {
                                background: $color-pale-grey;
                                color: $color-greyish-brown;
                            }

                            + .navigation__list {
                                @media screen and (min-width:1125px) {
                                    display: block;
                                }
                            }
                        }
                    }

                    .navigation__item--first {

                        @media screen and (min-width:1125px) {
                            display: none;

                            .navigation__arrow {
                                @media screen and (min-width:1125px) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }


            .navigation__item--small {
                @media screen and (min-width:1125px) {
                    width: 32.5%;
                    @include border-radius(0);
                    background: $grey;
                }

                &:nth-child(2n+1),
                &:last-child {
                    @media screen and (min-width:1125px) {
                        border-right: 1px solid #eee;
                        height: 50%;
                    }
                }

                > .navigation__link {
                    @media screen and (min-width:1125px) {
                        margin-bottom: 0.2em;
                        color: #8e8e8e;
                        font-size: .7em;
                        text-transform: uppercase;
                    }
                }

                .navigation__list {
                    .navigation__link {
                        @media screen and (min-width:1125px) {
                            font-size: .9em;
                            color: #424242;
                        }
                    }
                }
            }

            .navigation__item--has-block {

                .navigation__block-item-title {
                    display: block;
                    position: relative;
                    padding: 1em 0.7em 1em 0;
                    text-decoration: none;
                    font-weight: 500;
                    color: $color-greyish-brown;
                    font-size: 16px;

                    @media screen and (min-width:1125px) {
                        display: none;
                    }
                }

                @media screen and (min-width:1125px) {
                    padding-left: $desktop-small-padding;
                    padding-right: $desktop-small-padding;
                }

                &:hover {
                    position: relative;
                    z-index: 2222;
                }

                > .navigation__link {
                    @media screen and (min-width:1125px) {
                        color: $primary-color;
                        margin-bottom: 0.5em;
                        float: left;
                        padding-left: 0;
                    }
                }

                .navigation__button {
                    @media screen and (min-width:1125px) {
                        float: right;
                        font-size: 0.85em;
                        padding: .45em 1em;
                    }
                }

                .navigation__link {
                    &--featured {
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.57;
                        letter-spacing: normal;
                        color: #3f3f3f;
                        margin: 0;
                        padding-bottom: 2px;

                        @include tablet {
                            width: 50%;
                        }
                    }
                }


                @media screen and (min-width:1125px) {
                    width: 65%;
                    @include border-radius(0);
                    background: $color-pale-grey;
                }

                .latest-news__item {
                    clear: both;

                    @media screen and (max-width:1125px) {
                        all: unset;

                        * {
                            all: unset;
                        }

                        .latest-news__image-block {
                            display: none;
                        }

                        .latest-news__link {
                            color: $color-warm-grey-two;
                            font-weight: 500;
                            padding: 1em 0.7em 1em 0;
                            font-size: 14px;

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }

                    @media screen and (min-width:1125px) {
                        padding-left: 0;
                        padding-right: 0;
                        margin-bottom: $desktop-small-padding;
                        height: 177px;
                    }

                    .latest-news__image-block {
                        @media screen and (min-width:1125px) {
                            height: 100%;
                            overflow: hidden;
                        }
                    }

                    .latest-news__content {
                        @media screen and (min-width:1125px) {
                            height: 100%;
                            display: flex;
                            flex-wrap: wrap;
                        }
                    }

                    .latest-news__date {
                        @media screen and (min-width:1125px) {
                            margin-top: auto;
                        }
                    }
                }

                .sign-post {

                    @media screen and (max-width:1125px) {
                        all: unset;

                        * {
                            all: unset;
                        }

                        .sign-post__button {
                            display: none;
                        }
                    }

                    @media screen and (min-width:1125px) {
                        background: #ffffff;
                        @include clearfix();
                        @include spectrum-radius;
                        @include grey-border();
                        margin-bottom: 20px;
                        position: relative;
                        height: auto;
                        margin-bottom: $desktop-small-padding;
                    }

                    a {
                        display: block;

                        @media screen and (max-width:1125px) {
                            color: $color-warm-grey-two;
                            font-weight: 500;
                            padding: 1em 0.7em 1em 0;
                            font-size: 14px;
                        }

                        @media screen and (min-width:1125px) {
                            padding: 2em;
                            text-decoration: none;
                            padding: 2em 1em;
                        }

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    &__item {
        margin: 0;

        &:before {
            display: none;
        }

        @media screen and (min-width:1125px) {
            float: left;
        }

        &--main {

            > .navigation__link {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                @media screen and (min-width:1125px) {
                    overflow: inherit;
                }

                &:hover,
                &:focus {

                    .navigation__arrow {
                        @media screen and (min-width:1125px) {
                            transform: rotate(180deg) scaleX(-1);
                        }
                    }
                    /*+ .navigation__list {
                        margin: 0;

                        @include desktop {
                            display: flex;
                            @include translate(0,0);
                        }
                    }*/
                }
            }
        }

        &.nav-dropdown-open {
            > ul {
                display: block;

                .navigation__list--second {
                    display: block;
                    left: 0;
                }
            }
        }
    }

    &--microsite {
        .navigation__list {
            .navigation__list {
                .navigation__item--has-dropdown {
                    .navigation__list {
                        .navigation__list {
                            @include desktop {
                                height: 100%;
                            }
                        }
                    }
                }
            }

            .navigation__item--has-block {
                @media screen and (min-width:1125px) {
                    height: auto;
                }
            }

            .navigation__list--main {
                @media screen and (min-width:1125px) {
                    width: 810px;
                    padding: 34px 0 0 0;
                    height: auto;
                }
            }
        }
    }
}
