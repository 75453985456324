.btn,
.btn-secondary {
    @include reset-button();

    // for spinners inside buttons and supporting isFaded option
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: background-color $transition-default, color $transition-default, border-color $transition-default;

    .icon {
        flex-shrink: 0;
    }

    &.is-disabled,
    &:disabled {
        background-color: $color-disabled-background;
        border-color: $color-disabled-border;
        color: $color-disabled-text;
        cursor: not-allowed;

        .can-hover &:hover,
        .can-hover &:focus,
        .can-hover &:active {
            background-color: $color-disabled-background;
            border-color: $color-disabled-border;
            color: $color-disabled-text;
        }
    }
}

.button {
    $this: &;

    @include border-radius(150px);
    @include transition(all,0.2s,ease);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 9px 30px;
    background: $primary-color;
    border: 1px solid transparent;
    color: $color-white;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    font-size: 12px;

    @include desktop {
        padding: 10px 50px;
        font-size: 14px;
    }

    &[disabled] {
        background: $color-greyish-brown;
        cursor: default;

        &:hover,
        [data-whatintent='keyboard'] &:focus {
            background: $color-greyish-brown;
        }
    }

    &:hover,
    [data-whatintent='keyboard'] &:focus {
        background: darken($primary-color, 10%);
        text-decoration: none;
    }

    &__spinner {
        display: none;
    }

    &--secondary {
        background: $secondary-color;

        &:hover,
        [data-whatintent='keyboard'] &:focus {
            background: $color-purple-pink-three;
            text-decoration: none;
        }
    }

    &--tertiary {
        background: $color-violet-blue;

        &:hover,
        [data-whatintent='keyboard'] &:focus {
            background: darken($color-violet-blue, 10%);
            text-decoration: none;
        }
    }

    &--clear {
        background: none;
        border: 1px solid $color-lavender;
        color: $color-violet-blue;

        &:hover,
        [data-whatintent='keyboard'] &:focus {
            background: $color-violet-blue;
            border-color: $color-violet-blue;
            color: #FFFFFF;
        }
    }

    &--clear-two {
        background: none;
        border: 2px solid $color-violet-blue;
        color: $color-violet-blue;

        &:hover,
        [data-whatintent='keyboard'] &:focus {
            background: $color-violet-blue;
            border-color: $color-violet-blue;
            color: white;
        }
    }

    &--purple {
        background: lighten($color-barney,10%);

        &:hover,
        [data-whatintent='keyboard'] &:focus {
            background: darken($color-barney, 10%);
            text-decoration: none;
        }
    }

    &--quaternary {
        background-color: $color-pale-grey;
        color: $color-violet-blue;

        @include tablet {
            font-size: 16px;
        }

        &:hover,
        [data-whatintent='keyboard'] &:focus {
            background-color: darken($color-pale-grey, 10%);
        }
    }

    &--large {
        padding: 12px 3em;
        font-size: 14px;

        @include tablet {
            font-size: 16px;
        }
    }

    &--small {
        padding: 14px 18px;
        font-size: 0.9em;

        @include tablet {
            padding: 14px 36px;
        }
    }

    &--very-small {
        padding: 8px 8px;
        font-size: 80%;
        font-weight: normal;
        @include tablet {
            padding: 8px 8px;
        }
    }

    &--with-spinner {
        position: relative;

        #{$this}__spinner {
            display: none;
        }

        &.is-spinner-shown {
            #{$this}__spinner {
                display: block;
            }

            #{$this}__text {
                opacity: 0.7;
            }
        }
    }

    &--dark-blue {
        background: #2A2659;

        &:hover,
        [data-whatintent='keyboard'] &:focus {
            background: darken(#2A2659,10%);
        }
    }

    &--light-blue {
        background: #8024F3;

        &:hover,
        [data-whatintent='keyboard'] &:focus {
            background: darken(#8024F3,10%);
        }
    }
}


.button-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.base-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 7px 15px 8px;
    border: 2px solid transparent;
    border-radius: 25px;
    color: white;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    transition: background-color $transition-default, color $transition-default, border-color $transition-default;

    &--violet-blue {
        background-color: $color-violet-blue;

        &:hover,
        &:focus {
            background-color: darken($color-violet-blue, 10%);
        }
    }
}

.link {
    padding: 0;
    background-color: transparent;
    border: none;
    color: $color-warm-grey;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
}

.back-btn {
    display: inline-flex;
    align-items: center;
    color: $color-greyish-brown;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1.5px;
    line-height: 1.67;
    text-transform: uppercase;
    text-decoration: none;

    @include tablet {
        font-size: 12px;
    }

    &::before {
        content: '';
        display: block;
        width: 22px;
        height: 22px;
        margin-right: 12px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 12px;
        border: 2px solid $color-lavender;
        border-radius: 50%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='16px' height='12px' viewBox='0 0 16 12'%3E%3Cpath fill='%23D0C0EF' d='M5.1,6H15 M0.1,6.3c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.1l0.1,0.1l4.1,4.9c0.3,0.4,1,0.5,1.4,0.1 c0.4-0.4,0.5-1,0.1-1.4L5.1,9.5L3.1,7h12c0.5,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-12l2-2.5l0.7-0.9c0.3-0.4,0.3-1-0.1-1.4 C5.5,0.1,5.3,0,5.1,0C4.8,0,4.6,0.1,4.4,0.3L0.3,5.3L0.2,5.3c0,0-0.1,0.1-0.1,0.2c0,0,0,0.1-0.1,0.2c0,0,0,0.1,0,0.2 c0,0.1,0,0.2,0,0.3C0,6.2,0,6.3,0.1,6.3z'/%3E%3C/svg%3E%0A");

        @include tablet {
            width: 30px;
            height: 30px;
            margin-right: 20px;
            background-size: 15px;
        }
    }
}
