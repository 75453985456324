.account-preferences-form {
    &__title {
        color: $color-violet-blue;
    }

    &__text {
        margin-top: 15px;
        &.double-padding {
            margin-bottom:15px;
        }
    }

    &__fields {
        margin-top: 15px;
    }

    &__field {
        & + & {
            margin-top: 15px;
        }
    }

    &__submit {
        width: 100%;
        margin-top: 30px;

        @include tablet {
            width: auto;
            min-width: 200px;
            margin-top: 60px;
        }
    }
}
