.video {
    &__splash {
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 2;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include transition(all,0.5s,ease);
        opacity: 1;
        border:none;
        outline:none;

        &--fade-out {
            opacity: 0;
            visibility: hidden;
        }

        svg {
            fill: $primary-color;
            width: 25px;
            position: relative;
            left: 4px;
            top: 1px;

            @include tablet {
                width: 30px;
            }

            @include desktop {
                width: 35px;
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include spectrum-radius();
        }
    }

    &__play {
        background: $color-white;
        border:1px solid $color-white-three;
        height: 60px;
        width: 60px;
        line-height: 60px;
        @include border-radius(150px);
        display: flex;
        align-items: center;
        justify-content: center;

        @include tablet {
            height: 80px;
            width: 80px;
            line-height: 80px;
        }

        @include desktop {
            height: 100px;
            width: 100px;
            line-height: 100px;
        }
    }

    &__overlay {
        height: 100%;
        position: relative;
        z-index: 3;
        width: 100%;
        background: rgba(255,255,255,0.4);
        @include spectrum-radius();
    }

    &__container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;

        button {
            display: block;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            border: none;
            outline: none;

            &:hover {
                background: none;
                cursor: pointer;
            }
        }

        iframe, object, embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            @include spectrum-radius();
            height: 100%;
        }
    }
}


// Add padding to mobile if video in a 2 col

.generic-section__col {
    &.split {
        .video {
            @include mobile-only {
                margin-bottom:30px;
            }
        }
    }
}
