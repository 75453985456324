.participant-registration {
    $this: &;

    &__name {
        margin-bottom: 15px;
        color: $color-warm-grey;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.57;
    }

    &__participant-name {
        margin-bottom: 10px;
        color: $color-violet-blue;
    }

    &__options {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
    }

    &__option-item {
        margin-right: 10px;
        margin-bottom: 10px;
    }

}
