// Vars from Webpack config
$viewports: (mobile-wide: 580, tablet: 768, desktop: 964, desktop-wide: 1200);
$containerMaxWidth: 1440;
$defaultTransitionDuration: 300;

@import '~style-settings';
@import 'single-pledge-page';

/* Pledge Wall Layout */
/* Grid */
.dw_pledge_wall {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 40px;
    padding: 20px;
    align-items: start;
}

.dw_pledge_wall_more_results_placeholder {
    text-align: center;
    margin-top:10px;
}

.dw_pledge_single_page {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 600px));
    padding: 20px;
    align-items: start;
    justify-content: center;
}

.dw_pledge_card {
    position: relative;
    background: #fff;
    border: 1px solid #ccc;
    padding: 32px;
    border-radius: 16px 16px 0 16px;
    box-shadow: 4px 4px 4px 4px #eee;


}
    .dw_pledge_card h2 {
        margin-bottom: 30px;
        font-size: 2.875rem;
        color: #4203bf;
    }

    .dw_pledge_card p {
        line-height: 24px;
        margin-bottom: 15px;
    }


/* Media Queries for Specific Column Widths */
@media (min-width: 1500px) {
    .dw_pledge_wall {
        grid-template-columns: repeat(4, 1fr); /* 4 columns */
    }
}

@media (min-width: 1200px) and (max-width: 1499px) {
    .dw_pledge_wall {
        grid-template-columns: repeat(3, 1fr); /* 3 columns */
    }
}

@media (min-width: 650px) and (max-width: 1199px) {
    .dw_pledge_wall {
        grid-template-columns: repeat(2, 1fr); /* 2 columns */
    }
}

@media (max-width: 649px) {
    .dw_pledge_wall {
        grid-template-columns: 1fr; /* 1 column */
    }
}


/*/   blocks  /*/

.dw_pledge_card.dw_pledge_nas_block {
    background: linear-gradient(135deg, #fff, #eaeaff, #ffebf3);
    color: #30008f !important;
}

.dw_pledge_nas_block img.dw_pledge_main_image, .dw_pledge_nas_block video {
    width: calc(100% + 64px) !important;
    max-width: calc(100% + 64px) !important;
    border-radius: 16px 16px 0 0;
    position: relative;
    margin-left: -32px;
    margin-top: -32px;
    margin-bottom: -4px;
}

.dw_pledge_nas_block .dw_pledge_title {
    background: #ffffff85;
    width: calc(100% + 64px) !important;
    margin-left: -32px;
    margin-top: -2px;
    padding: 8px 32px 8px 0;
    margin-bottom: 1.5rem;
}

.dw_pledge_card.dw_pledge_nas_block .dw_nas_logo {
    max-height: 50px;
    width: auto;
    vertical-align: middle;
    position: relative;
    margin: 8px 20px;
}


.dw_pledge_card.dw_pledge_nas_block h2 {
    color: #30008f !important;
    font-weight: 700;
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 4px 0 0 0;
    padding: 0;
}

.dw_pledge_nas_block_summary, .dw_pledge_nas_block_subheading {
    font-weight: 700;
    font-size: 1.2rem !important;
}

.dw_pledge_nas_block_summary {
    margin-bottom: 2rem !important;
}

.dw_pledge_card.dw_pledge_nas_block ul li {
    padding-left: 0px !important;
}

.dw_pledge_resource_block ul li:before {
    display: none !important;
}


/* Masonry */

/*
.dw_pledge_wall {
    column-count: 4;
    column-gap: 32px;
}

.dw_pledge_card {
    border: 1px solid #ccc;
    padding: 32px;
margin-bottom: 32px;
    border-radius: 16px;
    break-inside: avoid;
  }
*/

/*  Flex */

/*
.dw_pledge_wall {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
}

.dw_pledge_card {
  display: block;
  max-width: 20%;
  margin: 30px;
  border: 1px solid #ccc;
  padding: 32px;
  border-radius: 16px 16px 0 16px;
  box-shadow: 4px 4px 4px 4px #eee;
}

*/

/* Pledge card rotate */
.dw_pledge_wall .dw_pledge_card:nth-of-type(3n + 1) {
    rotate: 0.5deg;
}

.dw_pledge_wall .dw_pledge_card:nth-of-type(3n + 2) {
    rotate: -0.5deg;
}

.dw_pledge_card .dw_pledge_main_image {
    max-width: 100%;
    margin-bottom: 20px;
    border-radius: 16px;
}

.dw_pledge_id {
    color: #c015ac;
    margin-right: 8px;
    top: -4px;
    padding: 0 2px 0 0;
    font-size: 2.4rem;
    font-weight: 700;
    background: linear-gradient(135deg, #f064d9, #c85bf3, #f2b926, #44b9e5, #7b9ed7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    letter-spacing: -2px;
}

.dw_pledge_title {
    display: flex;
}

.dw_pledge_card h2 {
    font-size: 1.4rem;
}

.dw_pledge_content strong {
    font-weight: 600;
    background: #f6f7ff;
}

p.dw_pledge_name {
    font-weight: 600;
    font-size: 0.9rem;
    margin: 0 !important;
}

/*.dw_pledge_footer {
    display: flex;
    border-top: 1px solid #bbb;
    margin-top: 20px;
    padding-top: 20px;
}

.dw_pledge_footer img {
    max-height: 30px;
}

.dw_pledge_footer div {
    text-align: center;
    margin-bottom: 0;
    width: 50%;
}

.dw_pledge_footer p {
    margin: 0;
}

.dw_pledge_footer span {
    display: inline;
}

.dw_pledge_footer span.dw_pledge_like_count,
.dw_pledge_footer span.dw_pledge_share_count {
    text-align: center;
}

.dw_pledge_footer button {
    background: none !important;
    color: inherit;
    padding: 0;
    border: none;
}

.dw_pledge_like img,
.dw_pledge_share img {
    filter: saturate(0%);
    transition: transform 0.2s ease;
    cursor:pointer;
}

.dw_pledge_like img.liked {
    filter: saturate(100%);
}

.dw_pledge_like img:hover,
.dw_pledge_share img:hover {
    transform: scale(1.2);
    filter: saturate(100%);
}

.dw_pledge_like_tooltip {
    position: absolute;
    background: #ffffff;
    color: #000000;
    padding: 24px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    white-space: nowrap;
    width: 90% !important;
    opacity: 0;
    transition: opacity 0.3sease-in-out;
    pointer-events: none;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
}

.dw_pledge_like_tooltip.show {
    opacity: 1;
}*/

.dw_pledge_tags {
    margin: 12px 0 0 0;
}

.dw_pledge_tags span {
    display: inline-flex;
    margin: 0 6px 6px 0;
    font-size: 0.9rem;
}

/* Colours for Specific Tags */
.dw_pledge_tags span[data-tag="ShowUpAtWork"] {
    color: #ff2121;
}

.dw_pledge_tags span[data-tag="ShowUpForFriends"] {
    color: #186bbf;
}

.dw_pledge_tags span[data-tag="ShowUpAtSchool"] {
    color: #e929bb;
}

.dw_pledge_tags span[data-tag="ShowUpAtHome"] {
    color: #22bf22;
}

.dw_pledge_tags span[data-tag="ShowUpAllTheTime"] {
    color: #a7a000;
}

/*.dw_pledge_tags span[data-tag="make_a_difference"] {
    color: #ea9010;
}

.dw_pledge_tags span[data-tag="show_up_for_change"] {
    color: #0f99ee;
}

.dw_pledge_tags span[data-tag="positive_steps"] {
    color: #a972e9;
}

.dw_pledge_tags span[data-tag="actions_speak_louder"] {
    color: #0099a2;
}

.dw_pledge_tags span[data-tag="be_an_example"] {
    color: #ff6324;
}*/

.dw_pledge_card.nas_block {
    background: linear-gradient(45deg, #a57bff, #0237a7);
}

.dw_pledge_card.nas_block h2,
.dw_pledge_card.nas_block h5 {
    color: #fff;
}

.dw_pledge_card.nas_block ul li {
    color: #fff;
}

.dw_pledge_card.nas_block .dw_pledge_content {
    color: #fff;
}

/* Pledge Intro Section */

.dw_pledge_intro_section {
    width: 80%;
    margin: 0 auto;
}

p.dw_pledge_intro {
    font-size: 1.1rem;
    line-height: 1.6rem;
    margin-bottom: 1.6rem;
}

.dw_pledge_submission_section {
    text-align: center;
    padding: 30px 0 40px;
    background: #f6f7ff;
    border-radius: 10px;
}

/* Wrapper to position elements inside */
.dw_pledge_input_container {
    position: relative;
    display: inline-block;
    width: 90%;
    max-width: 900px;
}

h3.dw_pledge_prompt {
    font-size: 1.8rem;
    margin-bottom: 30px;
}

h3.dw_pledge_prompt span {
    font-weight: 600;
}

/* Input Field */
#dw_pledge_input {
    width: 100%;
    padding: 16px 200px 16px 32px;
    font-size: 1.3rem;
    border: 2px solid #858aaa;
    border-radius: 30px;
    outline: none;
    position: relative;
    resize: none;
    overflow-y: hidden;
}

/* Button inside the input */
#dw_pledge_submit {
    position: absolute;
    right: 9px;
    bottom: -8px;
    transform: translateY(-50%);
    background: #d31abc;
    color: white;
    border: none;
    border-radius: 24px;
    padding: 10px 18px;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
}

/* Adjust styles for smaller screens */
@media (max-width: 767px) {
    #dw_pledge_input {
        height: 150px;
        padding: 16px 32px;
    }

    #dw_pledge_submit {
        display: block;
        position: relative;
        width: 100%;
        right: 0;
        text-align: center;
        bottom: -30px;
    }
}

#dw_pledge_submit:hover {
    background: #c015ac;
}

/* Modal Styling */
.dw_pledge_modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.dw_pledge_modal_content {
    background: white;
    padding: 32px 16px;
    width: 90%;
    max-width: 800px;
    margin: 5% auto;
    border-radius: 10px;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
}

/* Disable background scrolling when modal is open */
body.modal-open {
    overflow: hidden;
}

.dw_pledge_modal_content h2 {
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 20px;
}

.dw_pledge_close,
.dw_pledge_share_close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 36px;
    font-weight: 700;
    cursor: pointer;
}

/* Pledge wall filter bar */

.dw_pledge_filter_bar {
    padding: 15px 20px;
    margin-bottom: 20px;
    text-align: center;
    background: #f5f5f5;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.dw_pledge_filter_bar h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
}

.dw_pledge_filter_controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
}

.dw_pledge_filter_controls label {
    font-size: 14px;
    font-weight: 600;
}

.dw_pledge_filter_controls select {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    background: white;
    margin-right: 32px;
}

/* Adjust styles for smaller screens */
@media (max-width: 600px) {
    .dw_pledge_filter_controls {
        flex-direction: column;
        gap: 10px;
    }
}

/* Pledge form */

div#modal_form_placeholder {
/*    display: none;*/
}

.dw_pledge_modal_content .form-widget {
    border: 0;
    padding: 16px;
}

.dw_pledge_modal_content .form-widget .ktc-default-section {
    margin: 0 !important;
}

.dw_pledge_modal_content .form-widget .form-widget__columns {
    display: flex;
    flex-wrap: nowrap;
}

.dw_pledge_modal_content .form-widget .form-widget__column {
    width: 100% !important;
}

.dw_pledge_modal_content .form-widget .form-widget__title {
    display: none !important;
}

.dw_pledge_modal_content .form-widget .control-label {
    font-size: 1.1rem;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 8px;
}

.dw_pledge_modal_content .form-widget .explanation-text {
    font-size: 0.9rem;
    margin-top: 16px;
}

.dw_pledge_modal_content .form-widget .ktc-checkbox-list {
    display: grid;
    gap: 10px; /* Space between checkboxes */
    align-items: start;
}

@media (min-width: 650px) {
    .dw_pledge_modal_content .form-widget .ktc-checkbox-list {
        grid-template-columns: repeat(2, 1fr); /* Two columns */
    }
}

@media (max-width: 649px) {
    .dw_pledge_modal_content .form-widget .ktc-checkbox-list {
        grid-template-columns: repeat(1, 1fr); /* One column */
    }
}

.dw_pledge_modal_content .form-widget .ktc-checkbox {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-left: 0px !important;
}

.dw_pledge_modal_content .form-widget .ktc-checkbox label {
    font-size: 16px;
    cursor: pointer;
}

/* Target only the form inside the modal */
.dw_pledge_modal_content .form-widget .ktc-default-section:nth-child(2),
.dw_pledge_modal_content .form-widget .ktc-default-section:nth-child(3),
.dw_pledge_modal_content .form-widget .form-widget__submit {
    display: none; /* Hide sections 2 and 3 + submit button initially */
}

/* Add spacing for buttons */
.dw_pledge_modal_content .form-widget .form-navigation {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.dw_pledge_modal_content .form-widget .ktc-default-section {
/*    margin-bottom: 10px !important;*/
    padding: 10px;
}

.dw_pledge_modal_content .form-widget .form-field.form-field--input {
}

.dw_pledge_modal_content .form-widget .form-next {
    margin: 30px 0 10px;
    padding: 8px 32px;
    border-radius: 32px;
    width: 100%;
    font-weight: 700;
    color: #fff;
    background: #1175ae;
    border: none;
    cursor:pointer;
}

.dw_pledge_modal_content .form-widget .form-next:hover {
    background: #476adc;
}

.dw_pledge_modal_content .form-widget .form-back {
    padding: 8px 32px;
    border-radius: 32px;
    width: 100%;
    background: #fff;
    border: 1px solid #ccc;
    color: #222;
    cursor:pointer;
}

.dw_pledge_modal_content .form-widget .form-widget__submit {
    margin: 20px 0 0 0;
    font-size: 1.2rem;
    padding: 12px;
    width: 100%;
}

/* Pledge tracker */

.dw_pledge_tracker {
    margin: 0 0 30px 0;
    position: relative;
    width: 90%;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.dw_pledge_tracker_bar {
    background-color: #e0e0e0; /* Grey background for the bar */
    border-radius: 25px;
    height: 20px;
    overflow: hidden;
    width: 100%;
}

.dw_pledge_progress {
    background: linear-gradient(135deg, #f064d9, #c85bf3, #5b6ee1, #44b9e5, #7bd7d7);
    height: 100%;
    width: 0; /* Start at 0% */
    border-radius: 25px 0 0 25px; /* Rounded left edge */
    transition: width 1.5s ease-out; /* Smooth animation */
}

.dw_pledge_tracker_text {
    margin: 0 0 16px 0;
    font-size: 16px;
    font-weight: bold;
}

.dw_pledge_count,
.dw_pledge_target {
    color: #4203bf;
}


