.right-cavet {
    position: relative;

    &:after {
        @include desktop {
            content: "";
            background: #afafaf;
            height: 50%;
            width: 1px;
            position: absolute;
            right: -14px;
            top: 28%;
        }
    }
}

.arrow-svg {
    height: 30px;
    width: 30px;
    fill: #c7c7c7;

    &--reverse {
        transform: scale(-1,1)
    }
}


.owl-carousel {
    .owl-stage {
        display: flex;
        &:after {
            display:none !important;
        }
    }
}

.owl-buttons {

    &--wide {
        position: absolute;
        top: 50%;
        display: none;

        @include desktop {
            width: 1200px;
            left: -118px;
            display: block;
        }
    }

    &__button {

        &:hover {
            position: relative;
            z-index: 222;
            cursor: pointer;

            .advice-section__owl-svg {
                fill: $color-white;
            }

            .owl-buttons__text {
                width: 147px;
                opacity: 1;
            }
        }

        &--owl-prev {
            float: left;
        }

        &--owl-next {
            float: right;
        }
    }

    &__text {
        position: absolute;
        left: 0px;
        color: $color-white;
        background: $color-bluish-purple;
        padding: 0 15px;
        height: 50px;
        @include border-radius(150px);
        display: block;
        text-align: right;
        font-size: 12px;
        opacity: 0;
        z-index: -1;
        width: 0;
        top: 0;
        line-height: 50px;
        @include transition(all,0.25s,ease);

        &--right {
            left: initial;
            right: 0;
            text-align: left;
        }
    }

    &__owl-svg {
        width: 100%;
        @include transition(all,0.2s,ease);
        height: auto;
        fill: $color-lavender;

        &--reverse {
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
    }
}


.scrollbar {
    background: $color-lavender;
    display:block;
    width:100%;
    @media screen and (min-width:1130px) {
        display:none;
    }
    &__scroller {
        background: $color-violet-blue;
    }
}

.hover-top-transition {
    @include hoverTopTransition();
}
