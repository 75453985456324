.visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    white-space: nowrap;
}

.hidden {
    display: none;
}

.fill-parent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.of-cover,
.of-contain {
    & > img,
    & > video {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
}

.of-cover {
    & > img,
    & > video {
        object-fit: cover;
    }
}

.of-contain {
    & > img,
    & > video {
        object-fit: contain;
    }
}

.box {
    border: 1px solid $color-white-three;
    border-radius: 20px 20px 0 20px;

    &--base {
        padding: 20px;

        @include tablet {
            padding: 30px;
        }
    }
}

.mt-20-0 {
    margin-top: 20px;

    @include tablet {
        margin-top: 0;
    }
}

.mt-15-30 {
    margin-top: 15px;

    @include tablet {
        margin-top: 30px;
    }
}

.mt-30-60 {
    margin-top: 30px;

    @include tablet {
        margin-top: 60px;
    }
}

.color-violet-blue {
    color: $color-violet-blue;
}
