.guidance {
    margin-bottom: $mobile-large-padding;

    @include desktop {
        margin-bottom: $desktop-large-padding;
    }

    &--sub-topic {
        .generic-section {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &--sub-topic-v2 {
        .guidance-navigation__description {
        }
    }

    &__wrapper {
        @include desktop {
            padding: 0 30px;
        }
    }

    &__container {
        background: $color-pale-grey;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        max-width: 1200px;
        @include border-radius(20px 20px 0 0);

        &--narrow {
            max-width: 964px;
            padding: 30px $padding-side-mobile;

            @include desktop {
                padding: 80px 0;
            }
        }

        &--footer {
            background: $color-white;
            border: 1px solid $color-white-three;
            @include border-radius(0 0 0 20px);
            padding: 20px;

            @include tablet {
                text-align: right;
                @include border-radius(0 0 0 40px);
            }

            p {
                font-size: 12px;
                color: $color-warm-grey;

                span {
                    font-weight: 500;
                    color: $color-greyish-brown;
                }
            }
        }
    }

    &__main-content {
        p {
            color: $color-greyish-brown;
            line-height: 24px;
            margin-bottom: 20px;

            @include tablet {
                font-size: 16px;
            }
        }

        h1 {
            font-weight: bold;
            letter-spacing: -0.5px;
            line-height: 1.17;
            color: $color-violet-blue;

            @include tablet {
                font-size: 26px;
                margin-bottom: 30px;
            }

            @include desktop {
                font-size: 36px;
            }
        }
    }

    &__content {
        padding: 15px 0;

        @include desktop {
            padding: 0 0 $desktop-medium-padding 0;
        }

        &--subtopic {
            h1 {
                line-height: 42px;
                font-weight: 600;

                @include tablet {
                    font-size: 25px;
                }

                @include desktop {
                    font-size: 32px;
                }
            }
        }
    }

    &__sidebar-container {
        @include desktop {
            order: 2;
        }
    }

    &__sidebar {
        @include desktop {
            @include spectrum-radius();
            border: 1px solid $color-white-three;
            padding: 30px;
        }
    }

    &__side-item {
        @include spectrum-radius();
        padding: 20px;

        @include desktop {
            padding: 30px;
        }

        &--nav {
            background: $color-violet-blue;

            &.mobile-view {
                background: $color-violet-blue;
                padding: 15px 0 0;
                margin: 15px 0 0;
                border-top: 1px solid $color-white;
                border-radius: 0;

                @include desktop {
                    padding: 30px;
                    border: none;
                    margin: 0;
                    @include spectrum-radius();
                }

                .guidance__sidebar__title {
                    font-size: 18px;

                    @include desktop {
                        font-size: 21px;
                    }
                }

                .guidance-navigation__side-navigation {
                    padding: 0;
                }
            }

            .guidance__sidebar__title {
                color: $color-white;
            }
        }
    }

    &__share {
        margin-top: 30px;
        display: none;

        @include tablet {
            display: block;
        }

        p {
            margin-bottom: 15px;
            font-size: 14px;
            color: $color-greyish-brown;
        }

        &--mobile {
            display: block;

            @include tablet {
                display: none;
            }
        }

        &--full {
            display: block;
        }
    }

    &__sidebar-nav {
        max-height: 48px;
        overflow: hidden;
        margin-bottom: $mobile-medium-padding;
        @include border-radius(10px 10px 0 10px);
        @include transition(all,0.5s,ease);
        border: 1px solid $color-white-three;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 22px;
            right: 15px;
            width: 9px;
            height: 9px;
            color: #C4C4C4;
            border: 1px solid currentcolor;
            transform: translateY(-90%) rotateZ(-45deg);
            transition: transform 0.3s;
            transform-origin: 100%;
            border-color: transparent transparent currentcolor currentcolor;

            @include desktop {
                display: none;
            }
        }

        @include desktop {
            border: 0;
            margin-bottom: 0;
            max-height: none;
            @include border-radius(0);
        }

        &.active {
            max-height: 400px;

            @include desktop {
                max-height: none;
            }

            &:after {
                border-color: currentcolor currentcolor transparent transparent;
                top: 28px;

                @include desktop {
                    display: none;
                }
            }
        }

        &--dropdown-full {
            max-height: 48px;
            overflow: hidden;
            margin-bottom: $mobile-medium-padding;
            @include border-radius(10px 10px 0 10px);
            @include transition(all,0.5s,ease);
            border: 1px solid $color-white-three;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                top: 22px;
                right: 15px;
                width: 9px;
                height: 9px;
                color: #C4C4C4;
                border: 1px solid currentcolor;
                transform: translateY(-90%) rotateZ(-45deg);
                transition: transform 0.3s;
                transform-origin: 100%;
                border-color: transparent transparent currentcolor currentcolor;

                @include desktop {
                    display: block;
                }
            }

            .guidance__sidebar-item {
                background: $color-white;

                a {
                    padding: 15px;
                }

                &:first-child {
                    a {
                        padding: 15px;
                        pointer-events: none;
                        cursor: default;
                    }
                }

                &:last-child {
                    a {
                        padding: 15px;
                    }
                }
            }

            &.active {
                max-height: 400px;

                @include desktop {
                    max-height: 400px;
                }

                &:after {
                    border-color: currentcolor currentcolor transparent transparent;
                    top: 28px;

                    @include desktop {
                        display: block;
                    }
                }
            }
        }
    }

    &__sidebar-title {
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 1.5px;
        color: $color-warm-grey;
        margin-bottom: 15px;
        text-transform: uppercase;

        @include tablet {
            font-size: 12px;
        }
    }

    &__sidebar-list {
        margin: 0;
        padding: 0;
    }

    &__sidebar-item {
        list-style: none;
        background: $color-white;

        @include desktop {
            background: none;
        }

        a {
            font-size: 14px;
            text-decoration: none;
            font-weight: 500;
            display: block;
            color: $color-greyish-brown;
            padding: 15px;

            @include desktop {
                border-bottom: 1px solid $color-white-three;
                padding: 15px 0;
                font-size: 16px;
            }

            &:hover {
                color: $color-violet-blue;

                @include desktop {
                    font-weight: bold;
                }
            }
        }

        &:last-child {
            a {
                @include desktop {
                    border-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }

        &:first-child {
            a {
                pointer-events: none;
                cursor: default;

                @include desktop {
                    pointer-events: visible;
                    cursor: pointer;
                    padding-left: 0;
                }
            }
        }
    }

    .accordion {
        &__panel {
            p {
                font-weight: 500;
                margin-bottom: 10px;
            }

            a {
                display: block;
            }
        }
    }

    .advice-section--single-column {
        @include spectrum-radius();
        padding: 20px;
        background: $color-blue-violet;

        @include desktop {
            padding: 30px;
        }

        .advice-section__toolbar {
            margin-bottom: 15px;

            @include desktop {
                margin-bottom: 20px;
            }
        }

        .advice-section__header {
            color: $color-white;
            margin: 0;
            text-align: left;

            @include desktop {
                font-size: 22px;
            }
        }

        .guidance-navigation__side-nav {
            li {
                color: $color-white;
                margin-bottom: 15px;

                a {
                    color: $color-white;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
