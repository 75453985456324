.latest-news {
    position: relative;
    z-index: 9;

    &__toolbar {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 15px;

        @include tablet {
            margin-bottom: 30px;
        }
    }

    &__buttons {
        margin-top: 30px;

        @include tablet {
            margin-top: 0;
            position: absolute;
            right: 0;
            top: 0;
        }

        .button {
            font-size: 14px;
            width: 100%;
            margin-bottom: 15px;
            background: $color-white;

            @include tablet {
                background: none;
                margin-bottom: 0;
                width: auto;
            }

            &:hover {
                background: $color-violet-blue;
            }

            &:not(:last-child) {
                margin-right: 15px;
            }
        }
    }

    &__row {
        box-sizing: border-box;
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex: 0 1 auto;
        -webkit-box-flex: 0;
        flex: 0 1 auto;
        -ms-flex-direction: row;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    &__item {
        position: relative;
        box-sizing: border-box;
        -ms-flex: 0 0 auto;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        border: 1px solid $color-white-two;
        @include spectrum-radius();

        &--full {
            display: flex;

            .latest-news__link {
                display: flex;
            }

            .latest-news__category {
                left: 20px;
                right: initial;
            }

            .latest-news__image-block {
                width: 44%;
                position: relative;
                @include border-radius(15px 0 0 15px);
            }

            .latest-news__content {
                width: 64%;
                border: none;
                @include border-radius(0 15px 0 0);
                margin-left: -40px;
                padding-left: 4em;
            }

            .latest-news__content-header {
                color: #3F3F3F;
            }
        }

        img {
            max-width: 100%;
            vertical-align: top;
            height: auto;
        }
    }

    &__curve-right {
        position: absolute;
        bottom: -2px;
        right: 0;
        height: 100%;
        fill: $color-white;
        top: 0;
        transform: rotate(0deg) scaleX(-1);
    }

    &__link {
        text-decoration: none;
        display: block;
    }

    &__category {
        background: $color-teal;
        color: #ffffff;
        font-weight: 500;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        @include spectrum-radius();
        padding: 0.4em 0.8em;
        position: absolute;
        top: 20px;
        font-size: 12px;
        right: 30px;
    }

    &__title {
        font-size: 24px;
        color: $color-violet-blue;

        @include tablet {
            font-size: 32px;
        }
    }

    &__date {
        font-size: 12px;
        color: $color-warm-grey;

        &--event {
            -ms-flex-preferred-size: 25%;
            flex-basis: 25%;
            max-width: 25%;
            padding-right: 1.5em;
            text-align: center;
        }
    }

    &__image {
        display: block;
        position: relative;
    }

    &__location {
        font-size: 12px;
        color: $color-warm-grey;
        line-height: 18px;
    }

    &__month {
        color: $secondary-color;
        letter-spacing: 2px;
        @include quicksand-regular();
        text-transform: uppercase;
        margin-bottom: 0.2em;
        display: block;
    }

    &__day {
        color: $secondary-color;
        font-size: 2em;
        display: block;
        @include quicksand-bold();
        margin-bottom: 0.1em;
    }

    &__year {
        @include quicksand-bold();
        color: $secondary-color;
    }

    &__content {
        padding: 20px 20px 20px 30px;
        @include border-radius(0 0 0 15px);
        height: 160px;
        background: $color-white;
        border: 1px solid $color-white-three;
        border-top: 0;

        @include desktop {
            height: 170px;
        }

        &--event {
            display: flex;
        }
    }

    &__content-header {
        color: $color-violet-blue;
        font-size: 16px;
        margin-bottom: $mobile-xsmall-padding;
    }

    &__curve {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        fill: $color-white;
    }

    .owl-buttons__button {
        &:hover {
            .owl-buttons__owl-svg {
                fill: $color-white;
                background: $color-violet-blue;
                border-color: $color-violet-blue;
                z-index: 222;
            }
        }
    }

    .owl-buttons__owl-svg {
        width: 100%;
        @include transition(all,0.2s,ease);
        height: auto;
        fill: $color-lavender;
        width: 50px;
        @include border-radius(150px);
        height: 50px;
        padding: 1em;
        background: $color-white;
        @include transition(all,0.2s,ease);
        position: relative;
        border: 1px solid $color-white-three;
    }

    .related-event__content {
        min-height: 184px;

        @include tablet {
            min-height: 190px;
        }

        @include desktop {
            min-height: 159px;
        }
    }
}

.news-carousel {
    .owl-stage-outer {
        padding-top: 10px;
        margin-top: -10px;

        .related-news {
            @include tablet {
                margin-bottom: 10px;
            }
        }
    }
}

/*Related Content*/

.related-event {

    &__inner {
        border: 1px solid $color-white-three;
        @include border-radius(20px);
    }

    &__content {
        @include border-radius(0 0 20px 20px);
        background: $color-white;
        padding: 20px 25px 40px;
        display: flex;
        box-sizing: border-box;
        -ms-flex: 0 0 auto;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        border-top: 0;

        @include tablet {
            min-height: 180px;
        }
    }

    &__link {
        color: inherit;
        text-decoration: none;
        display: block;
    }

    &__title {
        font-size: 22px;
        color: $color-violet-blue;
        margin-bottom: $mobile-small-padding;

        @include tablet {
            font-size: 32px;
            max-height: 66px;
            overflow: hidden;
            margin-bottom: $desktop-small-padding;
        }
    }

    &__date {
        font-size: 12px;
        color: $color-warm-grey;
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
        padding-right: 1.5em;
        text-align: center;
    }

    &__detail {
        flex-basis: 75%;
        max-width: 75%;
    }

    &__image {
        display: block;
        position: relative;
        height: 194px;
        overflow: hidden;

        img {
            @include border-radius(20px 20px 0 0);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            border: 1px solid $color-white-three;
            height: 100%;
            object-fit: cover;
        }
    }

    &__category {
        background: $color-teal;
        color: #ffffff;
        font-weight: 500;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        @include spectrum-radius();
        padding: 0.4em 0.8em;
        position: absolute;
        top: 20px;
        font-size: 12px;
        right: 30px;
    }

    &__location {
        text-transform: uppercase;
        font-weight: 500;
        line-height: 1.8;
        letter-spacing: 1.5px;
        margin-bottom: 5px;
        font-size: 12px;
        color: $color-warm-grey;
    }

    &__month {
        color: $secondary-color;
        letter-spacing: 2px;
        @include quicksand-regular();
        text-transform: uppercase;
        margin-bottom: 0.2em;
        display: block;
    }

    &__day {
        color: $secondary-color;
        font-size: 2em;
        display: block;
        @include quicksand-bold();
        margin-bottom: 0.1em;
    }

    &__year {
        @include quicksand-bold();
        color: $secondary-color;
    }

    &__curve {
        position: absolute;
        left: -1px;
        bottom: 0;
        width: 101%;
        fill: $color-white;

        &--border {
            fill: none;
            stroke: #E0E0E0;
            stroke-miterlimit: 10;
            stroke-width: 1px;
        }
    }

    &__title {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.38;
        margin-bottom: 10px;
        color: $color-violet-blue;
    }
}


.related-news {

    &__inner {
        border: 1px solid $color-white-three;
        @include border-radius(20px);
    }

    &__category {
        background: $color-teal;
        color: #ffffff;
        font-weight: 500;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        @include spectrum-radius();
        padding: 0.4em 0.8em;
        position: absolute;
        top: 20px;
        font-size: 12px;
        right: 30px;
    }


    &__detail {
        display: flex;
        flex-flow: column wrap;
        width: 100%;
    }

    &__content {
        @include border-radius(0 0 20px 20px);
        background: $color-white;
        padding: 20px 25px 40px;
        display: flex;
        box-sizing: border-box;
        -ms-flex: 0 0 auto;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        border-top: 0;

        @include tablet {
            min-height: 190px;
        }

        @include desktop {
            min-height: 159px;
        }
    }

    &__link {
        color: inherit;
        text-decoration: none;
        display: block;
    }

    &__title {
        font-size: 22px;
        color: $color-violet-blue;
        margin-bottom: $mobile-small-padding;
        clear: both;
        width: 100%;

        @include tablet {
            font-size: 32px;
            max-height: 66px;
            margin-bottom: $desktop-small-padding;
        }

        @include mobile-only {
            min-height: 90px;
        }
    }

    &__date {
        font-size: 12px;
        color: $color-warm-grey;
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
        padding-right: 1.5em;
        text-align: center;
    }

    &__image {
        display: block;
        position: relative;
        height: 194px;
        background: #e4e4e4;
        @include border-radius(20px 20px 0 0);
        overflow: hidden;

        img {
            @include border-radius(20px 20px 0 0);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__curve {
        position: absolute;
        left: -2px;
        bottom: 0;
        width: 101%;
        fill: $color-white;

        &--border {
            fill: none;
            stroke: #E0E0E0;
            stroke-miterlimit: 10;
            stroke-width: 1px;
        }
    }

    &__title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 10px;
        color: $color-violet-blue;
    }

    &__description {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 22px;
        color: $color-greyish-brown;
        margin-top: auto;
    }
}
