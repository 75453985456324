.padding-top-l {
    padding-top: 40px;

    @include tablet {
        padding-top: 80px;
    }

    @include desktop {
        padding-top: 80px;
    }

    @include desktop-wide {
        padding-top: 100px;
    }
}

.padding-bottom-l {
    padding-bottom: 40px;

    @include tablet {
        padding-bottom: 80px;
    }

    @include desktop {
        padding-bottom: 80px;
    }

    @include desktop-wide {
        padding-bottom: 100px;
    }
}

.padding-top-m {
    padding-top: 30px;

    @include tablet {
        padding-top: 60px;
    }

    @include desktop {
        padding-top: 60px;
    }

    @include desktop-wide {
        padding-top: 80px;
    }
}

.padding-bottom-m {
    padding-bottom: 30px;

    @include tablet {
        padding-bottom: 60px;
    }

    @include desktop {
        padding-bottom: 60px;
    }

    @include desktop-wide {
        padding-bottom: 80px;
    }
}

.padding-top-s {
    padding-top: 15px;

    @include tablet {
        padding-top: 30px;
    }

    @include desktop {
        padding-top: 30px;
    }

    @include desktop-wide {
        padding-top: 50px;
    }
}

.padding-bottom-s {
    padding-bottom: 15px;

    @include tablet {
        padding-bottom: 30px;
    }

    @include desktop {
        padding-bottom: 30px;
    }

    @include desktop-wide {
        padding-bottom: 50px;
    }
}

.padding-top-xs {
    padding-top: 10px;

    @include tablet {
        padding-top: 15px;
    }

    @include desktop {
        padding-top: 30px;
    }

    @include desktop-wide {
        padding-top: 40px;
    }
}

.padding-bottom-xs {
    padding-bottom: 10px;

    @include tablet {
        padding-bottom: 15px;
    }

    @include desktop {
        padding-bottom: 30px;
    }

    @include desktop-wide {
        padding-bottom: 40px;
    }
}

.padding-top-none {
    padding-top: 0;
}

.padding-bottom-none {
    padding-bottom: 0;
}
