
.audience-selector {
    background: $color-dark-slate-blue;
    padding: 40px 15px;

    @include tablet {
        padding: 80px 0;
    }

    &__title {
        text-align: center;
        font-size: 22px;
        color: $color-white;
        margin-bottom: 15px;

        @include tablet {
            font-size: 32px;
        }
    }

    &__text {
        font-size: 14px;
        text-align: center;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 2em;
        color: $color-white;
        opacity: 0.7;
        margin-bottom: 30px;
        line-height: 24px;

        @include tablet {
            width: 70%;
            margin-bottom: 60px;
            font-size: 16px;
        }
    }

    &__button {
        padding: 14px 20px;
        background: $color-white;
        font-size: 12px;
        @include border-radius(150px 150px 0 150px);
        width: 100%;
        font-weight: 400;
        color: $color-warm-grey;
        text-align: left;
        position: relative;
        border:none;
        outline:none;
        @include tablet {
            display: none;
        }

        &:hover {
            background: $color-white;
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            width: 9px;
            height: 9px;
            color: #C4C4C4;
            border: 1px solid currentcolor;
            transform: translateY(-90%) rotateZ(-45deg);
            transition: transform 0.3s;
            transform-origin: 100%;
            right: 15px;
            border-color: transparent transparent currentcolor currentcolor;
        }
    }

    &__list {
        margin: 0;
        padding: 0;
        text-align: center;
        max-height: 0;
        overflow: hidden;
        @include transition(all,0.25s,ease);

        @include tablet {
            max-height: 800px;
            width: 80%;
            margin: 0 auto;
        }

        &.active {
            margin-top:15px;
            max-height: 800px;
        }
    }

    &__item {
        padding-right: 1rem;
        padding-left: 1rem;
        background: $primary-color;
        @include spectrum-radius();
        @include transition(all,0.2s,ease);
        display: block;
        margin: 0 0 15px;

        @include tablet {
            display: inline-block;
            margin: 0 15px 15px 0;
        }

        &:nth-child(2) {
            background: $secondary-color;

            &:hover {
                background: darken($secondary-color,10%);
            }
        }

        &:nth-child(3) {
            background: $purple;

            &:hover {
                background: darken($purple,10%);
            }
        }

        &:nth-child(4) {
            background: $green;

            &:hover {
                background: darken($green,10%);
            }
        }

        &:nth-child(5) {
            background: $orange;

            &:hover {
                background: darken($orange,10%);
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        font-weight: 600;
        color: $color-white;
        text-decoration: none;
        padding: 0.5em;
        @include border-radius(150px 150px 0 150px);
        padding: 14px 5px;
        font-size: 12px;

        @include tablet {
            padding: 16px 30px;
            font-size: 18px;
        }

        &:hover {
            text-decoration: none;
        }
    }

    &__link-svg {
        fill: #ffffff;
        display: block;
        height: 20px;
        margin-left: 20px;
    }
}
