.account-events {
    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        color: $color-violet-blue;
    }

    &__count {
        color: $color-warm-grey;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.33;

        @include tablet {
            font-size: 18px;
        }
    }

    &__list {
        margin-top: 30px;
    }

    &__pagination {
        margin-top: 35px;
        text-align: center;

        @include tablet {
            margin-top: 65px;
        }
    }

    &__no-events {
        margin-top: 40px;
        text-align: center;
    }
}
